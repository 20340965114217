import { Lang } from "@/types/lang";

export default <Lang>{
  registration: "Inscripción",
  login: "Inicio de sesión",
  signUpWarning1:
    "Al hacer clic en iniciar sesión, o continuar con las otras opciones que aparecen a continuación, usted acepta ",
  signUpWarning2: "Condiciones de servicio ",
  signUpWarning3: "nuestras y garantiza que ha leído la",
  signUpWarning4: "Política de privacidad",
  logout: "Cierre de sesión",
  reset: "Restablecer contraseña",
  createPoem: "Crear poema",
  paraphrase: "Parafrasee",
  Creative: "Creativo",
  Image: "Imagen",
  registrationConfirmation: "Confirmación de inscripción",
  copyToClipboard: "Copiar al portapapeles",
  buyNeurons: "Comprar AIera",
  speechToAudio: "Texto a audio",
};
