import { defineStore } from "pinia";
import { ToastMessageOptions } from "primevue/toast";
import { Ref, ref } from "vue";

import { Article } from "@/hooks/articles/useGetArticles";
import { LanguagesEnum } from "@/settings/languages";

export const DEFAULT_TOAST_LIFE_TIME = 5000;

interface ToastConfig extends ToastMessageOptions {
  action?: {
    label: string;
    handler: () => void;
  };
}

export const useAppStore = defineStore("appStore", () => {
  const currentLanguage = ref<string>(LanguagesEnum.English);
  const isLoading: Ref<boolean> = ref(false);
  const isShowLoginPopup = ref(false);
  const isShowSignupPopup = ref(false);
  const isShowProfileMenu = ref(false);
  const isShowForgotPasswordPopup = ref(false);

  const articleList = ref<Article[]>([]);

  const toast = ref<ToastConfig | null>(null);

  const updateArticles = (value: Article[]) => {
    articleList.value = value;
  };

  const updateLanguage = (value: string) => {
    currentLanguage.value = value;
  };

  const updateToast = (value: ToastConfig | null) => {
    toast.value = value;
  };

  const updateIsLoading = (value: boolean) => {
    isLoading.value = value;
  };

  const updateLoginPopup = (val: boolean) => {
    isShowLoginPopup.value = val;
  };

  const updateProfileMenu = (val: boolean) => {
    isShowProfileMenu.value = val;
  };

  const updateSignupPopup = (val: boolean) => {
    isShowSignupPopup.value = val;
  };

  const updateForgotPopup = (val: boolean) => {
    isShowForgotPasswordPopup.value = val;
  };

  const showToast = (config: ToastConfig) => {
    updateToast({ ...config, life: config.life ?? DEFAULT_TOAST_LIFE_TIME });
  };

  return {
    currentLanguage,
    isLoading,
    isShowLoginPopup,
    isShowSignupPopup,
    toast,
    isShowProfileMenu,
    articleList,
    isShowForgotPasswordPopup,

    showToast,
    updateIsLoading,
    updateLanguage,
    updateLoginPopup,
    updateSignupPopup,
    updateProfileMenu,
    updateArticles,
    updateForgotPopup,
  };
});
