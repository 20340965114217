import { Lang } from "@/types/lang";

export default <Lang>{
  termsHeading1: "Algemene voorwaarden",
  termsHeading2: "Toelaatbaarheid van gebruik",
  termsHeading3: "Garantie en Aansprakelijkheid",
  termsHeading4: "De inhoud ",
  termsHeading5: "Beperkte inhoud",
  termsHeading6: "Betalingsprocedure",
  termsHeading7: "Restitutiebeleid",
  termsHeading8: "Diensten van derden",
  termsHeading9: "Auteursrechten",
  termsHeading10: "Beëindiging",
  termsHeadingKyc: "KYC-verificatie",

  terms1:
    'Voordat u de diensten van AI Era (het "Bedrijf", "Wij", "Ons", "het Platform", "de Dienst(en)" of "de Website") gebruikt, wordt u aangeraden de volgende Algemene voorwaarden ("Overeenkomst") te lezen. Door de website of diensten op enigerlei wijze te bezoeken, erkent u dat u de voorwaarden van deze Overeenkomst hebt gelezen, begrepen en geaccepteerd en dat u ermee instemt gebonden te zijn aan alle bepalingen ervan. Als u niet akkoord gaat met alle voorwaarden van deze Overeenkomst, moet u onmiddellijk stoppen met het gebruik van het Platform of de Diensten. Het gebruik van de Diensten van het Bedrijf is afhankelijk van uw aanvaarding van deze Voorwaarden. Als u niet akkoord gaat met deze Voorwaarden, maak dan GEEN gebruik van de Website.\n' +
    "AI Era World is eigendom van en wordt beheerd door (Bedrijf).\n" +
    "(Bedrijf) is gereguleerd onder de wetten van (Rechtsgebied) als een besloten vennootschap met beperkte aansprakelijkheid, met haar statutaire zetel te (Adres)\n" +
    "Telefoonnummer:  E-mail: contact{'@'}aieraworld.com",
  terms2:
    "U garandeert ons dat: \n" +
    "- U meerderjarig bent in het land waar u woont; \n" +
    "- U in staat bent een bindend contract aan te gaan en dat het u niet bij wet verboden is de Services te gebruiken of er toegang toe te hebben; \n" +
    "- Dat de informatie die u aan ons verstrekt accuraat en waarheidsgetrouw is; \n" +
    "- U wettelijk bevoegd bent om een betalingsmethode te gebruiken, inclusief credit- of debitcard voor het uitvoeren van een bestelling (Door het verstrekken van dergelijke informatie, erkent u en machtigt u ons om de informatie te delen met betalingsverwerkende derden om de voltooiing van uw bestelling te vergemakkelijken. Als Wij fraude, een ongeautoriseerde of illegale transactie vermoeden, behouden Wij ons het recht voor om uw bestelling op elk gewenst moment te weigeren of te annuleren om bepaalde redenen, waaronder het niet beschikbaar zijn van de service, technische fouten of onjuiste prijzen).\n" +
    "In rechtsgebieden waar dit wettelijk verboden is, wordt deze Overeenkomst ongeldig en wordt het recht op toegang tot de Services ingetrokken.",
  terms3:
    "Het Platform wordt voortdurend bijgewerkt met betrekking tot de beschikbaarheid, fouten en onnauwkeurigheden van Onze Diensten. Ons Platform kan Diensten hebben die onjuist geprijsd, verouderd of niet altijd beschikbaar zijn, en We kunnen vertragingen ervaren in het updaten van informatie over onze Diensten op het Platform en in advertenties op andere websites.\n" +
    "Onze garantie dekt niet alle mogelijke informatie, inclusief prijzen en productafbeeldingen. Specificaties, beschikbaarheid of diensten kunnen verschillen en Wij hebben de bevoegdheid om informatie zonder voorafgaande kennisgeving te wijzigen of te herzien, met inbegrip van eventuele fouten, onnauwkeurigheden of weglatingen. Het Bedrijf behoudt zich het recht voor om de prijzen op elk gewenst moment te wijzigen voordat een Bestelling is ontvangen.\n" +
    "Of je nu een creditcard of bankpas gebruikt, de betaling kan worden gedaan met de kaart die je hebt gekregen en de kaartuitgever zal deze valideren. Als we niet in staat zijn om de benodigde autorisatie te verkrijgen, kunnen we niet aansprakelijk worden gesteld voor vertraging of niet-levering van je bestelling.\n" +
    "Eventuele promoties die beschikbaar worden gesteld via de Service kunnen onderhevig zijn aan regels die verschillen van deze Voorwaarden. Om ervoor te zorgen dat u voldoet aan de toepasselijke regelgeving en ons Privacybeleid, raden we u aan deze regels te lezen wanneer u een promotie overweegt. De voorwaarden van een Promotie zijn onderworpen aan dezelfde regels als deze Voorwaarden als een van die regels niet wordt nageleefd.\n" +
    "Als u een account bij ons aanmaakt, moet u nauwkeurige, volledige en actuele informatie verstrekken om uw account aan te maken. Als u dit niet doet, kan dit leiden tot de onmiddellijke beëindiging van uw account op het Platform, aangezien dit wordt beschouwd als een schending van de Voorwaarden.\n" +
    "Het is uw verantwoordelijkheid om het wachtwoord dat u gebruikt voor toegang tot de Service en alle activiteiten met betrekking tot uw wachtwoord te onderhouden, of het nu gaat om onze Service of een Sociale Mediadienst van derden. U bent verplicht tot geheimhouding wanneer u uw wachtwoord met anderen deelt. Wanneer u ontdekt dat uw account is geschonden of voor illegale doeleinden wordt gebruikt, dient u ons hiervan onmiddellijk op de hoogte te stellen.\n" +
    "Onze service is beperkt tot één gebruikersaccount per gebruiker, en als u onze voorwaarden schendt of meerdere accounts aanmaakt, kunnen we uw gegevens verwijderen en de toegang beperken. Wij verlenen u de bevoegdheid om audio- en video-inhoud te produceren of te verzenden via onze service. Het is uw verantwoordelijkheid om ervoor te zorgen dat de inhoud die met behulp van onze service(s) wordt gemaakt, voldoet aan de wettelijke vereisten, waaronder de juistheid en geschiktheid ervan. De Service verleent u het recht en de licentie om Inhoud te gebruiken, te wijzigen, uit te voeren, tentoon te stellen, te kopiëren (in zijn geheel), te reproduceren en te distribueren die is gecreëerd door uw creatie en plaatsing op de Service. De Service verleent u volledige rechten op alle Inhoud, wat betekent dat u verantwoordelijk bent voor het beschermen en behouden van deze rechten.\n" +
    "U gebruikt de Dienst op eigen risico. U erkent dat de Dienst inhoud kan bevatten die u beledigend, onfatsoenlijk, onjuist of aanstootgevend vindt, en u gaat ermee akkoord dat er geen aansprakelijkheid bestaat voor eventuele fouten of schade als gevolg van uw gebruik van ons Platform. U draagt de volledige verantwoordelijkheid voor al uw handelingen met betrekking tot de Diensten. Als u zich inlaat met frauduleus, beledigend of anderszins illegaal gedrag tijdens het gebruik van de Diensten, kunnen uw toegangs- of gebruiksrechten worden beëindigd. Het is verboden om berichten of verzoeken te plaatsen of te verzenden die bedoeld zijn om wachtwoorden, accounts of privé-informatie van andere gebruikers van het Platform te verkrijgen. Het is uitdrukkelijk verboden om de Diensten te gebruiken om inbreuk te maken op de beveiliging van een computernetwerk, wachtwoorden of beveiligingscoderingen te kraken, illegaal materiaal over te dragen of op te slaan (met inbegrip van potentieel schadelijke of illegale inhoud) of illegale activiteiten te ontplooien.\n" +
    "De broncode van het Platform zal niet worden gedecompileerd, reverse-engineered of op enige andere wijze worden verkregen. U bent verantwoordelijk voor het inhouden, indienen en rapporteren van alle belastingen, heffingen en andere overheidsaanslagen in verband met uw activiteiten in verband met de Diensten. Het Bedrijf maakt regelmatig back-ups van de inhoud, maar garandeert niet dat er geen gegevens verloren gaan of beschadigd raken. Back-uppunten kunnen ongeldig of corrupt zijn als de Content corrupt was voordat de back-up werd gemaakt of als deze tijdens het back-upproces is gewijzigd, zonder beperking. Het Bedrijf zal alles in het werk stellen om problemen op te lossen die zich kunnen voordoen, bekend of ontdekt, en die de back-ups van de Content beïnvloeden. Het Bedrijf is echter niet verantwoordelijk voor de integriteit van de Content of het falen om deze met succes te herstellen naar een bruikbare staat.",
  terms4:
    "Onze service stelt u in staat om audio-, tekst- en beeldinhoud te genereren. U bent verantwoordelijk voor de Content die u genereert met behulp van onze Diensten, inclusief de wettigheid en geschiktheid ervan. Door Content te genereren op het Platform, verleent u ons het recht en de licentie om dergelijke Content te gebruiken, aan te passen, publiekelijk uit te voeren, publiekelijk te tonen, te reproduceren en te distribueren op en via de Service. U behoudt al uw rechten op Inhoud die u creëert, plaatst of weergeeft op of via de Dienst en u bent verantwoordelijk voor het beschermen van deze rechten.\n" +
    "Het gebruik van onze Services houdt in dat u begrijpt dat het Bedrijf niet kan garanderen dat de gegenereerde inhoud 100% correct of van de beste kwaliteit of volledig waarheidsgetrouw is en dat er daarom geen sprake zal zijn van terugbetalingen of vragen over de uiteindelijke kwaliteit van de inhoud.",
  terms5:
    "U bent zich ervan bewust en gaat ermee akkoord dat u de volledige verantwoordelijkheid draagt voor de Inhoud en voor alle activiteiten die verband houden met uw account, ongeacht of deze worden uitgevoerd door u of door een andere gebruiker met toegang tot uw account. \n" +
    "Het is verboden om Inhoud te maken of te verzenden die illegaal, aanstootgevend, schandelijk, weerzinwekkend, bedreigend, lasterlijk, onfatsoenlijk, onwettig of anderszins aanstootgevend is. De volgende lijst met Inhoud kan als beperkt worden beschouwd, maar is niet beperkt tot: \n" +
    "- Het aangaan van een onwettige activiteit of het aanmoedigen van dergelijk onwettig gedrag; \n" +
    "- Het Platform gebruiken om materiaal te maken dat lasterlijk, discriminerend en beledigend is, inclusief vermeldingen of opmerkingen met betrekking tot religie, ras, seksuele geaardheid/genderidentiteit, nationale/etnische afkomst of andere groepen; \n" +
    "- De Service gebruiken om verboden activiteiten te promoten, zoals het gebruik van illegale stoffen/drugs en diensten voor volwassenen; \n" +
    "- Het genereren van spam, door machines gegenereerde of willekeurig toegewezen taken, ongeoorloofde of ongevraagde reclame, kettingbrieven;\n" +
    "- Elke andere vorm van onbeveiligde verzoeken, of elke vorm van loterij of gokken; \n" +
    "- Het gebruiken van de Service voor het creëren of ontwikkelen van virussen, malwares, trojan horses of vergelijkbaar materiaal dat is ontworpen om verstoring, schade of interferentie met de werking van software te veroorzaken; \n" +
    "- Inbreuk maken op de intellectuele eigendomsrechten van enige partij, met inbegrip van maar niet beperkt tot: octrooien, handelsmerken of handelsgeheimen, evenals auteursrechten en publiciteitsrechten; \n" +
    "- Zich voordoen als personen, inclusief werknemers en vertegenwoordigers van het Bedrijf;\n" +
    "- De privacy van anderen in gevaar brengen. \n" +
    "Tenzij anders overeengekomen, heeft het bedrijf de bevoegdheid om seksueel expliciete inhoud te weigeren of te verwijderen en te beslissen of deze geschikt is voor gebruik of voldoet aan deze voorwaarden. Het staat het Bedrijf vrij om de manier waarop de Inhoud wordt gepresenteerd aan te passen en wijzigingen aan te brengen. Het Bedrijf heeft de bevoegdheid om de Service te beperken of in te trekken als u deze gebruikt om Inhoud te maken die als aanstootgevend wordt beschouwd. Het Bedrijf is niet verantwoordelijk voor de inhoud die door gebruikers en/of derden op andere platforms wordt geplaatst.",
  termsKyc:
    "Om de Services te kunnen gebruiken, moet een gebruiker zijn e-mailadres bevestigen. Het gebruik van de Services is echter beperkt voor inwoners van de volgende landen: Noord-Korea (Democratische Volksrepubliek Korea), Iran en Myanmar.\n" +
    "Gebruikers met verdachte activiteiten of inwoners van landen met een hoog risico, zoals Afghanistan, Albanië, Barbados, Burkina Faso, Cambodja, Kaaimaneilanden, Democratische Republiek Congo, Gibraltar, Haïti, Jamaica, Jordanië, Mali, Marokko, Mozambique, Nicaragua, Pakistan, Panama, Filippijnen, Rusland, Senegal, Zuid-Soedan, Syrië, Tanzania, Trinidad en Tobago, Turkije, Oeganda, Verenigde Arabische Emiraten, Vanuatu, Jemen en Zimbabwe, kunnen onderhevig zijn aan extra verificatie (a.k.a. Know Your Customer of KYC) door het verstrekken van aanvullende gegevens en/of documenten. Dergelijke informatie kan ook vereist zijn als er een officieel verzoek is van autoriteiten of andere wetshandhavingsinstanties.",
  terms6:
    "Om toegang te krijgen tot de Diensten, moet u een voldoende hoeveelheid van de virtuele valuta van het Platform-Neurons in uw account Portemonnee hebben. U kunt uw saldo controleren en opwaarderen in de daarvoor bestemde sectie van de Website. Neurons hebben geen waarde buiten het Platform, kunnen alleen worden gebruikt op het Platform en kunnen niet worden overgedragen aan een ander platform of persoon. 10 Neurons staan gelijk aan 1 Euro.\n" +
    'Transacties worden binnen 72 uur na betaling verwerkt.Nadat de betaling is voltooid, kan het tot 24 uur duren voordat je saldo is bijgewerkt, maar dit proces verloopt meestal onmiddellijk. We hebben het recht om een derde partij payment service processor (de "Payment Processor") te gebruiken voor uw stortingen. De desbetreffende betalingsverwerker is primair verantwoordelijk voor het faciliteren van de betaling van uw transacties. Om uw transactie te verwerken, heeft de Betalingsverwerker uw persoonlijke gegevens en betalingsinformatie nodig, zoals debet-/creditcardgegevens, adres en andere informatie. Deze informatie wordt alleen gebruikt om de transacties te verwerken en wordt niet doorgegeven aan derden, met uitzondering van de financiële instellingen en regelgevende instanties die betrokken zijn bij de verwerkings- of nalevingsprocedures. U kunt meer te weten komen over het gebruik van uw persoonlijke gegevens in ons Privacybeleid.\n' +
    "Wij behouden ons het recht voor om alle transacties die u maakt bij het gebruik van de Dienst te verifiëren en goed te keuren om er zeker van te zijn dat ze voldoen aan deze Algemene Voorwaarden en de voorwaarden die door externe serviceproviders aan het Platform worden gesteld. U erkent uitdrukkelijk en gaat ermee akkoord dat dergelijke verificaties van u kunnen vereisen dat u ons aanvullende persoonlijke informatie verstrekt om uw identiteit te verifiëren en te bevestigen en om verificaties uit te voeren gericht op het ontmoedigen van fraude en misbruik van de Dienst, in overeenstemming met Ons Privacybeleid en alle toepasselijke wet- en regelgeving.\n" +
    "Door verder te gaan met een transactie, verklaart u dat alle bankkaarten en andere betaalrekeningen die u gebruikt voor transacties wettelijk de uwe zijn.",
  terms7:
    "De Content die je op onze website hebt gekocht, komt niet in aanmerking voor restitutie.U hebt geen recht op restitutie van kosten of transactieprovisies die u al hebt betaald.Restituties zijn alleen mogelijk voor transacties die zijn uitgevoerd om Neurons te kopen en alleen in geval van technische problemen met de Service die op de Website wordt aangeboden en/of andere problemen die het gevolg zijn van Onze fouten die ertoe hebben geleid dat u de Service waarvoor u hebt betaald niet hebt kunnen ontvangen.Elk verzoek voor terugbetaling moet persoonlijk worden ingediend via contact@aiaieraworld.com e-mail binnen 14 dagen vanaf het moment van de transactie.",
  terms8:
    "Links op ons platform kunnen leiden naar websites of diensten van derden die geen eigendom zijn van de Onderneming. Websites of diensten van derden kunnen worden gekoppeld aan het bedrijf, maar het bedrijf is niet verantwoordelijk voor hun inhoud, privacybeleid of praktijken. U erkent en gaat ermee akkoord dat het bedrijf niet aansprakelijk kan worden gesteld voor schade of verlies veroorzaakt door of in verband met het gebruik van of het vertrouwen op dergelijke inhoud, goederen of diensten die worden aangeboden via websites of andere diensten. We raden u ten zeerste aan de gebruiksvoorwaarden en het privacybeleid te lezen van websites of diensten van derden die u bezoekt.",
  terms9:
    "Het principe van auteursrecht vereist dat we de intellectuele eigendomsrechten van anderen erkennen en respecteren. Het is ons beleid om elke bewering dat op de service geplaatste inhoud inbreuk maakt op de intellectuele eigendomsrechten of auteursrechten van anderen, te behandelen. Als u van mening bent dat de service inbreuk op auteursrechten faciliteert en u bent eigenaar van auteursrechten of bevoegd namens hen, stuur ons dan een e-mail op contact{'@'}aiaieraworld.com om uw melding in te dienen. Vermeld in uw melding een gedetailleerde uitleg van de vermeende inbreuk. Het toegeven van een onwaarheid dat enige Inhoud inbreuk maakt op uw auteursrecht kan ertoe leiden dat u aansprakelijk wordt gesteld voor schade, inclusief kosten en honoraria van advocaten.",
  terms10:
    "We hebben de bevoegdheid om je account op te schorten of te beëindigen zonder voorafgaande kennisgeving of aansprakelijkheid, zelfs als je de voorwaarden niet schendt. Dit geldt ongeacht de reden. Je gebruik van de Service wordt beëindigd bij het verwijderen van je account. Als je je account van het Platform wilt verwijderen, kun je gewoon stoppen met het gebruik van de Dienst. Deze Overeenkomst blijft volledig van kracht zolang u de Diensten gebruikt. Het Bedrijf kan uw toegang tot de Diensten op elk gewenst moment beëindigen of opschorten. In het geval van een schending van een van de voorwaarden van deze Overeenkomst, kan het Bedrijf onmiddellijk alle Diensten en toegang tot de Website opschorten of beëindigen zonder enige aansprakelijkheid of waarschuwing.\n" +
    "Alle bepalingen van deze Overeenkomst die van kracht blijven na beëindiging zijn essentieel, inclusief eigendomsbepalingen en disclaimers. Deze beperkingen zijn ook van toepassing op aansprakelijkheidsbeperkingen. Het Bedrijf, zijn moedermaatschappijen, dochterondernemingen, gelieerde ondernemingen en werknemers zijn volledig verplicht om zich te verdedigen tegen alle vorderingen, aansprakelijkheden, schikkingen/rechtvaardige kosten, met inbegrip van advocaatkosten of schade als gevolg van uw gebruik van Services, schending van deze Overeenkomst, of de schending door u of een andere partij van uw intellectuele eigendomsrechten of andere rechten.\n" +
    "Indien een bepaling van deze Voorwaarden onafdwingbaar of ongeldig wordt geacht, zullen de overige bepalingen worden gewijzigd en geïnterpreteerd om het beoogde doel te bereiken volgens de toepasselijke wetgeving zonder dat deze worden vervangen. Tenzij anders overeengekomen, heeft het niet toepassen van een recht of verplichting onder deze Voorwaarden geen invloed op het vermogen van een partij om naleving van deze Overeenkomst af te dwingen. Bij geschillen is de oorspronkelijke Engelse versie van de Voorwaarden bepalend.\n" +
    "Deze Voorwaarden zijn aan verandering onderhevig en kunnen door ons naar eigen goeddunken worden gewijzigd of vervangen. U erkent en gaat ermee akkoord gebonden te zijn aan de herziene voorwaarden als u toegang blijft houden tot of gebruik blijft maken van onze service nadat deze herzieningen van kracht zijn geworden. Als u niet akkoord gaat met de nieuwe voorwaarden, verzoeken wij u uw gebruik van de website en de service te staken.\n" +
    "Als je vragen hebt over deze Algemene voorwaarden, kun je contact met ons opnemen via contact{'@'}aiaieraworld.com.",
};
