import { Lang } from "@/types/lang";

export default <Lang>{
  registration: "Registration",
  login: "Login",
  signUpWarning1: " By clicking log in, or continuing with the other options below, you agree to and have read",
  signUpWarning2: " Terms and Conditions ",
  signUpWarning3: "and have read the ",
  signUpWarning4: "Privacy Policy ",
  logout: "Logout",
  reset: "Reset Password",
  createPoem: "Create Poem",
  paraphrase: "Paraphrase",
  Creative: "Creative",
  // Image: "Image",
  Image: "Neuro-Vision",
  registrationConfirmation: "Registration Confirmation",
  copyToClipboard: "Copy to Clipboard",
  buyNeurons: "Buy AIera",
  // speechToAudio: "Speech to Audio",
  speechToAudio: "VoxAI",
  generationStyle: "Generation Style",
  paraphraseStyle: "Paraphrase Style",
  voices: "Voices",
  dialects: "Dialects",
  languages: "Languages",
};
