import { Lang } from "@/types/lang";

export default <Lang>{
  homeHeading: "Willkommen bei AIEra!",
  homeSubtitle:
    "Erleben Sie die Kraft der künstlichen Intelligenz zum Greifen nah. Unsere Plattform bietet benutzerfreundliche Tools und erweiterte Funktionen, die Sie bei der Entwicklung, Innovation und dem Wachstum unterstützen.",
  homeSubtitle2:
    "Egal, ob Sie Entwickler, Unternehmer oder neugieriger Enthusiast sind, unsere Plattform ermöglicht es Ihnen, das Potenzial von KI zu nutzen und Ihre Projekte zum Erfolg zu führen. Machen Sie mit und ändern Sie Ihre Einstellung zur Technologie.",
  homeHeading2: "Jetzt ausprobieren",
  serviceCard1:
    "Audio ist eine Ruhmeshalle, in der Ihr Text auf Ihren Befehl hin lebendig wird. Ich kann Ihren Text in gefühlvolle und klare Audiodateien umwandeln und Ihren Worten einen echten und einzigartigen Klang verleihen. \n" +
    "            Meine Sprachfähigkeiten können ein Werkzeug für eine Vielzahl von Aufgaben sein, von Text-to-Speech bis hin zur Erstellung von ansprechenden, praktischen Materialien.",
  serviceCard2:
    "Generate Images ist eine Schatztruhe der Kreativität, die eine Welt unglaublicher visueller Bilder eröffnet. Ich kann grafische Bilder schaffen, die abstrakte Beschreibungen und Ideen verkörpern.\n" +
    "            Das Ergebnis ist eine Harmonie von Formen und Farben, die es Ihnen ermöglicht, Ihre Welt aus einer neuen, inspirierenden Perspektive zu sehen.",
  serviceCard3:
    "Text Mode ist ein Werkzeug, das Ihnen die Möglichkeit bietet, einzigartige Texte, Gedichte und mehr zu erstellen. Ich bin bereit, Ihnen einen unerschöpflichen Fluss von Wörtern zu geben, die Ihre Fantasie bereichern und Ihre Kreativität inspirieren.\n" +
    "            Meine Algorithmen zur Texterstellung sind so leistungsfähig, dass sie Ihnen helfen werden, erstaunliche und lebendige Werke zu schaffen, \n" +
    "            die Sie in Ihre Ideen eintauchen lassen.",
  textServiceHeading: "Text-Dienste",
  textServiceName: "Gedicht-Generator",
  textServiceHint:
    "Erzeugt Gedichte auf Anfrage unter Beibehaltung des Stils.\n" +
    "Beispiel für eine Anfrage: „Schreiben Sie eine Kurzgeschichte über einen Magier in der Neuzeit im Stil von Edgar Allan Poe“.\n",
  imageServiceHint:
    "Erzeugt auf Anfrage Bilder im gewählten Stil. Beispiel für eine Anfrage: „Riesige Steampunk-Roboter in Hawaiihemden“.",
  creativeServiceHint: "Generiert 3 kreative Ideen zu einem ausgewählten Thema.",
  paraphraseServiceHint: "Paraphrasiert den eingegebenen Text in einem gewählten Stil.",
  instruction1: "Geben Sie den Betreff ein (nicht mehr als 300 Zeichen)",
  instruction2: "Wählen Sie den Stil, die Anzahl und die Größe der Strophen",
  instruction3: "Klicken sie auf erzeugen",
  loginWarning1: "Indem Sie auf „Anmelden“ klicken oder mit den anderen Optionen unten fortfahren, stimmen Sie dem zu ",
  loginWarning2: "Geschäftsbedingungen",
  loginWarning3: " und habe das gelesen ",
  loginWarning4: "Datenschutzrichtlinie",
  newUser: "Neu bei AI Factory? Anmelden",
  forgotPassword: "Haben Sie Ihr Passwort vergessen?",
  logoutWarning: "Von Ihrem Konto abmelden?",
  signUpWarning: "Sie haben bereits ein Konto? Anmelden",
  audioHint:
    "Text-to-Speech erweckt Ihren Text auf Ihren Befehl hin zum Leben. Ich kann Ihren Text in klare und einfühlsame Audiodateien umwandeln, die Ihren Worten einen einzigartigen Klang verleihen.",
  minBuyWarning: "Der mindesteinzahlungsbetrag für eine transaktion beträgt 10 EUR.",
  maxBuyWarning: "Der maximale einzahlungsbetrag pro transaktion beträgt 1000 EUR.",
  copyright: "© 2024 Techfox Group LTD.",
  buyAgreement1: "Ik heb het gelezen, begrepen en ga ermee akkoord\n",
  buyAgreement2: "und zum",
  buyAgreement3: "der Website",
  buyAgreement4: "Datenschutzrichtlinie",
  buyAgreement5: "Nutzungsbedingungen",
  homeServiceTitle1: "Audio",
  homeServiceTitle2: "Generate Images",
  homeServiceTitle3: "Text Mode",
  textServiceTitle2: "Paraphrasieren",
  textServiceTitle3: "Kreativ",
};
