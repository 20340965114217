import { Lang } from "@/types/lang";

export default <Lang>{
  termsHeading1: "Conditions générales d'utilisation",
  termsHeading2: "Éligibilité de l'utilisation",
  termsHeading3: "Garantie et responsabilité",
  termsHeading4: "Le Contenu",
  termsHeading5: "Contenu restreint",
  termsHeading6: "Procédure de paiement",
  termsHeading7: "Politique de remboursement",
  termsHeading8: "Services de tiers",
  termsHeading9: "Droits d'auteur",
  termsHeading10: "Résiliation",
  termsHeadingKyc: "Vérification KYC",

  terms1:
    'Avant d\'utiliser les services fournis par AI Era (la "société", "nous", "la plateforme", le(s) "service(s)" ou "le site web"), il est recommandé de lire les conditions générales suivantes ("l\'accord"). En accédant au site web ou aux services de quelque manière que ce soit, vous reconnaissez avoir lu, compris et accepté les conditions générales du présent accord et vous acceptez d\'être lié par toutes ses dispositions. Si vous n\'acceptez pas toutes les conditions du présent accord, vous devez cesser immédiatement d\'utiliser la plate-forme ou les services. L\'utilisation des services de la société est subordonnée à votre acceptation des présentes conditions. Si vous n\'acceptez pas ces conditions, veuillez NE PAS utiliser le site web.\n' +
    "AI Era World est détenu et exploité par (l'entreprise).\n" +
    "(Société) est une société à responsabilité limitée régie par les lois de (Juridiction), dont le siège social est situé à (Adresse).\n" +
    "Numéro de téléphone :  Courriel : contact{'@'}aieraworld.com",
  terms2:
    "Vous nous garantissez que \n" +
    "- Vous avez l'âge légal dans votre pays de résidence ; \n" +
    "- Vous êtes capable de conclure un contrat contraignant et que la loi ne vous interdit pas d'utiliser les services ou d'y accéder ; \n" +
    "- Les informations que vous nous fournissez sont exactes et véridiques ; \n" +
    "- Vous êtes légalement autorisé à utiliser toute méthode de paiement, y compris une carte de crédit ou de débit pour remplir toute commande (en fournissant ces informations, vous reconnaissez et nous autorisez à partager ces informations avec des tiers chargés du traitement des paiements afin de faciliter l'exécution de votre commande. Si nous soupçonnons une fraude, une transaction non autorisée ou illégale, nous nous réservons le droit de refuser ou d'annuler votre commande à tout moment pour certaines raisons, notamment l'indisponibilité du service, des erreurs techniques ou une tarification incorrecte).\n" +
    "Dans les juridictions où la loi l'interdit, le présent accord devient invalide et le droit d'accès aux services est révoqué.",
  terms3:
    "La Plateforme est continuellement mise à jour en ce qui concerne la disponibilité, les erreurs et les inexactitudes de nos services. Notre Plateforme peut contenir des Services dont le prix est inexact, qui sont dépassés ou qui ne sont pas disponibles à tout moment, et Nous pouvons subir des retards dans la mise à jour des informations concernant notre Service sur la Plateforme et dans la publicité sur d'autres sites web.\n" +
    "Notre assurance ne couvre pas toutes les informations possibles, y compris les prix et les images des produits. Les spécifications, la disponibilité ou les services peuvent différer, et nous avons le pouvoir de modifier ou de réviser les informations sans préavis, y compris les erreurs, les inexactitudes ou les omissions. La Société se réserve le droit de modifier ses prix à tout moment avant la réception d'une commande.\n" +
    "Que vous utilisiez une carte de crédit ou de débit, le paiement peut être effectué à l'aide de la carte qui vous a été délivrée, et l'émetteur de la carte la validera. Si nous ne sommes pas en mesure d'obtenir l'autorisation nécessaire, nous ne pourrons être tenus responsables du retard ou de la non-livraison de votre commande.\n" +
    "Les promotions proposées par l'intermédiaire du service peuvent être soumises à des règles distinctes des présentes conditions. Afin de garantir votre conformité aux réglementations applicables et à notre politique de protection de la vie privée, nous vous recommandons de lire ces règles avant d'envisager toute promotion. Les conditions d'une promotion seront soumises aux mêmes règles que les présentes conditions si l'une de ces règles n'est pas respectée.\n" +
    "Si vous créez un compte chez nous, vous devez fournir des informations exactes, complètes et à jour pour créer votre compte. Le non-respect de cette obligation peut entraîner la résiliation immédiate de votre compte sur la Plateforme, car il est considéré comme une violation des Conditions.\n" +
    "Il est de votre responsabilité de conserver le mot de passe que vous utilisez pour accéder au Service et à toute activité liée à votre mot de passe, que ce soit avec Notre Service ou un Service de médias sociaux tiers. Vous êtes tenu de respecter la confidentialité lorsque vous partagez votre mot de passe avec d'autres personnes. Si vous découvrez que votre compte a été violé ou utilisé à des fins illégales, vous devez nous en informer rapidement.\n" +
    "Notre service est limité à un compte d'utilisateur par utilisateur, et si vous enfreignez nos conditions ou créez plusieurs comptes, nous pouvons supprimer vos données et en restreindre l'accès. Nous vous donnons le pouvoir de produire ou de transmettre du contenu audio et vidéo par l'intermédiaire de notre service. Il vous incombe de veiller à ce que le contenu créé à l'aide de notre (nos) service(s) réponde aux exigences légales, notamment en ce qui concerne son exactitude et son caractère approprié. Le Service vous accorde le droit et la licence d'utiliser, de modifier, d'exécuter, d'exposer, de copier (dans son intégralité), de reproduire et de distribuer le Contenu créé par vos soins et mis en ligne. Le service vous accorde des droits complets sur tout le contenu, ce qui signifie que vous êtes responsable de la protection et de la conservation de ces droits.\n" +
    "Vous utilisez le service à vos propres risques. Vous reconnaissez que le service peut contenir des éléments que vous pouvez juger offensants, indécents, incorrects ou répréhensibles, et vous acceptez qu'aucune responsabilité n'existe pour les erreurs ou les dommages résultant de votre utilisation de notre plate-forme. Vous êtes entièrement responsable de toutes vos actions liées aux services. Si vous adoptez un comportement frauduleux, abusif ou autrement illégal lors de l'utilisation des services, vos droits d'accès ou d'utilisation pourraient être résiliés. Il vous est interdit de publier ou de transmettre tout message ou toute sollicitation visant à obtenir des mots de passe, des comptes ou des informations privées d'autres utilisateurs de la plate-forme. Il est expressément interdit d'utiliser les services pour porter atteinte à la sécurité d'un réseau informatique, craquer des mots de passe ou des codes de cryptage de sécurité, transférer ou stocker du matériel illégal (y compris du contenu potentiellement nuisible ou illégal) ou s'engager dans une activité illégale.\n" +
    "Le code source de la plate-forme ne sera pas décompilé, désossé ou obtenu par d'autres moyens. Vous êtes responsable de la retenue, du dépôt et de la déclaration de tous les impôts, droits et autres évaluations gouvernementales liés à votre activité en rapport avec les services. La société met en œuvre des sauvegardes régulières du contenu, mais ne garantit pas l'absence de perte ou de corruption des données. Les points de sauvegarde peuvent être invalides ou corrompus si le contenu était corrompu avant d'être sauvegardé ou modifié au cours du processus de sauvegarde, sans limitation. La Société fera tout son possible pour résoudre les problèmes qui peuvent survenir, qu'ils soient connus ou découverts, et qui affectent les sauvegardes du contenu. Cependant, la Société n'est pas responsable de l'intégrité du contenu ou de son incapacité à le restaurer avec succès dans un état utilisable.",
  terms4:
    "Notre service vous permet de générer du contenu audio, texte et image. Vous êtes responsable du contenu que vous générez en utilisant nos services, y compris de sa légalité et de son caractère approprié. En générant du Contenu sur la Plateforme, vous nous accordez le droit et la licence d'utiliser, de modifier, d'exécuter publiquement, d'afficher publiquement, de reproduire et de distribuer ce Contenu sur et à travers le Service. Vous conservez tous vos droits sur tout Contenu que vous créez, publiez ou affichez sur ou par le biais du Service, et vous êtes responsable de la protection de ces droits.\n" +
    "L'utilisation de nos services implique que vous compreniez que l'entreprise ne peut garantir que le contenu généré est 100% correct ou de la meilleure qualité ou entièrement véridique et, par conséquent, ne fera l'objet d'aucun remboursement ou demande concernant la qualité du contenu final.",
  terms5:
    "Vous êtes conscient et acceptez d'être entièrement responsable du contenu ainsi que de toutes les activités associées à votre compte, qu'elles soient effectuées par vous ou par un autre utilisateur ayant accès à votre compte. \n" +
    "Il vous est interdit de créer ou de transmettre tout contenu illégal, offensant, déshonorant, destiné à être répugnant, menaçant, diffamatoire, indécent, illégal ou autrement offensant. La liste suivante de contenus peut être considérée comme restreinte, sans toutefois s'y limiter : \n" +
    "- La participation à une activité illégale ou l'encouragement d'un tel comportement illégal ; \n" +
    "- Utiliser la plate-forme pour créer des documents diffamatoires, discriminatoires et offensants, y compris toute mention ou remarque concernant la religion, la race, l'orientation sexuelle/l'identité de genre, l'origine nationale/ethnique ou d'autres groupes ; \n" +
    "- Utiliser le service pour promouvoir des activités interdites telles que la consommation de substances ou de drogues illégales et les services pour adultes ; \n" +
    "- Générer du spam, des tâches générées par des machines ou attribuées au hasard, de la publicité non autorisée ou non sollicitée, des chaînes de lettres ;\n" +
    "- toute autre forme de sollicitation non sécurisée, ou toute forme de loterie ou de jeu ; \n" +
    "- utiliser le service pour créer ou développer des virus, des logiciels malveillants, des chevaux de Troie ou tout autre matériel similaire conçu pour perturber, endommager ou interférer avec le fonctionnement d'un logiciel ; \n" +
    "- enfreindre les droits de propriété intellectuelle de toute partie, y compris, mais sans s'y limiter, les brevets, les marques ou les secrets commerciaux, ainsi que les droits d'auteur et les droits de publicité ; \n" +
    "- Usurper l'identité de personnes, y compris d'employés et de représentants de l'entreprise ;\n" +
    "- mettre en danger la vie privée d'autrui. \n" +
    "Sauf accord contraire, la société a le pouvoir de rejeter ou de supprimer le contenu sexuellement explicite et de décider s'il est approprié à l'utilisation ou au respect des présentes conditions. La Société est libre de modifier la façon dont le contenu est présenté et d'apporter des changements. La société a le pouvoir de restreindre ou de révoquer le service si vous l'utilisez pour créer un contenu considéré comme offensant. La Société n'est pas responsable du contenu publié par les utilisateurs et/ou les tiers sur d'autres plateformes.",
  termsKyc:
    "Pour utiliser les services, l'utilisateur doit confirmer son adresse électronique. Toutefois, l'utilisation des services est restreinte pour les résidents des pays suivants : Corée du Nord (République populaire démocratique de Corée), Iran et Myanmar : Corée du Nord (République populaire démocratique de Corée), Iran et Myanmar.\n" +
    "Les utilisateurs ayant des activités suspectes ou résidant dans des pays à haut risque, tels que l'Afghanistan, l'Albanie, la Barbade, le Burkina Faso, le Cambodge, les îles Caïmans, la République démocratique du Congo, Gibraltar, Haïti, la Jamaïque, la Jordanie, le Mali, le Maroc, le Mozambique, le Nicaragua, le Pakistan, le Panama et les Philippines, Mozambique, Nicaragua, Pakistan, Panama, Philippines, Russie, Sénégal, Soudan du Sud, Syrie, Tanzanie, Trinité-et-Tobago, Turquie, Ouganda, Émirats arabes unis, Vanuatu, Yémen et Zimbabwe, peuvent faire l'objet de vérifications supplémentaires (a.k.a. Know Your Customer ou KYC) en fournissant des données et/ou des documents supplémentaires. Ces informations peuvent également être requises en cas de demande officielle des autorités ou d'autres organes chargés de l'application de la loi.",
  terms6:
    "Pour accéder aux Services, vous devez disposer d'un montant suffisant de la monnaie virtuelle de la Plateforme, les Neurones, dans le Portefeuille de votre compte. Vous pouvez vérifier votre solde et le recharger dans la section appropriée du site web. Les Neurons n'ont aucune valeur en dehors de la Plateforme, ne peuvent être utilisés que sur la Plateforme et ne peuvent être transférés à une autre plateforme ou à une autre personne. 10 Neurons équivalent à 1 Euro.\n" +
    "Les transactions sont traitées dans les 72 heures suivant le paiement. Une fois le paiement effectué, la mise à jour de votre solde peut prendre jusqu'à 24 heures, mais ce processus est généralement instantané. Nous avons le droit de faire appel à un prestataire de services de paiement tiers (le \"prestataire de services de paiement\") pour vos dépôts. Le responsable du traitement des paiements est principalement chargé de faciliter le paiement de vos transactions. Pour traiter votre transaction, le prestataire de services de paiement a besoin de vos données personnelles et de vos informations de paiement, telles que les données de votre carte de débit/crédit, votre adresse et d'autres informations. Ces informations ne sont utilisées que pour traiter les transactions et ne sont pas divulguées à des tiers, à l'exception des institutions financières et réglementaires impliquées dans les procédures de traitement ou de conformité. Pour en savoir plus sur l'utilisation de vos données personnelles, consultez notre politique de confidentialité.\n" +
    "Nous nous réservons le droit de vérifier et d'approuver toutes les transactions que vous effectuez en utilisant le Service afin de nous assurer qu'elles sont conformes aux présentes Conditions générales et aux conditions imposées à la Plateforme par les fournisseurs de services tiers. Vous reconnaissez et acceptez expressément que ces vérifications peuvent nécessiter que vous nous fournissiez des informations personnelles supplémentaires afin de vérifier et de confirmer votre identité et d'effectuer des vérifications visant à décourager la fraude et l'utilisation abusive du Service, conformément à notre politique de confidentialité et à toutes les lois et réglementations applicables.\n" +
    "En procédant à une transaction, vous certifiez que toutes les cartes bancaires et autres comptes de paiement que vous utilisez pour les transactions vous appartiennent légalement.",
  terms7:
    "Le contenu acheté sur notre site web ne peut faire l'objet d'un remboursement. Vous n'avez droit à aucun remboursement pour les frais ou les commissions de transaction que vous avez déjà payés. Les remboursements ne sont possibles que pour les transactions effectuées pour acheter des neurones et uniquement en cas de problèmes techniques avec le service fourni sur le site web et/ou d'autres problèmes découlant de nos erreurs qui ont conduit à votre incapacité à recevoir le service pour lequel vous avez payé. Toute demande de remboursement doit être soumise personnellement par e-mail à contact{'@'}aiaieraworld.com dans un délai de 14 jours à compter de la date de la transaction.",
  terms8:
    "Des liens sur notre Plateforme peuvent conduire à des sites web ou des services de tiers qui n'appartiennent pas à la Société. Les sites web ou services de tiers peuvent être liés à la Société, mais la Société n'est pas responsable de leur contenu, de leurs politiques de confidentialité ou de leurs pratiques. Vous reconnaissez et acceptez que la Société ne peut être tenue responsable de tout dommage ou perte causé par ou en rapport avec l'utilisation ou la confiance accordée à ces contenus, biens ou services offerts par l'intermédiaire de sites web ou d'autres services. Nous vous recommandons vivement de lire les conditions d'utilisation, ainsi que les politiques de confidentialité, de tout site web ou service tiers auquel vous accédez.",
  terms9:
    "Les liens sur notre plateforme peuvent mener à des sites web ou à des services de tiers qui n'appartiennent pas à la société. Les sites web ou services de tiers peuvent être liés à la Société, mais la Société n'est pas responsable de leur contenu, de leurs politiques de confidentialité ou de leurs pratiques. Vous reconnaissez et acceptez que la Société ne peut être tenue responsable de tout dommage ou perte causé par ou en relation avec l'utilisation ou la confiance accordée à ces contenus, biens ou services offerts par l'intermédiaire de sites web ou d'autres services. Nous vous recommandons vivement de lire les conditions d'utilisation et les politiques de confidentialité de tout site web ou service tiers auquel vous accédez.",
  terms10:
    "Le principe des droits d'auteur nous oblige à reconnaître et à respecter les droits de propriété intellectuelle d'autrui. Notre politique consiste à répondre à toute allégation selon laquelle le contenu publié sur le service enfreint les droits de propriété intellectuelle ou les droits d'auteur d'autrui. Si vous pensez que le Service facilite la violation des droits d'auteur, et que vous êtes soit un détenteur de droits d'auteur, soit autorisé en son nom, veuillez nous envoyer un courriel à contact{'@'}aiaieraworld.com pour soumettre votre avis. Veuillez inclure une explication détaillée de la violation supposée dans votre notification. L'admission d'un mensonge selon lequel un contenu enfreint vos droits d'auteur peut vous rendre responsable des dommages, y compris les coûts et les frais d'avocat.",
  terms11:
    "Nous avons le pouvoir de suspendre ou de résilier votre compte sans préavis ni responsabilité, même si vous ne violez pas les conditions. Cette disposition s'applique quelle que soit la raison. La suppression de votre compte met fin à votre utilisation du service. Si vous souhaitez supprimer votre compte de la plateforme, il vous suffit de cesser d'utiliser le service. Le présent accord restera pleinement en vigueur tant que vous utiliserez les services. La société peut résilier ou suspendre votre accès aux services à tout moment. En cas de violation de l'une des conditions générales du présent accord, la société peut immédiatement suspendre ou résilier tous les services et l'accès au site web sans aucune responsabilité ni avertissement.\n" +
    "Toutes les dispositions du présent accord qui restent en vigueur après la résiliation sont essentielles, y compris les dispositions relatives à la propriété et les clauses de non-responsabilité. Ces limitations s'appliquent également aux limitations de responsabilité. La société, ses parents, ses filiales, ses sociétés affiliées et ses employés sont entièrement tenus de se défendre contre toute réclamation, responsabilité, règlement/coûts raisonnables, y compris les frais d'avocat ou les dommages résultant de votre utilisation des services, de la violation du présent accord ou de la violation par vous ou par une autre partie de votre propriété intellectuelle ou d'autres droits.\n" +
    "Si une disposition des présentes conditions est jugée inapplicable ou invalide, les autres dispositions seront modifiées et interprétées de manière à atteindre l'objectif visé conformément à la législation applicable, sans être remplacées. Sauf accord contraire, la non-application d'un droit ou d'une obligation en vertu des présentes conditions n'affectera pas la capacité de l'une ou l'autre partie à faire respecter le présent accord. En cas de litige, c'est la version anglaise originale des conditions qui prévaut.\n" +
    "Les présentes conditions sont susceptibles d'être modifiées et peuvent être modifiées ou remplacées par nous à notre seule discrétion. Vous reconnaissez et acceptez d'être lié par les conditions révisées si vous continuez à accéder à notre service ou à l'utiliser après l'entrée en vigueur de ces révisions. Si vous n'acceptez pas les nouvelles conditions, veuillez cesser d'utiliser le site web et le service.\n" +
    "Si vous avez des questions concernant les présentes conditions générales, veuillez nous contacter à l'adresse suivante : contact{'@'}aiaieraworld.com.",
};
