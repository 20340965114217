import { Lang } from "@/types/lang";

export default <Lang>{
  homeHeading: "Welcome to AIEra!",
  homeSubtitle:
    "Experience the power of artificial intelligence at your fingertips. Our platform offers easy-to-use tools and advanced features to help you build, innovate, and grow.",
  homeSubtitle2:
    "Whether you're a developer, entrepreneur, or curious enthusiast, our platform empowers you to harness AI's potential and drive your projects to success. Join us and change the way you think about technology.\n",
  homeHeading2: "Try It Now",
  serviceCard1:
    "Audio is a hall of fame where your text comes alive at your command. I can transform your text into heartfelt and clear audio files, giving your words a real and unique sound. \n" +
    "            My voice capabilities can be a tool for a wide range of tasks, from text-to-speech to creating engaging, practical materials.",
  serviceCard2:
    "Generate Images is a treasure trove of creativity that opens up a world of incredible visual imagery. I can create graphic images that embody abstract descriptions and ideas.\n" +
    "            The results display a harmony of shapes and colours, allowing you to see your world from a new, inspiring perspective",
  serviceCard3:
    "Text Mode is a tool that offers you the opportunity to create unique texts, poems and more. I am ready to give you an inexhaustible flow of words that enrich your imagination and inspire your creativity.\n" +
    "            My text generation algorithms are filled with power that will help you create amazing and vivid works, \n" +
    "            immersing you in your ideas.",
  textServiceHeading: "Text Mode",
  textServiceName: "Poems Generator",
  textServiceHint:
    "Generates poems on request while preserving the style. \n" +
    'Request example: "Write a short story about a magician in the modern times in the style of Edgar Allan Poe".\n',
  creativeServiceHint: "Generates 3 creative ideas on a chosen topic.",
  paraphraseServiceHint: "Paraphrases the entered text in a chosen style.",
  imageServiceHint:
    'Generates images on request in chosen style. Request example: "Giant steampunk robots in Hawaiian shirts".',
  instruction1: "Enter the subject (No more than 300 characters)",
  instruction2: "Select the style, number and size of stanzas",
  instruction3: "Click generate",
  loginWarning1: "By clicking log in, or continuing with the other options below, you agree to ",
  loginWarning2: "Terms and Conditions",
  loginWarning3: " and have read the ",
  loginWarning4: "Privacy Policy",
  newUser: "New to AI Factory? Sign Up",
  forgotPassword: "Forgot your password?",
  logoutWarning: "Log out of your account?",
  signUpWarning: "Already have an account? Sign In",
  audioHint:
    "Text-to-Speech brings your text to life at your command. I can convert your text into clear and heartfelt audio files, providing a unique sound to your words.",
  minBuyWarning: "The minimum deposit amount per one transaction is 10 EUR.",
  maxBuyWarning: "The maximum deposit amount per one transaction is 1000 EUR.",
  copyright: "© 2024 Techfox Group LTD.",
  buyAgreement1: "I have read, understood and agreed to the",
  buyAgreement2: "and to the",
  buyAgreement3: " of the website",
  buyAgreement4: "Privacy Policy",
  buyAgreement5: "Terms of Use",
  textServiceTitle1: "Poems Generator",
  textServiceTitle2: "Paraphrasing",
  textServiceTitle3: "Creative",
};
