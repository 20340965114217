import { Lang } from "@/types/lang";

export default <Lang>{
  registration: "Registrazione",
  login: "Accesso",
  signUpWarning1: "Facendo clic su Accedi o continuando con le altre opzioni sottostanti, l'utente accetta i nostri ",
  signUpWarning2: "Termini di servizio ",
  signUpWarning3: "e garantisce di aver letto ",
  signUpWarning4: "l'Informativa sulla privacy",
  logout: "Disconnessione",
  reset: "Reimpostare la password",
  createPoem: "Creare una poesia",
  paraphrase: "Parafrasi",
  Creative: "Creativo",
  Image: "Immagine",
  registrationConfirmation: "Conferma della registrazione",
  copyToClipboard: "Copia negli Appunti",
  buyNeurons: "Acquista AIera",
  speechToAudio: "Da testo ad audio",
};
