import { Lang } from "@/types/lang";

export default <Lang>{
  question1: "What is AI AIera?",
  question2: "How does AIera function?",
  question3: "What Services does the Platform offer?",
  question4: "How can I access AIera's Services?",
  question5: "What are “Quanties” and how can I acquire them?",
  question6: "How does the Platform calculate the final cost of a content generation?",
  question6_1: "How long does it take for transactions to be processed?",
  question7: "What applications does AIera have?",
  question8: "What is the technology behind AIera?",
  question9: "What languages can AIera process?",
  question10: "How does the Platform ensure the quality of the generated content?",
  answer1:
    "AIera is a versatile neural network, which is capable of creating texts, revising them, offering inventive ideas, and generating ultra-realistic images and audio files with lifelike voices.",
  answer2:
    "AIera utilizes cutting-edge, neural networks proficient in analyzing and generating content, while being creative enough to provide unique ideas and solutions.",
  answer3:
    "AIera consists of three distinct sections: The Text-generation service enables users to rephrase text, create innovative ideas, poems, and more. The Text-to-Speech service offers 6 highly realistic voices for creating audio content. The Image generation section allows users to create any image in 17 different styles.",
  answer4:
    "To use the Services, you need to create an account and verify your email. Once registered, you can proceed to the desired Service, ensuring you have sufficient “Quanties” to utilize them.\n",
  answer5:
    'A balance system has been implemented on our platform where “Quanties” serve as the special currency to access AIera\'s Services. To get “Quanties”, go to your profile and click the "Buy Quanties" button.\n',
  answer6:
    "There is a basic cost for every Service. For example, 1 image generation costs 50 “Quanties”, there are no additional options, so the price is static. For other services such as Text-to-Speech, there are additional features like Language, text size and voice type. All these additional options scale the price and we made sure that the final cost is properly displayed before a user spends their “Quanties”.\n",
  answer6_1:
    "Transactions are typically processed within 72 hours of payment. After the payment is complete, it may take up to 24 hours for your account balance to be updated, though the process is usually instant\n",
  answer7:
    "AIera can be used to generate quality text, augment and rewrite existing content, provide creative ideas, create realistic images and audio files.\n",
  answer8:
    "AIera is based on advanced artificial intelligence technologies, including neural networks and deep learning, and uses content generation and transformation techniques.\n",
  answer9:
    "AIera can process text in many languages, including but not limited to English, Dutch, Spanish, French, German, Italian and other popular languages.\n",
  answer10:
    "AIera uses advanced models and algorithms that are trained on large amounts of data and equipped with quality control mechanisms to ensure that high-quality content is generated, though we cannot guarantee 100% accurate results.\n",
};
