import { Lang } from "@/types/lang";

export default <Lang>{
  transactions: "Transacties",
  description: "Beschrijving",
  neurons: "AIera",
  date: "Datum",
  howToUse: "Hoe te gebruiken:",
  profile: "Profiel",
  password: "Wachtwoord",
  generatingPoems: "Gedichten genereren",
  stanzaCount: "Aantal strofen",
  stanzaSize: "Strofen grootte",
  paraphrasing: "Parafraseren",
  style: "Stijl",
  generatorImages: "Afbeeldingsgenerator",
  imageService: "Afbeeldingsdiensten",
  generatingImages: "Afbeeldingen genereren",
  creative: "Creatief",
  contactUs: "Contact",
  phone: "Telefoon",
  registrationNumber: "Registratienummer",
  whereWeAreLocated: "Onze locatie",
  newPassword: "Nieuw wachtwoord",
  confirmPassword: "Bevestig wachtwoord",
  audioService: "Audiodiensten",
  generatingAudio: "Tekst naar audio generatie",
  generatorAudio: "Audio generatie",
  buyNeurons: "AIera kopen",
  swap: "WISSEL",
  enterSubject: "Onderwerp Invoeren",
  selectStyle: "Selecteer stijl",
  pleaseLogin: "Log in om verder te gaan",
  instructions: "Instruktioner",
  selectTheme: "Selecteer thema",
  resetPassword: "Wachtwoord opnieuw instellen",
  noNeurons: "Uw saldo is laag. Koop alstublieft Neurons om verder te gaan.",
  voiceSelection: "Stem Selectie",
  generation: "Genereren",
  enterText: "Tekst invoeren",
  privacyPolicy: "Privacybeleid",
  termsAndConditions: "Algemene voorwaarden",
  textTitle: "Tekst",
  imageTitle: "Image",
  audioTitle: "Audio",
};
