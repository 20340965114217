import { Lang } from "@/types/lang";

export default <Lang>{
  privacyHeading1: "Politique de confidentialité",
  privacyHeading2: "L'objectif de la collecte de vos informations",
  privacyHeading3: "Cookies",
  privacyHeading4: "Divulgation des données",
  privacyHeading5: "Période de conservation",
  privacyHeading6: "Protection des données",
  privacyHeading7: "GDPR",
  privacyHeading8: "Liens vers des sites web de tiers",
  privacy1:
    "AI Era s'engage à préserver la confidentialité de ses clients. La présente Politique de confidentialité décrit la manière dont notre Plateforme recueille, utilise, partage, stocke, protège et divulgue vos Informations personnelles ou vos Informations d'utilisation.\n" +
    "En utilisant nos services, vous acceptez et consentez à la présente politique de confidentialité. Si vous ne respectez pas cette politique de confidentialité, vous devez cesser immédiatement d'utiliser nos services.\n" +
    "(Société) est une société à responsabilité limitée régie par la législation du (Pays), dont le siège social est situé à (adresse). Numéro d'enregistrement :  Numéro de téléphone : + E-mail : contact{'@'}aieraworld.com",
  privacy2:
    "vos informations Les finalités pour lesquelles nous recueillons vos informations comprennent, sans s'y limiter  :\n" +
    "\t•\tEnregistrement et la gestion de votre compte ;\n" +
    "\t•\tLa communication directe avec vous  ;\n" +
    "\t•\tLa fourniture d'informations à notre sujet, la publication de critiques, de messages sur les forums ou de contenu  ; \n" +
    "\t•\tLa réponse à vos demandes ;\n" +
    "\t•\tLe respect des dispositions légales applicables.\n" +
    "Nous recueillons des informations sur votre ordinateur ou votre appareil mobile lorsque vous utilisez nos services et nous les utilisons pour analyser vos données. Les données que nous recueillons comprennent les adresses IP des ordinateurs, les identifiants des appareils mobiles et les détails techniques concernant votre ordinateur ou votre appareil mobile.",
  privacy3:
    "Les \"cookies\" sont de petits fichiers stockés sur votre ordinateur ou votre appareil mobile qui nous permettent de suivre l'utilisation de nos services et d'identifier quand vous visitez le site web.\n" +
    "La modification des préférences de votre navigateur ou de votre appareil mobile peut empêcher les cookies d'être acceptés sur votre ordinateur ou votre appareil, ce qui peut entraver l'utilisation de certaines fonctionnalités disponibles sur Notre Plateforme. Vous reconnaissez et acceptez que la présente politique de confidentialité ne couvre pas l'utilisation de cookies par des tiers.",
  privacy4:
    "Notre politique interdit le transfert de vos informations personnelles à des tiers à des fins de monétisation par le biais de la publicité ou de la vente. Toutefois, nous pouvons partager vos informations personnelles avec des tiers qui fournissent des services tels que l'analyse commerciale, le service à la clientèle, le marketing, la distribution et le traitement des paiements. Les fournisseurs peuvent collecter des données en notre nom pour gérer les fonctionnalités de l'application ou fournir des services personnalisés. Les informations fournies par les vendeurs tiers sont exclusivement destinées à leurs propres fins et ne peuvent être partagées ou exploitées à d'autres fins.\n" +
    "Nous pouvons divulguer vos informations personnelles et vos informations d'utilisation à des entités gouvernementales lorsque cela est nécessaire, par exemple en réponse à une ordonnance du tribunal ou à une demande du gouvernement. Si nous devons nous conformer à une demande légale, une notification par courrier électronique sera envoyée à votre boîte de réception.",
  privacy5:
    "La durée de conservation de vos données personnelles est déterminée par la Société en fonction du temps nécessaire pour se conformer à la présente politique de confidentialité et peut varier. Nous conserverons et utiliserons vos données si nécessaire pour remplir nos obligations légales, résoudre les litiges et appliquer nos accords et politiques. En règle générale, les données sont conservées pendant un an à compter de la dernière fourniture (ou mise à jour) de données par l'utilisateur.",
  privacy6:
    "Nous voulons que vous nous fassiez confiance et que vous ayez l'assurance que vos informations sont protégées. Nous adhérons à des mesures de sauvegarde standard pour les informations personnelles et les informations d'utilisation qui sont communément acceptées. Bien qu'aucun service ne puisse garantir une sécurité totale, nous avons mis en œuvre les mesures administratives, techniques et de sécurité nécessaires pour assurer la protection de vos informations. Le personnel autorisé, les agents, les fournisseurs tiers et les autres entités n'utilisent nos données qu'aux fins requises. Nous utilisons des pare-feu appropriés pour les protéger contre toute utilisation non autorisée, ainsi que des technologies de cryptage et des systèmes de détection d'intrusion.\n" +
    "L'Entreprise prendra toutes les mesures nécessaires pour s'assurer que vos données sont traitées en toute sécurité et conformément à la présente politique de confidentialité. Aucune donnée à caractère personnel ne sera transférée au niveau international à moins que des contrôles adéquats ne soient mis en place pour protéger vos données et autres informations.",
  privacy7:
    "Nous nous conformons au Règlement général sur la protection des données (RGPD) en ce qui concerne le traitement de vos données personnelles. Nous pouvons traiter les données personnelles dans les conditions suivantes :\n" +
    "\t•\tVous avez donné votre consentement au traitement des données à caractère personnel pour une ou plusieurs finalités spécifiques.\n" +
    "\t•\tle respect d'une obligation légale à laquelle l'entreprise est soumise.\n" +
    "\t•\tIntérêts vitaux : Le traitement des données à caractère personnel est nécessaire pour protéger vos intérêts vitaux ou ceux d'une autre personne physique.\n" +
    "\t•\tIntérêts publics : Le traitement des données à caractère personnel est lié à une tâche effectuée dans l'intérêt public ou dans l'exercice de l'autorité officielle dont est investie la société.\n" +
    "\t•\tIntérêts légitimes : Le traitement des données à caractère personnel est nécessaire aux fins des intérêts légitimes poursuivis par la société.\n" +
    "Dans tous les cas, la société aidera volontiers à clarifier la base juridique exacte qui s'applique au traitement, y compris si la fourniture de données à caractère personnel est une nécessité légale ou contractuelle, ou une condition préalable à la conclusion d'un contrat.\n" +
    "La Société s'engage à respecter la confidentialité de vos données personnelles et à vous garantir l'exercice de vos droits. Vous avez le droit, en vertu de la présente politique de protection de la vie privée et de la loi, de :\n" +
    "\t•\tDemander l'accès à vos données personnelles ;\n" +
    "\t•\tDemander la correction des données personnelles que nous détenons à votre sujet ;\n" +
    "\t•\tS'opposer au traitement de vos données personnelles ;\n" +
    "\t•\tDemander l'effacement de vos données personnelles. Vous avez le droit de nous demander d'effacer ou de supprimer des données à caractère personnel lorsque nous n'avons aucune raison valable de continuer à les traiter ;\n" +
    "\t•\tDemander le transfert de vos données personnelles ;\n" +
    "\t•\tRetirer votre consentement au traitement des informations personnelles.",
  privacy8:
    "Notre plate-forme peut contenir des liens vers d'autres sites web qui ne sont pas exploités par la société. Si vous cliquez sur le lien d'un tiers, vous serez dirigé vers le site web de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialité de chaque site web que vous visitez.\n" +
    "Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques des sites web ou services de tiers.\n" +
    "La présente politique de confidentialité peut être mise à jour ou modifiée à tout moment sans préavis, c'est pourquoi nous vous conseillons de consulter régulièrement cette page. Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter à l'adresse contact{'@'}aieraworld.com.",
};
