import { RouteRecordRaw } from "vue-router";

import { ROUTES } from "@/constants/routes";
import { i18n } from "@/i18n";
import AdminLogin from "@/views/AdminLogin.vue";
import AdminPanel from "@/views/AdminPanel.vue";
import ArticlesDetails from "@/views/ArticlesDetails.vue";
import ArticlesView from "@/views/ArticlesView.vue";
import AudioGenerator from "@/views/AudioGenerator.vue";
import AudioService from "@/views/AudioService.vue";
import BuyNeurons from "@/views/BuyNeurons.vue";
import ContactUs from "@/views/ContactUs.vue";
import CreativeService from "@/views/CreativeService.vue";
import HomePage from "@/views/HomePage.vue";
import ImageService from "@/views/ImageService.vue";
import ImagesGenerator from "@/views/ImagesGenerator.vue";
import ParapharsingService from "@/views/ParapharsingService.vue";
import PoemsService from "@/views/PoemsService.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import ProfilePage from "@/views/ProfilePage.vue";
import TermsConditions from "@/views/TermsConditions.vue";
import TextService from "@/views/TextService.vue";
import UserTransactions from "@/views/UserTransactions.vue";

export const HEADER_LINKS = [
  {
    name: "home",
    link: ROUTES.ROOT.PATH,
    label: "Home",
  },
  {
    name: "text",
    link: ROUTES.TEXT_SERVICE.PATH,
    label: i18n.global.t("title.textTitle"),
  },
  {
    name: "audio",
    link: ROUTES.AUDIO_SERVICE.PATH,
    label: i18n.global.t("title.audioTitle"),
  },
  {
    name: "image",
    link: ROUTES.IMAGE_SERVICE.PATH,
    label: i18n.global.t("title.imageTitle"),
  },
  {
    name: "articles",
    link: ROUTES.ARTICLES.PATH,
    label: "Blog",
  },
];

export const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTES.ROOT.PATH,
    name: ROUTES.ROOT.NAME,
    props: (route) => ({ session_id: route.query.session_id }),
    component: HomePage,
  },

  {
    path: ROUTES.TEXT_SERVICE.PATH,
    name: ROUTES.TEXT_SERVICE.NAME,
    component: TextService,
  },

  {
    path: ROUTES.AUDIO_SERVICE.PATH,
    name: ROUTES.AUDIO_SERVICE.NAME,
    component: AudioService,
  },

  {
    path: ROUTES.IMAGE_SERVICE.PATH,
    name: ROUTES.IMAGE_SERVICE.NAME,
    component: ImageService,
  },

  {
    path: ROUTES.POEMS_SERVICE.PATH,
    name: ROUTES.POEMS_SERVICE.NAME,
    component: PoemsService,
  },

  {
    path: ROUTES.PARAPHRASING_SERVICE.PATH,
    name: ROUTES.PARAPHRASING_SERVICE.NAME,
    component: ParapharsingService,
  },

  {
    path: ROUTES.CREATIVE_SERVICE.PATH,
    name: ROUTES.CREATIVE_SERVICE.NAME,
    component: CreativeService,
  },

  {
    path: ROUTES.IMAGE_GENERATOR.PATH,
    name: ROUTES.IMAGE_GENERATOR.NAME,
    component: ImagesGenerator,
  },

  {
    path: ROUTES.AUDIO_GENERATOR.PATH,
    name: ROUTES.AUDIO_GENERATOR.NAME,
    component: AudioGenerator,
  },

  {
    path: ROUTES.PROFILE.PATH,
    name: ROUTES.PROFILE.NAME,
    component: ProfilePage,
  },

  {
    path: ROUTES.BUY_NEURONS.PATH,
    name: ROUTES.BUY_NEURONS.NAME,
    component: BuyNeurons,
  },

  {
    path: ROUTES.USER_TRANSACTIONS.PATH,
    name: ROUTES.USER_TRANSACTIONS.NAME,
    component: UserTransactions,
  },

  {
    path: ROUTES.PROFILE.PATH,
    name: ROUTES.PROFILE.NAME,
    component: ProfilePage,
  },

  {
    path: ROUTES.LOGOUT.PATH,
    name: ROUTES.LOGOUT.NAME,
    component: HomePage,
  },

  {
    path: ROUTES.ADMIN_LOGIN.PATH,
    name: ROUTES.ADMIN_LOGIN.NAME,
    component: AdminLogin,
  },

  {
    path: ROUTES.ADMIN_PANEL.PATH,
    name: ROUTES.ADMIN_PANEL.NAME,
    component: AdminPanel,
  },

  {
    path: ROUTES.REGISTRATION.PATH,
    name: ROUTES.REGISTRATION.NAME,
    component: HomePage,
    props: (route) => ({ secret_code: route.query.secret_code }),
  },

  {
    path: ROUTES.CONTACT_US.PATH,
    name: ROUTES.CONTACT_US.NAME,
    component: ContactUs,
  },

  {
    name: ROUTES.PRIVACY_POLICY.NAME,
    path: ROUTES.PRIVACY_POLICY.PATH,
    component: PrivacyPolicy,
  },
  {
    name: ROUTES.TERMS_OF_USE.NAME,
    path: ROUTES.TERMS_OF_USE.PATH,
    component: TermsConditions,
  },
  {
    name: "Contact Us",
    path: ROUTES.CONTACT_US.PATH,
    component: ContactUs,
  },
  {
    name: ROUTES.ARTICLES.NAME,
    path: ROUTES.ARTICLES.PATH,
    component: ArticlesView,
  },
  {
    path: ROUTES.ARTICLES_DETAILS.PATH,
    name: ROUTES.ARTICLES_DETAILS.NAME,
    component: ArticlesDetails,
    props: (route) => ({ articleId: route.query.articleId }),
  },
];
