import { Lang } from "@/types/lang";

export default <Lang>{
  termsHeading1: "Bedingungen und Konditionen",
  termsHeading2: "Zulässigkeit der Nutzung",
  termsHeading3: "Gewährleistung und Haftung",
  termsHeading4: "Der Inhalt ",
  termsHeading5: "Eingeschränkter Inhalt",
  termsHeading6: "Zahlungsverfahren",
  termsHeading7: "Erstattungspolitik",
  termsHeading8: "Dienste von Drittanbietern",
  termsHeading9: "Urheberrechte",
  termsHeading10: "Beendigung",
  termsHeadingKyc: "KYC-Überprüfung",

  terms1:
    'Bevor Sie die von AI Era (das "Unternehmen", "wir", "uns", "die Plattform", die "Dienstleistung(en)" oder "die Website") angebotenen Dienstleistungen nutzen, sollten Sie die folgenden Allgemeinen Geschäftsbedingungen ("Vereinbarung") lesen. Indem Sie auf die Website oder die Dienste in irgendeiner Weise zugreifen, bestätigen Sie, dass Sie die Bedingungen dieser Vereinbarung gelesen, verstanden und akzeptiert haben, und erklären sich damit einverstanden, an alle Bestimmungen dieser Vereinbarung gebunden zu sein. Wenn Sie nicht mit allen Bestimmungen und Bedingungen dieser Vereinbarung einverstanden sind, müssen Sie die Nutzung der Plattform oder der Dienste unverzüglich einstellen. Die Nutzung der Dienste des Unternehmens hängt von Ihrer Zustimmung zu diesen Bedingungen ab. Wenn Sie mit diesen Bedingungen nicht einverstanden sind, nutzen Sie die Website bitte NICHT.\n' +
    "AI Era World ist im Besitz und wird betrieben von (Unternehmen).\n" +
    "(Unternehmen) unterliegt den Gesetzen von (Staat) als Gesellschaft mit beschränkter Haftung und hat seinen eingetragenen Sitz in (Adresse)\n" +
    "Telefon:  E-Mail: contact{'@'}aieraworld.com",
  terms2:
    "Sie sichern uns zu, dass: \n" +
    "- Sie in dem Land, in dem Sie wohnen, volljährig sind; \n" +
    "- Sie in der Lage sind, einen verbindlichen Vertrag abzuschließen und Ihnen die Nutzung der Dienste nicht gesetzlich untersagt ist; \n" +
    "- dass die Informationen, die Sie uns zur Verfügung stellen, korrekt und wahrheitsgemäß sind; \n" +
    "- Sie sind rechtlich befugt, jede Zahlungsmethode, einschließlich Kredit- oder Debitkarten, für die Ausführung einer Bestellung zu verwenden. (Durch die Bereitstellung solcher Informationen erkennen Sie an und ermächtigen uns, die Informationen an Dritte weiterzugeben, die die Zahlung verarbeiten, um die Ausführung Ihrer Bestellung zu erleichtern. Bei Verdacht auf Betrug, eine nicht autorisierte oder illegale Transaktion behalten wir uns das Recht vor, Ihre Bestellung jederzeit aus bestimmten Gründen abzulehnen oder zu stornieren, z. B. bei Nichtverfügbarkeit des Dienstes, technischen Fehlern oder falschen Preisen).\n" +
    "In Gerichtsbarkeiten, in denen dies gesetzlich verboten ist, wird diese Vereinbarung ungültig, und das Recht auf Zugang zu den Diensten wird widerrufen.",
  terms3:
    "Die Plattform wird ständig hinsichtlich der Verfügbarkeit, Fehler und Ungenauigkeiten unserer Dienste aktualisiert. Unsere Plattform kann Dienste enthalten, deren Preise ungenau, veraltet oder nicht jederzeit verfügbar sind, und es kann zu Verzögerungen bei der Aktualisierung von Informationen über unsere Dienste auf der Plattform und bei der Werbung auf anderen Websites kommen.\n" +
    "Unsere Zusicherung umfasst nicht alle möglichen Informationen, einschließlich der Preise und Produktbilder. Spezifikationen, Verfügbarkeit oder Dienstleistungen können abweichen, und wir sind befugt, Informationen ohne vorherige Ankündigung zu ändern oder zu überarbeiten, einschließlich aller Fehler, Ungenauigkeiten oder Auslassungen. Das Unternehmen behält sich das Recht vor, seine Preise jederzeit zu ändern, bevor eine Bestellung eingegangen ist.\n" +
    "Unabhängig davon, ob Sie eine Kredit- oder Debitkarte verwenden, kann die Zahlung mit der Karte erfolgen, die Ihnen ausgestellt wurde, und der Kartenaussteller wird sie validieren. Wenn wir nicht in der Lage sind, die erforderliche Genehmigung zu erhalten, werden wir nicht für eine Verzögerung oder Nichtlieferung Ihrer Bestellung verantwortlich gemacht werden.\n" +
    "Jegliche Werbeaktionen, die über den Dienst zur Verfügung gestellt werden, können Regeln unterliegen, die sich von diesen Bedingungen unterscheiden. Um sicherzustellen, dass Sie die geltenden Vorschriften und unsere Datenschutzrichtlinien einhalten, empfehlen wir Ihnen, diese Regeln zu lesen und alle Werbeaktionen in Betracht zu ziehen. Die Bedingungen einer Werbeaktion unterliegen denselben Regeln wie diese Bedingungen, wenn eine dieser Regeln nicht befolgt wird.\n" +
    "Wenn Sie ein Konto bei uns erstellen, müssen Sie genaue, vollständige und aktuelle Informationen angeben, um Ihr Konto zu erstellen. Wenn Sie dies nicht tun, kann dies zur sofortigen Kündigung Ihres Kontos auf der Plattform führen, da dies als Verstoß gegen die Bedingungen angesehen wird.\n" +
    "Es liegt in Ihrer Verantwortung, das Passwort, das Sie für den Zugriff auf den Dienst und alle Aktivitäten im Zusammenhang mit Ihrem Passwort verwenden, aufrechtzuerhalten, unabhängig davon, ob es sich um unseren Dienst oder einen Social-Media-Dienst eines Drittanbieters handelt. Sie sind verpflichtet, die Vertraulichkeit zu wahren, wenn Sie Ihr Passwort mit anderen teilen. Wenn Sie feststellen, dass Ihr Konto missbraucht oder für illegale Zwecke verwendet wurde, müssen Sie uns umgehend darüber informieren.\n" +
    "Unser Dienst ist auf ein Benutzerkonto pro Benutzer beschränkt. Wenn Sie gegen unsere Bedingungen verstoßen oder mehrere Konten erstellen, können wir Ihre Daten entfernen und den Zugang beschränken. Wir gewähren Ihnen die Befugnis, Audio- und Videoinhalte über unseren Dienst zu produzieren oder zu übertragen. Es liegt in Ihrer Verantwortung, sicherzustellen, dass die mit Unserem Dienst erstellten Inhalte den gesetzlichen Anforderungen entsprechen, einschließlich ihrer Korrektheit und Angemessenheit. Der Dienst gewährt Ihnen das Recht und die Lizenz, die von Ihnen erstellten Inhalte zu nutzen, zu modifizieren, vorzuführen, auszustellen, zu kopieren (in ihrer Gesamtheit), zu vervielfältigen und zu verbreiten, indem Sie sie erstellen und veröffentlichen. Der Dienst gewährt Ihnen vollständige Rechte an allen Inhalten, was bedeutet, dass Sie für den Schutz und die Wahrung dieser Rechte verantwortlich sind.\n" +
    "Die Nutzung des Dienstes erfolgt auf eigene Gefahr. Sie erkennen an, dass der Dienst Inhalte enthalten kann, die Sie als beleidigend, unanständig, unrichtig oder anstößig empfinden könnten, und Sie erklären sich damit einverstanden, dass keine Haftung für Fehler oder Schäden besteht, die sich aus Ihrer Nutzung unserer Plattform ergeben. Sie tragen die volle Verantwortung für alle Ihre Handlungen im Zusammenhang mit den Diensten. Wenn Sie sich bei der Nutzung der Dienste auf betrügerisches, missbräuchliches oder anderweitig illegales Verhalten einlassen, können Ihre Zugangs- oder Nutzungsrechte gekündigt werden. Es ist Ihnen untersagt, Nachrichten oder Aufforderungen zu veröffentlichen oder zu übermitteln, die darauf abzielen, Passwörter, Konten oder private Informationen von anderen Benutzern der Plattform zu erhalten. Es ist ausdrücklich verboten, die Dienste zu nutzen, um die Sicherheit eines Computernetzwerks zu verletzen, Passwörter oder Sicherheitsverschlüsselungscodes zu knacken oder illegales Material (einschließlich potenziell schädlicher oder illegaler Inhalte) zu übertragen oder zu speichern oder illegale Aktivitäten zu unternehmen.\n" +
    "Der Quellcode der Plattform wird nicht dekompiliert, zurückentwickelt oder auf andere Weise beschafft. Sie sind für die Einbehaltung, Einreichung und Meldung aller Steuern, Abgaben und anderer staatlicher Veranlagungen im Zusammenhang mit Ihrer Tätigkeit in Verbindung mit den Diensten verantwortlich. Das Unternehmen führt regelmäßig Inhaltssicherungen durch, kann jedoch nicht garantieren, dass keine Daten verloren gehen oder beschädigt werden. Sicherungspunkte können ungültig oder beschädigt sein, wenn der Inhalt vor der Sicherung beschädigt oder während des Sicherungsvorgangs verändert wurde, ohne Einschränkung. Das Unternehmen unternimmt alle Anstrengungen, um auftretende Probleme zu beheben, unabhängig davon, ob sie bekannt sind oder entdeckt werden, die sich auf die Sicherungen der Inhalte auswirken. Das Unternehmen ist jedoch nicht verantwortlich für die Integrität der Inhalte oder dafür, dass diese nicht erfolgreich in einen brauchbaren Zustand wiederhergestellt werden können.",
  terms4:
    "Unser Dienst ermöglicht es Ihnen, Audio-, Text- und Bildinhalte zu erstellen. Sie sind für die Inhalte verantwortlich, die Sie mit unseren Diensten erstellen, einschließlich ihrer Rechtmäßigkeit und Angemessenheit. Durch die Erstellung von Inhalten auf der Plattform gewähren Sie uns das Recht und die Lizenz, diese Inhalte auf und über den Dienst zu verwenden, zu ändern, öffentlich aufzuführen, öffentlich darzustellen, zu reproduzieren und zu verbreiten. Sie behalten alle Ihre Rechte an Inhalten, die Sie erstellen, veröffentlichen oder auf oder über den Dienst anzeigen, und Sie sind für den Schutz dieser Rechte verantwortlich.\n" +
    "Mit der Nutzung unserer Dienste erklären Sie sich damit einverstanden, dass das Unternehmen nicht garantieren kann, dass die generierten Inhalte zu 100 % korrekt, von bester Qualität oder vollständig wahrheitsgetreu sind, und dass daher keine Rückerstattungen oder Rückfragen bezüglich der endgültigen Qualität der Inhalte möglich sind.",
  terms5:
    "Sie sind sich bewusst und erklären sich damit einverstanden, dass Sie die volle Verantwortung für die Inhalte sowie für alle mit Ihrem Konto verbundenen Aktivitäten tragen, unabhängig davon, ob diese von Ihnen oder einem anderen Benutzer mit Zugang zu Ihrem Konto durchgeführt werden. \n" +
    "Es ist Ihnen untersagt, Inhalte zu erstellen oder zu übermitteln, die illegal, beleidigend, schändlich, abstoßend, bedrohlich, verleumderisch, unanständig, ungesetzlich oder anderweitig anstößig sind. Die folgende Liste von Inhalten kann als eingeschränkt angesehen werden, ist aber nicht beschränkt auf: \n" +
    "- Beteiligung an einer ungesetzlichen Aktivität oder Ermutigung zu einem solchen illegalen Verhalten; \n" +
    "- Die Nutzung der Plattform zur Erstellung von Materialien, die diffamierend, diskriminierend und beleidigend sind, einschließlich jeglicher Erwähnung oder Bemerkung in Bezug auf Religion, Rasse, sexuelle Orientierung/Geschlechtsidentität, nationale/ethnische Herkunft oder andere Gruppen; \n" +
    "- Nutzung des Dienstes zur Förderung verbotener Aktivitäten, wie z. B. des Konsums illegaler Substanzen/Drogen und Dienstleistungen für Erwachsene; \n" +
    "- Generierung von Spam, maschinell generierten oder zufällig zugewiesenen Aufgaben, unerlaubter oder unaufgeforderter Werbung und Kettenbriefen;\n" +
    "- Jede andere Form von ungesicherter Aufforderung oder jede Art von Lotterie oder Glücksspiel; \n" +
    "- Nutzung des Dienstes zur Erstellung oder Entwicklung von Viren, Malware, trojanischen Pferden oder ähnlichem Material, das dazu bestimmt ist, den Betrieb von Software zu stören, zu beschädigen oder zu beeinträchtigen; \n" +
    "- Verletzung der Rechte am geistigen Eigentum einer Partei, einschließlich, aber nicht beschränkt auf: Patente, Marken oder Geschäftsgeheimnisse sowie Urheberrechte und Veröffentlichungsrechte; \n" +
    "- Nachahmung von Personen, einschließlich Mitarbeitern und Vertretern des Unternehmens;\n" +
    "- Gefährdung der Privatsphäre anderer. \n" +
    "Sofern nicht anders vereinbart, ist das Unternehmen befugt, sexuell eindeutige Inhalte abzulehnen oder zu löschen und darüber zu entscheiden, ob sie für die Nutzung oder die Einhaltung dieser Bedingungen geeignet sind. Dem Unternehmen steht es frei, die Art und Weise, wie der Inhalt präsentiert wird, zu modifizieren und Änderungen vorzunehmen. Das Unternehmen ist befugt, den Dienst einzuschränken oder zu sperren, wenn Sie ihn zur Erstellung von Inhalten nutzen, die als anstößig angesehen werden. Das Unternehmen ist nicht verantwortlich für die Inhalte, die von Nutzern und/oder Dritten auf anderen Plattformen veröffentlicht werden.  ",
  termsKyc:
    "Um die Dienste nutzen zu können, sollte ein Nutzer seine E-Mail-Adresse bestätigen. Die Nutzung der Dienste ist jedoch für Einwohner der folgenden Länder eingeschränkt: Nordkorea (Demokratische Volksrepublik Korea), Iran und Myanmar.\n" +
    'Nutzer mit verdächtigen Aktivitäten oder mit Wohnsitz in Ländern mit hohem Risiko, wie Afghanistan, Albanien, Barbados, Burkina Faso, Kambodscha, Cayman-Inseln, Demokratische Republik Kongo, Gibraltar, Haiti, Jamaika, Jordanien, Mali, Marokko, Mosambik, Nicaragua, Pakistan, Panama, Philippinen, Russland, Senegal, Südsudan, Syrien, Tansania, Trinidad und Tobago, Türkei, Uganda, Vereinigte Arabische Emirate, Vanuatu, Jemen und Simbabwe, können einer zusätzlichen Überprüfung unterzogen werden (a.auch bekannt als "Know Your Customer" oder KYC), indem zusätzliche Daten und/oder Dokumente vorgelegt werden. Solche Informationen können auch bei einer offiziellen Anfrage von Behörden oder anderen Strafverfolgungsbehörden verlangt werden.',
  terms6:
    "Um auf die Dienste zugreifen zu können, müssen Sie über einen ausreichenden Betrag der virtuellen Währung der Plattform - Neuronen - in Ihrer Kontobrieftasche verfügen. Sie können Ihr Guthaben im entsprechenden Bereich der Website überprüfen und aufladen. Neuronen haben außerhalb der Plattform keinen Wert, können nur auf der Plattform verwendet werden und können nicht auf eine andere Plattform oder Person übertragen werden. 10 Neuronen entsprechen 1 Euro.\n" +
    'Transaktionen werden innerhalb von 72 Stunden nach der Zahlung bearbeitet. Nach Abschluss der Zahlung kann es bis zu 24 Stunden dauern, bis Ihr Guthaben aktualisiert wird, obwohl dieser Vorgang normalerweise sofort erfolgt. Wir sind berechtigt, einen dritten Zahlungsdienstleister (der "Zahlungsabwickler") für Ihre Einzahlungen einzusetzen. Der jeweilige Zahlungsabwickler ist in erster Linie für die Erleichterung der Zahlung Ihrer Transaktionen verantwortlich. Um Ihre Transaktion zu bearbeiten, benötigt der Zahlungsabwickler Ihre persönlichen Daten und Zahlungsinformationen, wie z. B. Debit-/Kreditkartendaten, Adresse und andere Informationen. Diese Informationen werden nur zur Abwicklung der Transaktionen verwendet und nicht an Dritte weitergegeben, mit Ausnahme der Finanz- und Regulierungsbehörden, die an der Abwicklung oder den Compliance-Verfahren beteiligt sind. Weitere Informationen über die Verwendung Ihrer persönlichen Daten finden Sie in unserer Datenschutzrichtlinie.\n' +
    "Wir behalten uns das Recht vor, alle von Ihnen bei der Nutzung des Dienstes getätigten Transaktionen zu überprüfen und zu genehmigen, um sicherzustellen, dass sie mit diesen Allgemeinen Geschäftsbedingungen und den von Drittanbietern für die Plattform auferlegten Bedingungen übereinstimmen. Sie erkennen ausdrücklich an und erklären sich damit einverstanden, dass solche Überprüfungen von Ihnen verlangen können, dass Sie uns zusätzliche persönliche Informationen zur Verfügung stellen, um Ihre Identität zu überprüfen und zu bestätigen und um Überprüfungen durchzuführen, die darauf abzielen, Betrug und Missbrauch des Dienstes in Übereinstimmung mit unserer Datenschutzrichtlinie und allen geltenden Gesetzen und Vorschriften zu verhindern.\n" +
    "Indem Sie eine Transaktion durchführen, bestätigen Sie, dass alle Bankkarten und anderen Zahlungskonten, die Sie für Transaktionen verwenden, rechtmäßig Ihre sind.",
  terms7:
    "Der auf unserer Website erworbene Inhalt ist nicht rückerstattungsfähig. Sie haben keinen Anspruch auf Rückerstattung von Gebühren oder Transaktionsprovisionen, die Sie bereits bezahlt haben. Rückerstattungen sind nur für Transaktionen möglich, die für den Kauf von Neuronen durchgeführt wurden, und nur im Falle von technischen Problemen mit dem auf der Website angebotenen Service und/oder anderen Problemen, die auf unsere Fehler zurückzuführen sind, die dazu geführt haben, dass Sie den bezahlten Service nicht in Anspruch nehmen konnten. Jeder Antrag auf Rückerstattung muss persönlich per E-Mail an contact{'@'}aiaieraworld.com innerhalb von 14 Tagen ab dem Zeitpunkt der Transaktion eingereicht werden.",
  terms8:
    "Links auf unserer Plattform können zu Websites oder Dienstleistungen Dritter führen, die nicht dem Unternehmen gehören. Websites oder Dienste von Dritten können mit dem Unternehmen verlinkt sein, aber das Unternehmen ist nicht für deren Inhalte, Datenschutzrichtlinien oder Praktiken verantwortlich. Sie erkennen an und erklären sich damit einverstanden, dass das Unternehmen nicht für Schäden oder Verluste haftbar gemacht werden kann, die durch die Nutzung von oder im Zusammenhang mit der Nutzung von oder dem Vertrauen auf solche Inhalte, Waren oder Dienstleistungen, die über Websites oder andere Dienste angeboten werden, entstehen. Wir empfehlen Ihnen dringend, die Nutzungsbedingungen und die Datenschutzrichtlinien der Websites oder Dienste Dritter, auf die Sie zugreifen, zu lesen.",
  terms9:
    "Der Grundsatz des Urheberrechts verlangt von uns, die Rechte am geistigen Eigentum anderer anzuerkennen und zu respektieren. Es gehört zu unseren Grundsätzen, jeder Behauptung nachzugehen, dass im Dienst veröffentlichte Inhalte die geistigen Eigentumsrechte oder das Urheberrecht anderer verletzen. Wenn Sie der Meinung sind, dass der Dienst eine Urheberrechtsverletzung begünstigt, und Sie entweder ein Urheberrechtsinhaber oder in dessen Namen bevollmächtigt sind, senden Sie uns bitte eine E-Mail an contact@aiaieraworld.com, um Ihre Mitteilung einzureichen. Bitte fügen Sie Ihrer Meldung eine ausführliche Erläuterung des vermeintlichen Verstoßes bei. Das Eingeständnis, dass Inhalte Ihr Urheberrecht verletzen, kann dazu führen, dass Sie für Schäden, einschließlich Kosten und Anwaltsgebühren, zur Verantwortung gezogen werden.",
  terms10:
    "Wir sind berechtigt, Ihr Konto ohne Vorankündigung oder Haftung zu sperren oder zu kündigen, auch wenn Sie nicht gegen die Bedingungen verstoßen. Dies gilt ungeachtet des Grundes. Ihre Nutzung des Dienstes wird mit der Löschung Ihres Kontos beendet. Wenn Sie Ihr Konto von der Plattform entfernen möchten, können Sie die Nutzung des Dienstes einfach einstellen. Diese Vereinbarung bleibt in vollem Umfang in Kraft, solange Sie die Dienste nutzen. Das Unternehmen kann Ihren Zugang zu den Diensten jederzeit kündigen oder aussetzen. Im Falle eines Verstoßes gegen eine der Bedingungen dieser Vereinbarung kann das Unternehmen alle Dienste und den Zugang zur Website ohne jegliche Haftung oder Warnung sofort aussetzen oder beenden.\n" +
    "Alle Bestimmungen dieser Vereinbarung, die nach der Beendigung in Kraft bleiben, sind wesentlich, einschließlich der Eigentumsbestimmungen und Haftungsausschlüsse. Diese Einschränkungen gelten auch für Haftungsbeschränkungen. Das Unternehmen, seine Muttergesellschaften, Tochtergesellschaften, verbundenen Unternehmen und Mitarbeiter sind in vollem Umfang verpflichtet, sich gegen jegliche Ansprüche, Haftungen, Abfindungen/Kosten, einschließlich Anwaltsgebühren oder Schäden zu verteidigen, die aus Ihrer Nutzung der Dienste, der Verletzung dieser Vereinbarung oder der Verletzung Ihres geistigen Eigentums oder anderer Rechte durch Sie oder eine andere Partei entstehen.\n" +
    "Sollte eine Bestimmung dieser Bedingungen als nicht durchsetzbar oder ungültig erachtet werden, werden die anderen Bestimmungen so geändert und ausgelegt, dass der beabsichtigte Zweck gemäß dem geltenden Recht erreicht wird, ohne dass sie ersetzt werden. Sofern nicht anders vereinbart, beeinträchtigt die Nichtanwendung eines Rechts oder einer Verpflichtung gemäß diesen Bedingungen nicht die Fähigkeit einer der Parteien, die Einhaltung dieser Vereinbarung durchzusetzen. Die ursprüngliche englische Version der Bedingungen ist in allen Streitfällen das geltende Recht.\n" +
    "Diese Bedingungen können sich ändern und können von uns nach unserem alleinigen Ermessen geändert oder ersetzt werden. Sie erkennen die überarbeiteten Bedingungen an und erklären sich damit einverstanden, an diese gebunden zu sein, wenn Sie nach Inkrafttreten dieser Änderungen weiterhin auf unseren Service zugreifen oder ihn nutzen. Wenn Sie mit den neuen Bedingungen nicht einverstanden sind, stellen Sie bitte die Nutzung der Website und des Dienstes ein.\n" +
    "Wenn Sie Fragen zu diesen Geschäftsbedingungen haben, wenden Sie sich bitte an contact{'@'}aiaieraworld.com.",
};
