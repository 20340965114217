import { Lang } from "@/types/lang";

export default <Lang>{
  termsHeading1: "Termini e condizioni d'uso",
  termsHeading2: "Ammissibilità dell'uso",
  termsHeading3: "Garanzia e responsabilità",
  termsHeading4: "Il Contenuto",
  termsHeading5: "Contenuto limitato",
  termsHeading6: "Procedura di pagamento",
  termsHeading7: "Politica di rimborso",
  termsHeading8: "Servizi di terze parti",
  termsHeading9: "Diritti d'autore",
  termsHeading10: "Cessazione",
  termsHeadingKyc: "Verifica KYC",

  terms1:
    'Prima di utilizzare i servizi forniti da AI Era (la "Società", "Noi", "la Piattaforma", i "Servizi" o il "Sito web"), si raccomanda di leggere i seguenti Termini e condizioni ("Accordo"). Accedendo al sito web o ai servizi in qualsiasi modo, l\'utente riconosce di aver letto, compreso e accettato i termini e le condizioni del presente Accordo e accetta di essere vincolato da tutte le sue disposizioni. Se non accettate tutti i termini e le condizioni del presente Contratto, dovete interrompere immediatamente l\'utilizzo della Piattaforma o dei Servizi. L\'utilizzo dei Servizi della Società è subordinato all\'accettazione dei presenti Termini. Se non si accettano i presenti Termini, si prega di NON utilizzare il Sito web.\n' +
    "AI Era World è di proprietà e gestito da (Società).\n" +
    "(Azienda) è regolata dalle leggi di (Giurisdizione) come società a responsabilità limitata, con sede legale a (Indirizzo)\n" +
    "Numero di telefono:  E-mail: contact{'@'}aieraworld.com",
  terms2:
    "L'utente garantisce a Noi che: \n" +
    "- Siete maggiorenni nel vostro paese di residenza; \n" +
    "- di essere in grado di stipulare un contratto vincolante e che la legge non vieta l'utilizzo o l'accesso ai Servizi; \n" +
    "- che le informazioni fornite sono accurate e veritiere; \n" +
    "- Siete legalmente autorizzati a utilizzare qualsiasi metodo di pagamento, compresa la carta di credito o di debito, per l'evasione di qualsiasi ordine (fornendo tali informazioni, riconoscete e ci autorizzate a condividerle con terze parti incaricate dell'elaborazione dei pagamenti per facilitare il completamento del vostro ordine. Se sospettiamo una frode, una transazione non autorizzata o illegale, ci riserviamo il diritto di rifiutare o annullare l'ordine in qualsiasi momento per determinati motivi, tra cui l'indisponibilità del servizio, errori tecnici o prezzi errati).\n" +
    "Nelle giurisdizioni in cui la legge lo vieta, il presente Contratto non è valido e il diritto di accedere ai Servizi è revocato.",
  terms3:
    "La Piattaforma viene continuamente aggiornata in merito alla disponibilità, agli errori e alle imprecisioni dei Nostri Servizi. La nostra Piattaforma potrebbe avere Servizi con prezzi imprecisi, non aggiornati o non sempre disponibili, e potremmo subire ritardi nell'aggiornamento delle informazioni sul nostro Servizio sulla Piattaforma e nella pubblicità su altri siti web.\n" +
    "La nostra garanzia non copre tutte le informazioni possibili, compresi i prezzi e le immagini dei prodotti. Le specifiche, la disponibilità o i servizi possono differire e la Società ha la facoltà di modificare o rivedere le informazioni senza preavviso, compresi eventuali errori, imprecisioni o omissioni. La Società si riserva il diritto di modificare i prezzi in qualsiasi momento prima di ricevere un ordine.\n" +
    "Sia che si utilizzi una carta di credito o di debito, il pagamento può essere effettuato con la carta che vi è stata rilasciata e l'emittente della carta la convaliderà. Se non siamo in grado di ottenere l'autorizzazione necessaria, non saremo responsabili di eventuali ritardi o della mancata consegna dell'ordine.\n" +
    "Qualsiasi promozione resa disponibile attraverso il Servizio può essere soggetta a regole diverse dai presenti Termini. Per garantire la conformità alle normative vigenti e alla nostra Informativa sulla privacy, si consiglia di leggere tali regole prima di prendere in considerazione qualsiasi promozione. I termini di una promozione saranno soggetti alle stesse regole dei presenti Termini se una qualsiasi di queste regole non viene rispettata.\n" +
    "Se create un account con Noi, dovete fornire informazioni accurate, complete e aggiornate per creare il vostro account. La mancata osservanza di tale obbligo può comportare l'immediata cessazione dell'account dell'utente sulla Piattaforma, in quanto considerata una violazione dei Termini.\n" +
    "È responsabilità dell'utente mantenere la password che utilizza per accedere al Servizio e a qualsiasi attività correlata alla sua password, sia con il Nostro Servizio che con un Servizio di social media di terze parti. L'utente è tenuto a mantenere la riservatezza quando condivide la propria password con altri. Nel momento in cui si scopre che il proprio account è stato violato o utilizzato per scopi illegali, l'utente è tenuto ad informarci tempestivamente.\n" +
    "Il nostro servizio è limitato a un account per utente; se l'utente viola i nostri Termini o crea più account, possiamo rimuovere i suoi dati e limitare l'accesso. Vi concediamo la facoltà di produrre o trasmettere contenuti audio e video attraverso il Nostro Servizio. È responsabilità dell'utente assicurarsi che il Contenuto creato utilizzando i Nostri Servizi soddisfi i requisiti legali, tra cui la correttezza e l'adeguatezza. Il Servizio vi concede il diritto e la licenza di utilizzare, modificare, eseguire, esporre, copiare (nella sua interezza), riprodurre e distribuire i Contenuti creati attraverso la vostra creazione e pubblicazione. Il Servizio concede all'utente tutti i diritti su tutti i Contenuti, il che significa che l'utente è responsabile della protezione e del mantenimento di tali diritti.\n" +
    "L'utente utilizza il Servizio a proprio rischio e pericolo. L'utente riconosce che il Servizio può contenere contenuti che l'utente può ritenere offensivi, indecenti, scorretti o discutibili e accetta di non essere responsabile di eventuali errori o danni derivanti dall'uso della nostra Piattaforma. L'utente si assume la completa responsabilità di tutte le sue azioni relative ai Servizi. Se l'utente si impegna in comportamenti fraudolenti, abusivi o altrimenti illegali durante l'utilizzo dei Servizi, i suoi diritti di accesso o di utilizzo potrebbero essere interrotti. È vietato pubblicare o trasmettere qualsiasi messaggio o sollecitazione volta a ottenere password, account o informazioni private da altri utenti della Piattaforma. È espressamente vietato utilizzare i Servizi per violare la sicurezza di qualsiasi rete di computer, craccare password o codici di crittografia di sicurezza, o trasferire o memorizzare materiale illegale (compresi contenuti potenzialmente dannosi o illegali) o impegnarsi in qualsiasi attività illegale.\n" +
    "Il codice sorgente della Piattaforma non potrà essere decompilato, decodificato o ottenuto con qualsiasi altro mezzo. L'utente è responsabile della trattenuta, del deposito e della segnalazione di tutte le imposte, i dazi e altre valutazioni governative legate alla sua attività in relazione ai Servizi. La Società esegue regolarmente il backup dei contenuti, ma non garantisce l'assenza di perdita o corruzione dei dati. I punti di backup possono essere non validi o corrotti se il Contenuto era corrotto prima di essere sottoposto a backup o modificato durante il processo di backup, senza limitazioni. La Società farà il possibile per risolvere eventuali problemi, noti o scoperti, che riguardano i backup del Contenuto. Tuttavia, la Società non è responsabile dell'integrità del Contenuto o del suo mancato ripristino in uno stato utilizzabile.",
  terms4:
    "Il nostro Servizio consente di generare Contenuto audio, testo e immagine. L'utente è responsabile dei Contenuti generati utilizzando i Nostri Servizi, compresa la loro legalità e adeguatezza. Generando Contenuti sulla Piattaforma, ci concedete il diritto e la licenza di utilizzare, modificare, eseguire pubblicamente, visualizzare pubblicamente, riprodurre e distribuire tali Contenuti su e attraverso il Servizio. L'utente conserva tutti i propri diritti sui Contenuti creati, pubblicati o visualizzati su o tramite il Servizio ed è responsabile della protezione di tali diritti.\n" +
    "L'utilizzo dei nostri Servizi implica la consapevolezza che la Società non può garantire che i contenuti generati siano corretti al 100% o di ottima qualità o del tutto veritieri e, pertanto, non sarà soggetta ad alcun rimborso o richiesta di informazioni sulla qualità finale dei contenuti.",
  terms5:
    "L'utente è consapevole e accetta di essere completamente responsabile dei Contenuti e di tutte le attività associate al proprio account, siano esse eseguite dall'utente stesso o da un altro utente con accesso al suo account. \n" +
    "È vietato creare o trasmettere Contenuti illegali, offensivi, disdicevoli, intesi come ripugnanti, minacciosi, diffamatori, indecenti, illegali o altrimenti offensivi. Il seguente elenco di Contenuti può essere considerato limitato, ma non è limitato a: \n" +
    "- Impegnarsi in un'attività illegale o incoraggiare tale comportamento illegale; \n" +
    "- Utilizzare la Piattaforma per creare materiali diffamatori, discriminatori e offensivi, incluse menzioni o osservazioni su religione, razza, orientamento sessuale/identità di genere, origine nazionale/etnica o altri gruppi; \n" +
    "- Utilizzare il Servizio per promuovere attività vietate, come l'uso di sostanze/droghe illegali e servizi per adulti; \n" +
    "- Generare spam, compiti generati dalla macchina o assegnati in modo casuale, pubblicità non autorizzata o non richiesta, lettere a catena;\n" +
    "- qualsiasi altra forma di sollecitazione non protetta, o qualsiasi tipo di lotteria o gioco d'azzardo; \n" +
    "- Utilizzare il Servizio per creare o sviluppare virus, malware, cavalli di Troia o materiale simile progettato per causare interruzioni, danni o interferenze con il funzionamento di qualsiasi software; \n" +
    "- Violare i diritti di proprietà intellettuale di qualsiasi parte, inclusi, a titolo esemplificativo, brevetti, marchi o segreti commerciali, nonché diritti d'autore e diritti di pubblicità; \n" +
    "- Impersonare persone, compresi dipendenti e rappresentanti della Società;\n" +
    "- mettere in pericolo la privacy di altri. \n" +
    "Se non diversamente concordato, la Società ha la facoltà di rifiutare o eliminare i contenuti sessualmente espliciti e di decidere se sono appropriati per l'uso o se sono conformi ai presenti Termini. La Società è libera di modificare il modo in cui il Contenuto viene presentato e di apportare modifiche. La Società ha la facoltà di limitare o revocare il Servizio se l'utente lo utilizza per creare Contenuti considerati offensivi. La Società non è responsabile dei contenuti pubblicati dagli utenti e/o da terzi su altre piattaforme.",
  termsKyc:
    "Per utilizzare i Servizi, l'utente deve confermare il proprio indirizzo e-mail. Tuttavia, l'uso dei Servizi è limitato ai residenti dei seguenti Paesi: Corea del Nord (Repubblica Popolare Democratica di Corea), Iran e Myanmar.\n" +
    "Utenti con attività sospette o residenti in paesi ad alto rischio, come Afghanistan, Albania, Barbados, Burkina Faso, Cambogia, Isole Cayman, Repubblica Democratica del Congo, Gibilterra, Haiti, Giamaica, Giordania, Mali, Marocco, Mozambico, Nicaragua, Pakistan, Panama, Filippine, Russia, Senegal, Sudan del Sud, Siria, Tanzania, Trinidad e Tobago, Turchia, Uganda, Emirati Arabi Uniti, Vanuatu, Yemen e Zimbabwe, possono essere soggetti a verifiche supplementari (ad es.Know Your Customer o KYC) fornendo dati e/o documenti aggiuntivi. Tali informazioni possono essere richieste anche in caso di richiesta ufficiale da parte delle autorità o di altri organi preposti all'applicazione della legge.",
  terms6:
    "Per accedere ai Servizi, l'utente deve disporre di una quantità sufficiente di Neuroni, la valuta virtuale della Piattaforma, nel proprio portafoglio. L'utente può controllare il proprio saldo e ricaricarlo nell'apposita sezione del Sito web. I Neuroni non hanno valore al di fuori della Piattaforma, possono essere utilizzati solo sulla Piattaforma e non possono essere trasferiti a un'altra piattaforma o persona. 10 Neuroni equivalgono a 1 euro.\n" +
    "Le transazioni vengono elaborate entro 72 ore dal pagamento. Una volta completato il pagamento, l'aggiornamento del saldo potrebbe richiedere fino a 24 ore, anche se di solito il processo è istantaneo. Per i vostri depositi siamo autorizzati a utilizzare un elaboratore di servizi di pagamento di terze parti (il \"Processore di pagamento\"). Il rispettivo Processore di pagamento è il principale responsabile della facilitazione del pagamento delle vostre transazioni. Per elaborare la vostra transazione, il Payment Processor richiede i vostri dati personali e le informazioni di pagamento, come i dettagli della carta di debito/credito, l'indirizzo e altre informazioni. Queste informazioni vengono utilizzate solo per elaborare le transazioni e non vengono divulgate a terzi, ad eccezione delle istituzioni finanziarie e normative coinvolte nelle procedure di elaborazione o di conformità. Per ulteriori informazioni sull'utilizzo dei vostri dati personali, consultate la nostra Informativa sulla privacy.\n" +
    "Ci riserviamo il diritto di verificare e approvare tutte le transazioni effettuate dall'utente durante l'utilizzo del Servizio per garantire che siano conformi ai presenti Termini e Condizioni e ai termini imposti sulla Piattaforma da fornitori di servizi terzi. L'utente riconosce e accetta espressamente che tali verifiche possono richiedere che l'utente ci fornisca ulteriori informazioni personali per verificare e confermare la sua identità e per eseguire verifiche volte a scoraggiare le frodi e l'uso improprio del Servizio, in conformità con la nostra Politica sulla privacy e tutte le leggi e i regolamenti applicabili.\n" +
    "Procedendo con una transazione, l'utente certifica che tutte le carte bancarie e gli altri conti di pagamento utilizzati per le transazioni sono legalmente suoi.",
  terms7:
    "Il Contenuto acquistato sul nostro sito web non è rimborsabile.L'utente non ha diritto ad alcun rimborso per eventuali spese o commissioni di transazione già pagate.I rimborsi sono possibili solo per le transazioni effettuate per l'acquisto di Neuroni e solo in caso di problemi tecnici con il Servizio fornito sul sito web e/o altri problemi derivanti da nostri errori che hanno portato all'impossibilità di ricevere il Servizio per cui si è pagato. Qualsiasi richiesta di rimborso deve essere presentata personalmente tramite l'e-mail contact{'@'}aiaieraworld.com entro 14 giorni dal momento della transazione.",
  terms8:
    "I link presenti sulla nostra piattaforma possono condurre a siti web o servizi di terze parti non di proprietà della Società. I siti web o i servizi di terzi possono essere collegati alla Società, ma quest'ultima non è responsabile del loro contenuto, delle loro politiche sulla privacy o delle loro pratiche. L'utente riconosce e accetta che la Società non può essere ritenuta responsabile per eventuali danni o perdite causati da o in relazione all'uso o all'affidamento su tali contenuti, beni o servizi offerti attraverso siti web o altri servizi. Si consiglia vivamente di leggere i termini d'uso e le politiche sulla privacy di qualsiasi sito web o servizio di terzi a cui si accede.",
  terms9:
    "Il principio del copyright ci impone di riconoscere e rispettare i diritti di proprietà intellettuale di altri. È nostra politica affrontare qualsiasi reclamo relativo a Contenuti pubblicati sul Servizio che violino i diritti di proprietà intellettuale o il copyright di altri. Se ritenete che il Servizio stia facilitando la violazione del copyright, e siete un proprietario del copyright o autorizzato per suo conto, inviateci un'e-mail all'indirizzo contact{'@'}aiaieraworld.com per presentare la vostra segnalazione. Si prega di includere nella comunicazione una spiegazione dettagliata della presunta violazione. L'ammissione di una falsa dichiarazione di violazione del diritto d'autore da parte di un Contenuto può comportare la responsabilità dell'utente per i danni, compresi i costi e le spese legali.",
  terms10:
    "Abbiamo la facoltà di sospendere o chiudere l'Account dell'utente senza alcun preavviso o responsabilità, anche se l'utente non ha violato i Termini. Questo vale indipendentemente dal motivo. L'utilizzo del Servizio da parte dell'utente sarà terminato con la cancellazione del suo account. Se si desidera rimuovere il proprio Account dalla Piattaforma, è sufficiente cessare di utilizzare il Servizio. Il presente Contratto continuerà ad avere pieno vigore ed efficacia durante l'utilizzo dei Servizi da parte dell'utente. La Società può interrompere o sospendere il vostro accesso ai Servizi in qualsiasi momento. In caso di violazione di uno qualsiasi dei termini e delle condizioni del presente Contratto, la Società può sospendere o interrompere immediatamente tutti i Servizi e l'accesso al Sito web senza alcuna responsabilità o avviso.\n" +
    "Tutte le disposizioni del presente Contratto che rimangono in vigore dopo la risoluzione sono essenziali, comprese le disposizioni sulla proprietà e le esclusioni di responsabilità. Queste limitazioni si applicano anche alle limitazioni di responsabilità. La Società, i suoi genitori, le sue filiali, le sue affiliate e i suoi dipendenti sono pienamente obbligati a difendersi da qualsiasi reclamo, responsabilità, transazione/costo ragionevole, comprese le spese legali o i danni derivanti dall'uso dei Servizi da parte dell'utente, dalla violazione del presente Contratto o dalla violazione da parte dell'utente o di un'altra parte della sua proprietà intellettuale o di altri diritti.\n" +
    "Se una disposizione dei presenti Termini è ritenuta inapplicabile o non valida, le altre disposizioni saranno modificate e interpretate per raggiungere lo scopo previsto dalla legge applicabile senza essere sostituite. Se non diversamente concordato, la mancata applicazione di un diritto o di un obbligo ai sensi dei presenti Termini non pregiudicherà la capacità di una delle parti di far valere la propria conformità al presente Contratto. La versione originale in inglese dei Termini sarà la legge prevalente in qualsiasi controversia.\n" +
    "I presenti Termini sono soggetti a modifiche e possono essere emendati o sostituiti da noi a nostra esclusiva discrezione. L'utente riconosce e accetta di essere vincolato dai Termini modificati se continua ad accedere o a utilizzare il nostro Servizio dopo l'entrata in vigore di tali revisioni. Se non si accettano i nuovi termini, si prega di interrompere l'utilizzo del sito web e del Servizio.\n" +
    "Per qualsiasi domanda relativa ai presenti Termini e Condizioni, si prega di contattarci all'indirizzo contact{'@'}aiaieraworld.com.",
};
