import { Lang } from "@/types/lang";

export default <Lang>{
  question1: "Was ist AIera?",
  question2: "Wie funktioniert AIera?",
  question3: "Welche Dienste bietet die Plattform?",
  question4: "Wie kann ich auf die AIera-Dienste zugreifen?",
  question5: "Was sind „Quanties“ und wie kann ich sie erwerben?",
  question6: "Wie berechnet die Plattform die endgültigen Kosten für eine Content-Erstellung?",
  question6_1: "Wie lange dauert es, bis Transaktionen bearbeitet werden?",
  question7: "Welche Anwendungen gibt es für AIera?",
  question8: "Welche Technologie steckt hinter AIera?",
  question9: "Welche Sprachen kann AIera verarbeiten?",
  question10: "Wie stellt die Plattform die Qualität der generierten Inhalte sicher?",
  answer1:
    "AIera ist ein vielseitiges neuronales Netzwerk, das in der Lage ist, Texte zu erstellen, sie zu überarbeiten, originelle Ideen anzubieten und ultrarealistische Bilder und Audiodateien mit lebensechten Stimmen zu erzeugen.",
  answer2:
    "AIera nutzt hochmoderne neuronale Netze, die in der Lage sind, Inhalte zu analysieren und zu generieren, und gleichzeitig kreativ genug sind, um einzigartige Ideen und Lösungen zu liefern.",
  answer3:
    "AIera besteht aus drei verschiedenen Bereichen: Der Textgenerierungsdienst ermöglicht es Nutzern, Texte umzuformulieren, innovative Ideen, Gedichte und mehr zu erstellen. Der Text-to-Speech-Service bietet 6 hochrealistische Stimmen für die Erstellung von Audioinhalten. Der Bereich Bilderstellung ermöglicht es dem Nutzer, jedes beliebige Bild in 17 verschiedenen Stilen zu erstellen.",
  answer4:
    "Um die Dienste nutzen zu können, musst du ein Konto erstellen und deine E-Mail-Adresse verifizieren. Sobald du registriert bist, kannst du zum gewünschten Service gehen und sicherstellen, dass du genügend „Quanties“ hast, um sie zu nutzen.",
  answer5:
    "Auf unserer Plattform wurde ein Guthabensystem eingeführt, bei dem „Quanties“ als spezielle Währung für den Zugang zu den AIera-Diensten dienen. Um „Quanties“ zu erhalten, gehe in dein Profil und klicke auf den Button „Quanties kaufen“.",
  answer6:
    "Es gibt einen Grundpreis für jeden Service. Zum Beispiel kostet 1 Bildgenerierung 50 „Quanties“, es gibt keine zusätzlichen Optionen, der Preis ist also statisch. Für andere Dienste, wie z. B. Text-to-Speech, gibt es zusätzliche Funktionen wie Sprache, Textgröße und Sprachtyp. Alle diese zusätzlichen Optionen wirken sich auf den Preis aus, und wir haben dafür gesorgt, dass die endgültigen Kosten ordnungsgemäß angezeigt werden, bevor ein Nutzer seine „Quanties“ ausgibt.",
  answer6_1:
    "Transaktionen werden normalerweise innerhalb von 72 Stunden nach der Zahlung bearbeitet. Nachdem die Zahlung abgeschlossen ist, kann es bis zu 24 Stunden dauern, bis dein Kontostand aktualisiert wird, obwohl der Vorgang normalerweise sofort erfolgt.",
  answer7:
    "AIera kann verwendet werden, um hochwertigen Text zu generieren, bestehende Inhalte zu ergänzen und umzuschreiben, kreative Ideen zu liefern, realistische Bilder und Audiodateien zu erstellen.",
  answer8:
    "AIera basiert auf fortschrittlichen Technologien der künstlichen Intelligenz, einschließlich neuronaler Netze und Deep Learning, und nutzt Techniken zur Generierung und Transformation von Inhalten.",
  answer9:
    "AIera kann Texte in vielen Sprachen verarbeiten, einschließlich, aber nicht beschränkt auf Englisch, Niederländisch, Spanisch, Französisch, Deutsch, Italienisch und andere gängige Sprachen.",
  answer10:
    "AIera verwendet fortschrittliche Modelle und Algorithmen, die auf großen Datenmengen trainiert werden und mit Qualitätskontrollmechanismen ausgestattet sind, um sicherzustellen, dass qualitativ hochwertige Inhalte generiert werden, auch wenn wir keine 100 % genauen Ergebnisse garantieren können.",
};
