import { Lang } from "@/types/lang";

export default <Lang>{
  registration: "Registrierung",
  login: "Anmeldung",
  signUpWarning1:
    "Indem Sie auf 'Anmelden' klicken oder mit den anderen Optionen unten fortfahren, erklären Sie sich mit unseren ",
  signUpWarning2: "Nutzungsbedingungen einverstanden ",
  signUpWarning3: "und garantieren, dass sie die Datenschutzrichtlinie ",
  signUpWarning4: "Datenschutzrichtlinie",
  logout: "Abmeldung",
  reset: "Passwort zurücksetzen",
  createPoem: "Gedicht erstellen",
  paraphrase: "Paraphrase",
  Creative: "Kreativ",
  Image: "Bild",
  registrationConfirmation: "Bestätigung der Registrierung",
  copyToClipboard: "In die Zwischenablage kopieren",
  buyNeurons: "Kaufen Sie 'AIera'",
  speechToAudio: "Text zu Audio",
};
