import { Lang } from "@/types/lang";

export default <Lang>{
  privacyHeading1: "Política de privacidad",
  privacyHeading2: "Objetivo de la recogida de datos",
  privacyHeading3: "Cookies",
  privacyHeading4: "Divulgación de datos",
  privacyHeading5: "Período de conservación",
  privacyHeading6: "Protección de datos",
  privacyHeading7: "GDPR",
  privacyHeading8: "Enlaces a sitios web de terceros",
  privacy1:
    "AI Era se compromete a mantener la confidencialidad de Nuestros clientes. La presente Política de Privacidad describe cómo Nuestra Plataforma recopila, utiliza, comparte, almacena, protege y divulga su Información Personal o Información de Uso.\n" +
    "Al utilizar nuestros Servicios, usted acepta y consiente la presente Política de Privacidad. Si no cumple esta Política de Privacidad, deberá dejar de utilizar Nuestros Servicios inmediatamente.\n" +
    "(Empresa) se rige por las leyes de (País) como sociedad de responsabilidad limitada, con domicilio social en (dirección). Número de registro:  Número de teléfono: + Correo electrónico: contact{'@'}aieraworld.com",
  privacy2:
    "Las finalidades para las que recopilamos sus datos son, entre otras, las siguientes:\n" +
    "\t•\tRegistro y gestión de su cuenta;\n" +
    "\t•\tComunicarnos directamente con usted;\n" +
    "\t•\tProporcionar información sobre Nosotros, publicar reseñas, mensajes en foros o contenidos; \n" +
    "\t•\tResponder a sus preguntas;\n" +
    "\t•\tCumplir las disposiciones legales aplicables.\n" +
    "Recopilamos información sobre su ordenador o dispositivo móvil mientras utiliza Nuestros Servicios y la utilizamos para analizar sus datos. Los datos que recopilamos incluyen direcciones IP de ordenadores, ID de dispositivos móviles y detalles técnicos sobre su ordenador o dispositivo móvil.",
  privacy3:
    '"Cookies" son pequeños archivos almacenados en su ordenador o dispositivo móvil que nos permiten rastrear el uso de Nuestros Servicios e identificar cuando usted visita el sitio web.\n' +
    "La modificación de las preferencias de su navegador o dispositivo móvil puede impedir que se acepten cookies en su ordenador o dispositivo, lo que puede impedir el uso de determinadas funciones disponibles en Nuestra Plataforma. Usted reconoce y acepta que esta Política de Privacidad no cubre el uso de cookies por parte de terceros.",
  privacy4:
    "Nuestra política prohíbe la cesión de sus Datos Personales a terceros para su monetización mediante publicidad o ventas. Sin embargo, podemos compartir sus Datos Personales con terceros que presten servicios como análisis de negocio, atención al cliente, marketing, distribución y procesamiento de pagos. Los proveedores pueden recopilar datos en nuestro nombre para gestionar funciones de la aplicación o prestar servicios personalizados. La información proporcionada por terceros proveedores es exclusivamente para sus propios fines y no puede ser compartida o explotada para ningún otro propósito.\n" +
    "Podemos revelar sus Datos Personales e Información de Uso a entidades gubernamentales cuando sea necesario, como en respuesta a una orden judicial o demanda gubernamental. Si debemos cumplir con una solicitud legal, se le enviará una notificación por correo electrónico a su bandeja de entrada.",
  privacy5:
    "El período de conservación de sus datos personales lo determina la Empresa en función del tiempo necesario para cumplir esta Política de Privacidad y puede variar. Conservaremos y utilizaremos sus datos según sea necesario para cumplir nuestras obligaciones legales, resolver conflictos y hacer cumplir nuestros acuerdos y políticas. Normalmente, los datos se conservan durante un año desde la última provisión (o actualización) de datos por parte del usuario.",
  privacy6:
    "Queremos que confíe en nosotros y que tenga la seguridad de que su información está protegida. Nos adherimos a las medidas de salvaguarda estándar comúnmente aceptadas para la Información Personal y la Información de Uso. Aunque ningún Servicio puede garantizar una seguridad total, hemos implementado las medidas administrativas, técnicas y de seguridad necesarias para garantizar la protección de su información. El personal autorizado, los agentes, los proveedores externos y otras entidades sólo utilizan Nuestros datos para los fines requeridos. Utilizamos cortafuegos adecuados para protegerlos contra usos no autorizados, así como tecnologías de cifrado y sistemas de detección de intrusos.\n" +
    "La Empresa tomará todas las medidas necesarias para garantizar que sus datos sean tratados de forma segura y de acuerdo con esta Política de Privacidad. No se transferirá ningún Dato Personal a nivel internacional a menos que se establezcan controles adecuados para salvaguardar sus datos y otra información.",
  privacy7:
    "Cumplimos con el Reglamento General de Protección de Datos (RGPD) en lo que respecta al tratamiento de sus Datos Personales. Podemos procesar Datos Personales en las siguientes condiciones:\n" +
    "\t•\tUsted ha dado su consentimiento para el tratamiento de sus Datos Personales para uno o varios fines específicos.\n" +
    "\t•\tCumplimiento de una obligación legal a la que esté sujeta la empresa.\n" +
    "\t•\tIntereses vitales: El Tratamiento de Datos Personales es necesario para proteger sus intereses vitales o los de otra persona física.\n" +
    "\t•\tInterés público: El Tratamiento de Datos Personales está relacionado con una tarea que se lleva a cabo en interés público o en el ejercicio de la autoridad oficial conferida a la Empresa.\n" +
    "\t•\tIntereses legítimos: El Tratamiento de Datos Personales es necesario a efectos de los intereses legítimos perseguidos por la Empresa.\n" +
    "En cualquier situación, la Empresa ayudará gustosamente a aclarar la base jurídica exacta que se aplica al tratamiento, incluyendo si el suministro de Datos Personales es una necesidad legal o contractual, o un requisito previo para celebrar un contrato.\n" +
    "La Empresa se compromete a respetar la confidencialidad de sus Datos Personales y a garantizarle el ejercicio de sus derechos. Usted tiene derecho en virtud de la presente Política de Privacidad, y por ley a:\n" +
    "\t•\tSolicitar acceso a sus Datos Personales;\n" +
    "\t•\tSolicitar la corrección de los Datos Personales que tenemos sobre usted;\n" +
    "\t•\tOponerse al tratamiento de sus Datos Personales;\n" +
    "\t•\tSolicitar la supresión de sus Datos Personales. Usted tiene derecho a solicitarnos que borremos o eliminemos sus Datos Personales cuando no exista una buena razón para que sigamos procesándolos;\n" +
    "\t•\tSolicitar la transferencia de sus Datos Personales;\n" +
    "\t•\tRetirar su consentimiento para el tratamiento de datos personales.",
  privacy8:
    "Nuestra Plataforma puede contener enlaces a otros sitios web no gestionados por la Empresa. Si hace clic en un enlace de terceros, será dirigido al sitio web de ese tercero. Le recomendamos encarecidamente que revise la política de privacidad de cada sitio web que visite.\n" +
    "No tenemos ningún control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios web o servicios de terceros.\n" +
    "Esta Política de Privacidad puede ser actualizada o modificada en cualquier momento sin previo aviso, por lo que le sugerimos que consulte esta página de vez en cuando. Si tiene alguna pregunta sobre esta Política de Privacidad, puede ponerse en contacto con nosotros en contact{'@'}aieraworld.com.\n",
};
