import { Lang } from "@/types/lang";

export default <Lang>{
  privacyHeading1: "Datenschutzbestimmungen",
  privacyHeading2: "Der Zweck der Erfassung Ihrer Informationen",
  privacyHeading3: "Cookies",
  privacyHeading4: "Offenlegung von Daten",
  privacyHeading5: "Aufbewahrungsfrist",
  privacyHeading6: "Datenschutz",
  privacyHeading7: "GDPR",
  privacyHeading8: "Links zu Websites Dritter",
  privacy1:
    "AI Era ist bestrebt, die Vertraulichkeit unserer Kunden zu wahren. Diese Datenschutzrichtlinie beschreibt, wie Unsere Plattform Ihre persönlichen Daten oder Nutzungsdaten sammelt, verwendet, weitergibt, speichert, schützt und offenlegt.\n" +
    "Durch die Nutzung Unserer Dienste akzeptieren Sie diese Datenschutzrichtlinie und stimmen ihr zu. Wenn Sie diese Datenschutzrichtlinie nicht einhalten, sind Sie verpflichtet, die Nutzung Unserer Dienste unverzüglich einzustellen.\n" +
    "(Unternehmen) unterliegt den Gesetzen (Land) als Gesellschaft mit beschränkter Haftung und hat seinen eingetragenen Sitz in (Adresse). Registriernummer:  Telefon: + E-Mail: contact{'@'}aieraworld.com",
  privacy2:
    "Die Zwecke, für die wir Ihre Daten erfassen, sind unter anderem: \n" +
    "\t•\tRegistrierung und Verwaltung Ihres Kontos;\n" +
    "\t•\tWir kommunizieren direkt mit Ihnen;\n" +
    "\t•\tBereitstellung von Informationen über uns, Veröffentlichung von Bewertungen, Forenbeiträgen oder Inhalten; \n" +
    "\t•\tBeantwortung Ihrer Anfragen;\n" +
    "\t•\tEinhaltung der geltenden gesetzlichen Bestimmungen.\n" +
    "Wir sammeln Informationen über Ihren Computer oder Ihr mobiles Gerät, während Sie unsere Dienste nutzen, und verwenden diese, um Ihre Daten zu analysieren. Zu den von uns erfassten Daten gehören IP-Adressen von Computern, IDs von Mobilgeräten und technische Details über Ihren Computer oder Ihr Mobilgerät.",
  privacy3:
    '"Cookies" sind kleine Dateien, die auf Ihrem Computer oder Mobilgerät gespeichert werden und die es uns ermöglichen, die Nutzung unserer Dienste zu verfolgen und festzustellen, wann Sie die Website besuchen.\n' +
    "Durch Änderung der Einstellungen Ihres Browsers oder mobilen Geräts können Sie verhindern, dass Cookies auf Ihrem Computer oder Gerät akzeptiert werden, was die Nutzung bestimmter Funktionen auf unserer Plattform beeinträchtigen kann. Sie erkennen an und stimmen zu, dass diese Datenschutzrichtlinie nicht die Verwendung von Cookies durch Dritte abdeckt.",
  privacy4:
    "Unsere Richtlinie verbietet die Weitergabe Ihrer persönlichen Daten an Dritte zum Zwecke der Monetarisierung durch Werbung oder Verkauf. Wir können jedoch Ihre persönlichen Daten an Dritte weitergeben, die Dienstleistungen wie Geschäftsanalysen, Kundendienst, Marketing, Vertrieb und Zahlungsabwicklung anbieten. Anbieter können in unserem Namen Daten sammeln, um Anwendungsfunktionen zu verwalten oder kundenspezifische Dienste anzubieten. Die von Drittanbietern zur Verfügung gestellten Informationen sind ausschließlich für deren eigene Zwecke bestimmt und können nicht weitergegeben oder für andere Zwecke genutzt werden.\n" +
    "Wir können Ihre persönlichen Daten und Nutzungsinformationen an staatliche Stellen weitergeben, wenn dies erforderlich ist, z. B. als Reaktion auf einen Gerichtsbeschluss oder eine behördliche Aufforderung. Wenn wir einer gesetzlichen Aufforderung nachkommen müssen, wird eine E-Mail-Benachrichtigung an Ihren Posteingang gesendet.",
  privacy5:
    "Die Dauer der Aufbewahrung Ihrer persönlichen Daten wird vom Unternehmen auf der Grundlage der erforderlichen Zeit zur Einhaltung dieser Datenschutzrichtlinie festgelegt und kann variieren. Wir werden Ihre Daten so lange aufbewahren und verwenden, wie es zur Erfüllung unserer rechtlichen Verpflichtungen, zur Beilegung von Streitigkeiten und zur Durchsetzung unserer Vereinbarungen und Richtlinien erforderlich ist. In der Regel werden die Daten für ein Jahr ab der letzten Bereitstellung (oder Aktualisierung) der Daten durch den Nutzer gespeichert.",
  privacy6:
    "Wir möchten, dass Sie uns vertrauen und darauf vertrauen können, dass Ihre Daten geschützt sind. Wir halten uns an allgemein anerkannte Standard-Schutzmaßnahmen für persönliche Daten und Nutzungsdaten. Obwohl kein Dienst vollständige Sicherheit garantieren kann, haben wir die notwendigen administrativen, technischen und Sicherheitsmaßnahmen eingeführt, um den Schutz Ihrer Daten zu gewährleisten. Autorisiertes Personal, Vertreter, Drittanbieter und andere Stellen verwenden unsere Daten nur für die erforderlichen Zwecke. Wir verwenden geeignete Firewalls, um sie gegen unbefugte Nutzung zu schützen, sowie Verschlüsselungstechnologien und Systeme zur Erkennung von Eindringlingen.\n" +
    "Das Unternehmen wird alle notwendigen Schritte unternehmen, um sicherzustellen, dass Ihre Daten sicher und in Übereinstimmung mit dieser Datenschutzrichtlinie behandelt werden. Personenbezogene Daten werden nur dann international übertragen, wenn angemessene Kontrollen zum Schutz Ihrer Daten und anderer Informationen vorhanden sind.",
  privacy7:
    "Wir halten uns bei der Verarbeitung Ihrer personenbezogenen Daten an die Allgemeine Datenschutzverordnung (GDPR). Wir können personenbezogene Daten unter den folgenden Bedingungen verarbeiten:\n" +
    "\t•\tSie haben Ihre Zustimmung zur Verarbeitung personenbezogener Daten für einen oder mehrere bestimmte Zwecke gegeben.\n" +
    "\t•\tErfüllung einer gesetzlichen Verpflichtung, der das Unternehmen unterliegt.\n" +
    "\t•\tLebenswichtige Interessen: Die Verarbeitung personenbezogener Daten ist erforderlich, um Ihre lebenswichtigen Interessen oder die einer anderen natürlichen Person zu schützen.\n" +
    "\t•\tÖffentliche Interessen: Die Verarbeitung personenbezogener Daten ist mit einer Aufgabe verbunden, die im öffentlichen Interesse oder in Ausübung öffentlicher Gewalt erfolgt, die dem Unternehmen übertragen wurde.\n" +
    "\t•\tBerechtigte Interessen: Die Verarbeitung personenbezogener Daten ist zur Wahrung der berechtigten Interessen des Unternehmens erforderlich.\n" +
    "In jedem Fall ist das Unternehmen gerne bereit, bei der Klärung der genauen Rechtsgrundlage, die für die Verarbeitung gilt, behilflich zu sein, einschließlich der Frage, ob die Bereitstellung personenbezogener Daten eine gesetzliche oder vertragliche Notwendigkeit oder eine Voraussetzung für den Abschluss eines Vertrags ist.\n" +
    "Das Unternehmen verpflichtet sich, die Vertraulichkeit Ihrer persönlichen Daten zu wahren und zu gewährleisten, dass Sie Ihre Rechte wahrnehmen können. Sie haben gemäß dieser Datenschutzrichtlinie und dem Gesetz das Recht auf:\n" +
    "\t•\tZugang zu Ihren persönlichen Daten beantragen;\n" +
    "\t•\tdie Berichtigung der persönlichen Daten, die wir über Sie gespeichert haben, verlangen;\n" +
    "\t•\tWiderspruch gegen die Verarbeitung Ihrer personenbezogenen Daten;\n" +
    "\t•\tVerlangen Sie die Löschung Ihrer persönlichen Daten. Sie haben das Recht, von uns zu verlangen, dass wir Ihre personenbezogenen Daten löschen oder entfernen, wenn es für uns keinen triftigen Grund gibt, sie weiter zu verarbeiten;\n" +
    "\t•\tdie Übermittlung Ihrer persönlichen Daten beantragen;\n" +
    "\t•\tIhre Zustimmung zur Verarbeitung personenbezogener Daten zu widerrufen.",
  privacy8:
    "Unsere Plattform kann Links zu anderen Websites enthalten, die nicht vom Unternehmen betrieben werden. Wenn Sie auf einen Link eines Dritten klicken, werden Sie auf die Website dieses Dritten weitergeleitet. Wir empfehlen Ihnen dringend, die Datenschutzrichtlinien jeder Website, die Sie besuchen, zu lesen.\n" +
    "Wir haben keine Kontrolle über und übernehmen keine Verantwortung für den Inhalt, die Datenschutzrichtlinien oder die Praktiken von Websites oder Diensten Dritter.\n" +
    "Diese Datenschutzrichtlinie kann jederzeit ohne vorherige Ankündigung aktualisiert oder geändert werden, daher empfehlen wir Ihnen, diese Seite gelegentlich zu überprüfen. Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben, können Sie uns unter contact@aieraworld.com kontaktieren.",
};
