import { Lang } from "@/types/lang";

export default <Lang>{
  question1: "Qu'est-ce qu'AIera ?",
  question2: "Comment fonctionne AIera ?",
  question3: "Quels sont les services offerts par la plateforme ?",
  question4: "Comment puis-je accéder aux services d'AIera ?",
  question5: "Que sont les « Quanties » et comment puis-je les acquérir ?",
  question6: "Comment la Plateforme calcule-t-elle le coût final d'une génération de contenu ?",
  question6_1: "Combien de temps faut-il pour que les transactions soient traitées ?",
  question7: "Quelles sont les applications d'AIera ?",
  question8: "Quelle est la technologie qui sous-tend AIera ?",
  question9: "Quelles langues AIera peut-elle traiter ?",
  question10: "Comment la plateforme garantit-elle la qualité du contenu généré ?",
  answer1:
    "AIera est un réseau neuronal polyvalent, capable de créer des textes, de les réviser, de proposer des idées inventives, de générer des images ultra-réalistes et des fichiers audio avec des voix réalistes.",
  answer2:
    "AIera utilise des réseaux neuronaux de pointe, capables d'analyser et de générer des contenus, tout en étant suffisamment créatifs pour proposer des idées et des solutions uniques.",
  answer3:
    "AIera se compose de trois sections distinctes : Le service de génération de texte permet aux utilisateurs de reformuler des textes, de créer des idées innovantes, des poèmes, etc. Le service de synthèse vocale propose 6 voix très réalistes pour créer des contenus audio. Le service de génération d'images permet de créer n'importe quelle image dans 17 styles différents.",
  answer4:
    "Pour utiliser les services, vous devez créer un compte et vérifier votre adresse électronique. Une fois inscrit, vous pouvez accéder au service souhaité, en vous assurant que vous avez suffisamment de « Quanties » pour l'utiliser.",
  answer5:
    "Un système d'équilibre a été mis en place sur notre plateforme où les « Quanties » servent de monnaie spéciale pour accéder aux Services d'AIera. Pour obtenir des « Quanties », rendez-vous sur votre profil et cliquez sur le bouton « Acheter des Quanties ».",
  answer6:
    "Il y a un coût de base pour chaque Service. Par exemple, une génération d'images coûte 50 « Quanties », il n'y a pas d'options supplémentaires, le prix est donc statique. Pour d'autres services tels que la synthèse vocale, il existe des caractéristiques supplémentaires telles que la langue, la taille du texte et le type de voix. Toutes ces options supplémentaires font varier le prix et nous avons veillé à ce que le coût final soit correctement affiché avant que l'utilisateur ne dépense ses « Quanties ».",
  answer6_1:
    "Les transactions sont généralement traitées dans les 72 heures suivant le paiement. Une fois le paiement effectué, la mise à jour du solde de votre compte peut prendre jusqu'à 24 heures, mais le processus est généralement instantané.",
  answer7:
    "AIera peut être utilisé pour générer des textes de qualité, augmenter et réécrire des contenus existants, fournir des idées créatives, créer des images et des fichiers audio réalistes.",
  answer8:
    "AIera repose sur des technologies avancées d'intelligence artificielle, notamment les réseaux neuronaux et l'apprentissage profond, et utilise des techniques de génération et de transformation de contenu.",
  answer9:
    "AIera peut traiter des textes dans de nombreuses langues, y compris, mais sans s'y limiter, l'anglais, le néerlandais, l'espagnol, le français, l'allemand, l'italien et d'autres langues courantes.",
  answer10:
    "AIera utilise des modèles et des algorithmes avancés qui sont formés sur de grandes quantités de données et équipés de mécanismes de contrôle de la qualité afin de garantir que le contenu généré est de haute qualité, bien que nous ne puissions pas garantir des résultats exacts à 100 %",
};
