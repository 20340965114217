<template>
  <div class="terms">
    <div>
      <h1 class="terms__heading">{{ $t("terms.termsHeading1") }}</h1>
      <div>
        <pre>{{ $t("terms.terms1") }}</pre>
      </div>

      <h1 class="terms__heading">{{ $t("terms.termsHeading2") }}</h1>
      <div>
        <pre>{{ $t("terms.terms2") }}</pre>
      </div>

      <h1 class="terms__heading">{{ $t("terms.termsHeading3") }}</h1>
      <div>
        <pre>{{ $t("terms.terms3") }}</pre>
      </div>

      <h1 class="terms__heading">{{ $t("terms.termsHeading4") }}</h1>
      <div>
        <pre>{{ $t("terms.terms4") }}</pre>
      </div>

      <h1 class="terms__heading">{{ $t("terms.termsHeading5") }}</h1>
      <div>
        <pre>{{ $t("terms.terms5") }}</pre>
      </div>

      <h1 class="terms__heading">{{ $t("terms.termsHeadingKyc") }}</h1>
      <div>
        <pre>{{ $t("terms.termsKyc") }}</pre>
      </div>

      <h1 class="terms__heading">{{ $t("terms.termsHeading6") }}</h1>
      <div>
        <pre>{{ $t("terms.terms6") }}</pre>
      </div>

      <h1 class="terms__heading">{{ $t("terms.termsHeading7") }}</h1>
      <div>
        <pre>{{ $t("terms.terms7") }}</pre>
      </div>

      <h1 class="terms__heading">{{ $t("terms.termsHeading8") }}</h1>
      <div>
        <pre>{{ $t("terms.terms8") }}</pre>
      </div>

      <h1 class="terms__heading">{{ $t("terms.termsHeading9") }}</h1>
      <div>
        <pre>{{ $t("terms.terms9") }}</pre>
      </div>

      <h1 class="terms__heading">{{ $t("terms.termsHeading10") }}</h1>
      <div>
        <pre>{{ $t("terms.terms10") }}</pre>
      </div>
      <div>
        <pre>{{ $t("terms.terms11") }}</pre>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.terms {
  padding-bottom: 40px;
  font-family: Helvetica, sans-serif;

  h1:first-of-type {
    padding-top: 40px;
  }

  h1 {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  &__heading {
    padding-top: 12px;
    text-align: center;
  }

  pre {
    margin: 0 auto;
    width: 90%;
    white-space: pre-wrap;
  }
}
</style>
