import { Lang } from "@/types/lang";

export default <Lang>{
  title: "Terms and Conditions",
  termsHeading1: "Terms & Conditions",
  termsHeading2: "Eligibility of Use",
  termsHeading3: "Warranty and Liability",
  termsHeading4: "The Content",
  termsHeading5: "Restricted Content",
  termsHeading6: "Payment Procedure",
  termsHeading7: "Refund Policy",
  termsHeading8: "Third-party Services",
  termsHeading9: "Copyrights",
  termsHeading10: "Termination",
  termsHeadingKyc: "KYC verification",

  terms1:
    "Before using the Services provided by AI Era (the “Company,” “We,” “Us,” “the Platform,” the “Service(s),” or “the Website”), it is recommended that you read the following Terms & Conditions (“Agreement”). By accessing the website or services in any way, you acknowledge that you have read, understood, and accepted the terms and conditions of this Agreement and agree to be bound by all its provisions. If you do not agree to all the terms and conditions of this Agreement, you must discontinue any use of the Platform or Services immediately. The use of the Company's Services is conditioned upon your acceptance of these Terms. If you do not agree with these Terms, please do NOT use the Website.\n" +
    "AI Era World is owned and operated by (Company).\n" +
    "(Company) is regulated under the laws of (Jurisdiction) as a private limited company, with its registered office at (Address)\n" +
    "Phone Number: E-mail: contact{'@'}aieraworld.com",
  terms2:
    "You warrant to Us that: \n" +
    "\t•\tYou are of legal age in your country of residence; \n" +
    "\t•\tYou are capable of entering into a binding contract and not being prohibited by law from using or accessing the Services; \n" +
    "\t•\tThat the information you provide to Us is accurate and truthful; \n" +
    "\t•\tYou are legally authorized to use any payment method, including credit or debit card for fulfilling any order (By providing such information, you acknowledge and authorize Us to share the information with payment processing third parties to facilitate the completion of your order. If We suspect fraud, an unauthorized or illegal transaction, We reserve the right to refuse or cancel your order at any time for certain reasons, including Service unavailability, technical errors or incorrect pricing).\n" +
    "In jurisdictions where prohibited by law, this Agreement becomes invalid, and the right to access the Services is revoked.",
  terms3:
    "The Platform is continuously updated concerning the availability, errors, and inaccuracies of Our Services. Our Platform may have Services that are inaccurately priced, outdated, or not available at all times, and We may experience delays in updating information about our Service on the Platform and in advertising on other websites.\n" +
    "Our assurance does not cover all possible information, including prices and product images. Specifications, availability, or services may differ, and We have the authority to modify or revise information without prior notice, including any errors, inaccuracies, or omissions. The Company reserves the right to modify its prices at any time before receiving an Order.\n" +
    "Whether you use a credit or debit card, payment can be made using whichever card you have been issued, and the card issuer will validate it. If we are unable to obtain the necessary authorization, We will not be held accountable for any delay or non-delivery of your order.\n" +
    "Any promotions made available through the Service may be subject to rules distinct from these Terms. To ensure your compliance with applicable regulations and our Privacy Policy, we recommend reading these rules and considering any promotions. The terms of a Promotion will be subject to the same rules as these Terms if any of those rules are not followed.\n" +
    "If you create an account with Us, you must provide accurate, complete, and up-to-date information to create your account. Failure to do so may result in the immediate termination of your account on the Platform, as it is considered a breach of the Terms.\n" +
    "It is your responsibility to maintain the password that you use for accessing the Service and any activities related to your password, whether with Our Service or a Third-Party Social Media Service. You are obligated to maintain confidentiality while sharing your password with others. Upon discovering that your account has been breached or used for illegal purposes, you must promptly notify Us.\n" +
    "Our service is limited to one user account per user, and if you violate our Terms or create multiple accounts, we may remove your data and restrict access. We grant you the power to produce or transmit audio and video content through Our Service. It is your responsibility to ensure that the Content created using Our Service(s) meets legal requirements, including its correctness and appropriateness. The Service grants you the right and license to use, modify, perform, exhibit, copy (in its entirety), reproduce, and distribute Content created through your creation and posting to it. The Service grants you complete rights over all Content, meaning you are responsible for protecting and retaining those rights.\n" +
    "You are to use the Service at your own risk. You acknowledge that the Service may contain content that you may find offensive, indecent, incorrect, or objectionable, and you agree that no liability exists for any errors or damages resulting from your use of our Platform. You bear complete responsibility for all your actions related to the Services. If you engage in fraudulent, abusive, or otherwise illegal behavior while using the Services, your access or use rights could be terminated. You are prohibited from posting or transmitting any message or solicitation intended to obtain passwords, accounts, or private information from other users of the Platform. It is expressly prohibited to use the Services for infringing on the security of any computer network, cracking passwords or security encryption codes, or transferring or storing illegal material (including potentially harmful or illegal content) or engaging in any illegal activity.\n" +
    "The Platform's source code will not be decompiled, reverse-engineered, or obtained through any other means. You are responsible for withholding, filing, and reporting all taxes, duties, and other governmental assessments linked to your activity in connection with the Services. The Company implements regular Content Backups but does not guarantee no loss or corruption of data. Backup points may be invalid or corrupted if the Content was corrupt before being backed up or changed during the backup process, without limitation. The Company will make every effort to resolve any problems that may arise, whether they are known or discovered, that affect the backups of Content. However, the Company is not responsible for the integrity of Content or its failure to successfully restore it to a usable state.",
  terms4:
    "Our Service allows you to generate Audio, Text & Image Content. You are responsible for the Content you generate using Our Services, including its legality and appropriateness. By generating Content on the Platform, you grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you create, post, or display on or through the Service, and you are responsible for protecting those rights.\n" +
    "Using our Services implies that you understand that the Company cannot guarantee that the generated content is 100% correct or of the best quality or entirely truthful and, therefore, will not be subject to any refunds or queries regarding the final content quality.",
  terms5:
    "You are aware and agree that you bear complete responsibility for the Content as well as for all activities associated with your account, whether they are performed by you or another user with an access to your account. \n" +
    "You are prohibited from creating or transmitting any Content that is illegal, offensive, disgraceful, intended to be repugnant, threatening, defamatory, indecent, unlawful or otherwise offensive. Following list of Contents may be deemed restricted, but is not limited to: \n" +
    "\t•\tEngaging in an unlawful activity or encouraging such illegal behaviour; \n" +
    "\t•\tUsing the Platform to create materials that defame, discriminate and are offensive, including any mentions or remark made regarding religion, race, sexual orientation/gender identity, national/ethnic origin, or other groups; \n" +
    "\t•\tUsing the Service to promote prohibited activities such as illegal substances/drugs usage and adult services; \n" +
    "\t•\tGenerating spam, machine-generated or randomly assigned tasks, advertising that is unauthorized or unsolicited, chain letters;\n" +
    "\t•\tAny other form of unsecured solicitation, or any kind of lottery or gambling; \n" +
    "\t•\tUsing the Service for creating or developing viruses, malwares, trojan horses or similar material designed to cause disruption, damage, or interference with the operation of any software; \n" +
    "\t•\tInfringing on the intellectual property rights of any party, including but not limited to: patents, trademarks or trade secrets, as well as copyrights and publicity rights; \n" +
    "\t•\tImpersonating individuals, including employees and representatives of the Company;\n" +
    "\t•\tEndangering the privacy of others. \n" +
    "Unless otherwise agreed upon, the Company has the authority to reject or delete sexually explicit content and make decisions about whether it is appropriate for use or compliance with these Terms. The Company is at liberty to modify the way the Content is presented and make changes. The Company has the authority to restrict or revoke the Service if you use it to create Content that is considered offensive. The Company is not responsible for the content posted by users and/or third parties on other platforms.  ",
  termsKyc:
    "To use the Services, a user should confirm their e-mail address. However, the use of the Services is restricted for residents of the following countries: North Korea (Democratic People's Republic of Korea), Iran, and Myanmar.\n" +
    "Users with suspicious activity or residents of High-risk countries, such as Afghanistan, Albania, Barbados, Burkina Faso, Cambodia, Cayman Islands, Democratic Republic of the Congo, Gibraltar, Haiti, Jamaica, Jordan, Mali, Morocco, Mozambique, Nicaragua, Pakistan, Panama, Philippines, Russia, Senegal, South Sudan, Syria, Tanzania, Trinidad and Tobago, Turkey, Uganda, United Arab Emirates, Vanuatu, Yemen, and Zimbabwe, may be subject to additional verification (a.k.a. Know Your Customer or KYC) by providing additional data and/or documents. Such information may also be required if there is an official request by authorities or other law enforcement bodies.",
  terms6:
    "To access the Services, you must have a sufficient amount of the Platform’s virtual currency—Neurons—in your account Wallet. You may check your balance and top it up in the appropriate section of the Website. Neurons have no value outside the Platform, can only be used on the Platform, and cannot be transferred to another platform or person. 10 Neurons equal 1 Euro.\n" +
    "Transactions are processed within 72 hours of payment.After the payment is complete, it may take up to 24 hours for your balance to update, though this process is usually instant. We are entitled to use a third-party payment service processor (the “Payment Processor”) for your deposits. The respective Payment Processor is primarily responsible for facilitating payment of your transactions. To process your transaction, the Payment Processor requires your personal details and payment information, such as debit/credit card details, address, and other information. This information is only used to process the transactions and is not disclosed to any third parties, except the financial and regulatory institutions involved in the processing or compliance procedures. You can learn more about the usage of your personal data in Our Privacy Policy.\n" +
    "We reserve the right to verify and approve any and all transactions made by you when using the Service to ensure that they comply with these Terms and Conditions and the terms imposed on the Platform by third-party service providers. You expressly acknowledge and agree that such verifications may require you to provide Us with additional personal information to verify and confirm your identity and to perform verifications aimed at deterring fraud and misuse of the Service, in compliance with Our Privacy Policy and all applicable laws and regulations.\n" +
    "By proceeding with a transaction, you certify that all bank cards and other payment accounts you use for transactions are legally yours.",
  terms7:
    "The Content purchased on our website is not eligible for a refund.You are not entitled to any refunds for any fees or transaction commissions you have already paid.Refunds are only possible for transactions conducted to purchase Neurons and only in case of technical issues with the Service provided on the Website and/or other issues arising from Our errors that led to your inability to receive the Service you have paid for.Any request for a refund must be submitted personally via contact{'@'}aiaieraworld.com email within 14 days from the time of the transaction.",
  terms8:
    "Links on Our Platform may lead to third-party websites or services not owned by the Company. Third-party websites or services may be linked to the Company, but the Company is not responsible for their content, privacy policies, or practices. You acknowledge and agree that the Company cannot be held liable for any damage or loss caused by or in connection with the use of or reliance on any such content, goods, or services offered through any websites or other services. We strongly recommend that you read the terms of use and the privacy policies of any third-party websites or services which you access.",
  terms9:
    "The principle of copyright requires us to acknowledge and respect the intellectual property rights of others. It is our policy to address any claim that Content posted on the Service infringes on the intellectual property rights or copyright of others. If you believe that the Service is facilitating copyright infringement, and you are either a copyright owner or authorized on their behalf, please send us an email at contact{'@'}aiaieraworld.com to submit your notice. Please include a detailed explanation of the supposed breach in your notice. The admission of a falsehood that any Content is infringing your copyright may result in you being held accountable for damages, including costs and attorneys' fees.",
  terms10:
    "We have the authority to suspend or terminate your Account without any prior notice or liability, even if you do not violate the Terms. This applies regardless of the reason. Your use of the Service will be terminated upon deleting your account. If you want to remove your Account from the Platform, you can simply cease using the Service. This Agreement will continue in full force and effect while you are using the Services. The Company may terminate or suspend your access to the Services at any time. In the event of a breach of any of the terms and conditions of this Agreement, the Company may immediately suspend or terminate all Services and access to the Website without any liability or warning.\n" +
    "All provisions of this Agreement that remain in effect after termination are essential, including ownership provisions and disclaimers. These limitations also apply to liability limitations. The Company, its parents, subsidiaries, affiliates, and employees are fully obligated to defend against any claims, liabilities, settlements/suitable costs, including attorneys' fees or damages arising from your use of Services, violation of this Agreement, or the infringement by you or another party of your intellectual property or other rights.\n" +
    "If any provision of these Terms is deemed unenforceable or invalid, the other provisions will be modified and interpreted to achieve the intended purpose as per applicable law without being superseded. Unless otherwise agreed upon, the non-application of any right or obligation under these Terms will not affect either party's ability to enforce its compliance with this Agreement. The original English version of the Terms will be the prevailing law in any dispute.\n" +
    "These Terms are subject to change and may be amended or replaced by us at our sole discretion. You acknowledge and agree to be bound by the revised Terms if you continue to access or use Our Service after those revisions become effective. If you do not agree with the new terms, please discontinue your use of the website and the Service.\n" +
    "If you have any queries regarding these Terms and Conditions, please reach out to us at contact{'@'}aiaieraworld.com.",
};
