import { Lang } from "@/types/lang";

export default <Lang>{
  homeHeading: "Bienvenidos a AIEra!",
  homeSubtitle:
    "Experimenta el poder de la inteligencia artificial al alcance de tu mano. Nuestra plataforma ofrece herramientas fáciles de usar y funciones avanzadas para ayudarlo a construir, innovar y crecer.\n",
  homeSubtitle2:
    "Ya seas desarrollador, emprendedor o entusiasta curioso, nuestra plataforma te permite aprovechar el potencial de la IA e impulsar tus proyectos hacia el éxito. Únase a nosotros y cambie su forma de pensar sobre la tecnología.\n",
  homeHeading2: "Pruébelo ahora",
  serviceCard1:
    "Audio es un salón de la fama donde tu texto cobra vida a tus órdenes. Puedo transformar tu texto en archivos de audio sinceros y claros, dando a tus palabras un sonido real y único. \n" +
    "            Mis capacidades de voz pueden ser una herramienta para una amplia gama de tareas, desde la conversión de texto a voz hasta la creación de materiales atractivos y prácticos.",
  serviceCard2:
    "La Generate Images es un tesoro de creatividad que abre un mundo de imágenes visuales increíbles. Puedo crear imágenes gráficas que encarnan descripciones e ideas abstractas.\n" +
    "            El resultado es una armonía de formas y colores que le permite ver el mundo desde una perspectiva nueva e inspiradora.",
  serviceCard3:
    "Text Mode es una herramienta que te ofrece la oportunidad de crear textos únicos, poemas y mucho más. Estoy listo para darle un flujo inagotable de palabras que enriquecen su imaginación e inspiran su creatividad.\n" +
    "            Mis algoritmos de generación de texto están llenos de potencia que te ayudarán a crear obras asombrosas y vívidas, \n" +
    "            sumergiéndote en tus ideas.",
  textServiceHeading: "Servicios de texto",
  textServiceName: "Generador de poemas",
  textServiceHint:
    "Genera poemas a petición conservando el estilo.\n" +
    "Ejemplo de solicitud: «Escribe una historia corta sobre un mago en los tiempos modernos al estilo de Edgar Allan Poe».\n",
  imageServiceHint:
    "Genera imágenes a petición en el estilo elegido. Ejemplo de solicitud: «Robots steampunk gigantes con camisas hawaianas».",
  creativeServiceHint: "Genera 3 ideas creativas sobre un tema elegido.",
  paraphraseServiceHint: "Parafrasea el texto introducido en el estilo elegido.",
  instruction1: "Introduzca el asunto (no más de 300 caracteres)",
  instruction2: "Seleccione el estilo, el número y el tamaño de las estrofas",
  instruction3: "Haga clic en generar",
  loginWarning1: "Al hacer clic en iniciar sesión o continuar con las otras opciones siguientes, aceptas ",
  loginWarning2: "Términos y condiciones",
  loginWarning3: "y he leído el ",
  loginWarning4: "Política de Privacidad",
  newUser: "¿Eres nuevo en AI Factory? Regístrate",
  forgotPassword: "¿Ha olvidado su contraseña?",
  logoutWarning: "¿Salir de su cuenta?",
  signUpWarning: "¿Ya tiene una cuenta? Iniciar sesión",
  audioHint:
    "La conversión de texto a voz da vida a su texto cuando usted lo ordene. Puedo convertir tu texto en archivos de audio claros y sinceros, proporcionando un sonido único a tus palabras.",
  minBuyWarning: "El importe mínimo de depósito por transacción es de 10 EUR.",
  maxBuyWarning: "El importe máximo de depósito por transacción es de 1000 EUR.",
  copyright: "© 2024 Techfox Group LTD.",
  buyAgreement1: "He leído, comprendido y acepto las\n",
  buyAgreement2: "y al",
  buyAgreement3: " del sitio web",
  buyAgreement4: "Política de Privacidad",
  buyAgreement5: "Condiciones de uso",
  textServiceTitle2: "Parafraseo",
  textServiceTitle3: "Creativo",
};
