import { Lang } from "@/types/lang";

export default <Lang>{
  question1: "Che cos'è AIera?",
  question2: "Come funziona AIera?",
  question3: "Quali servizi offre la piattaforma?",
  question4: "Come si accede ai Servizi di AIera?",
  question5: "Cosa sono i “Quanties” e come posso acquisirli?",
  question6: "Come fa la Piattaforma a calcolare il costo finale di una generazione di contenuti?",
  question6_1: "Quanto tempo ci vuole per elaborare le transazioni?",
  question7: "Quali sono le applicazioni di AIera?",
  question8: "Qual è la tecnologia alla base di AIera?",
  question9: "Quali lingue può elaborare AIera?",
  question10: "Come fa la piattaforma a garantire la qualità dei contenuti generati?",
  answer1:
    "AIera è una rete neurale versatile, in grado di creare testi, rivederli, offrire idee creative e generare immagini ultra-realistiche e file audio con voci realistiche.",
  answer2:
    "AIera utilizza reti neurali all'avanguardia, in grado di analizzare e generare contenuti e, allo stesso tempo, di fornire idee e soluzioni uniche.",
  answer3:
    "AIera si compone di tre sezioni distinte: Il servizio di generazione di testi consente agli utenti di riformulare testi, creare idee innovative, poesie e altro ancora. Il servizio Text-to-Speech offre 6 voci altamente realistiche per la creazione di contenuti audio. La sezione di generazione di immagini permette agli utenti di creare qualsiasi immagine in 17 stili diversi.",
  answer4:
    "Per utilizzare i Servizi, è necessario creare un account e verificare la propria e-mail. Una volta registrati, si può procedere al Servizio desiderato, assicurandosi di avere sufficienti “Quanties” per utilizzarlo.",
  answer5:
    "Sulla nostra piattaforma è stato implementato un sistema di bilanciamento in cui i “Quanties” fungono da moneta speciale per accedere ai Servizi di AIera. Per ottenere i “Quanties”, accedere al proprio profilo e fare clic sul pulsante “Acquista Quanties”.",
  answer6:
    "Esiste un costo di base per ogni Servizio. Ad esempio, 1 generazione di immagini costa 50 “Quanties”, non ci sono opzioni aggiuntive, quindi il prezzo è statico. Per altri servizi, come Text-to-Speech, ci sono caratteristiche aggiuntive come la lingua, la dimensione del testo e il tipo di voce. Tutte queste opzioni aggiuntive fanno lievitare il prezzo e ci siamo assicurati che il costo finale sia visualizzato correttamente prima che l'utente spenda i suoi “Quanties”.",
  answer6_1:
    "In genere le transazioni vengono elaborate entro 72 ore dal pagamento. Una volta completato il pagamento, potrebbero essere necessarie fino a 24 ore per aggiornare il saldo del conto, anche se di solito il processo è istantaneo.",
  answer7:
    "AIera può essere utilizzata per generare testi di qualità, aumentare e riscrivere contenuti esistenti, fornire idee creative, creare immagini realistiche e file audio.",
  answer8:
    "AIera si basa su tecnologie avanzate di intelligenza artificiale, tra cui reti neurali e deep learning, e utilizza tecniche di generazione e trasformazione dei contenuti.",
  answer9:
    "AIera è in grado di elaborare testi in molte lingue, tra cui, ma non solo, l'inglese, l'olandese, lo spagnolo, il francese, il tedesco, l'italiano e altre lingue popolari.",
  answer10:
    "AIera utilizza modelli e algoritmi avanzati, addestrati su grandi quantità di dati e dotati di meccanismi di controllo della qualità per assicurare la generazione di contenuti di alta qualità, anche se non possiamo garantire risultati accurati al 100%.",
};
