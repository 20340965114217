import { Lang } from "@/types/lang";

export default <Lang>{
  question1: "¿Qué es AIera?",
  question2: "¿Cómo funciona AIera?",
  question3: "¿Qué servicios ofrece la plataforma?",
  question4: "¿Cómo puedo acceder a los Servicios de AIera?",
  question5: "¿Qué son los «Quanties» y cómo puedo adquirirlos?",
  question6: "¿Cómo calcula la Plataforma el coste final de una generación de contenidos?",
  question6_1: "¿Cuánto tardan en procesarse las transacciones?",
  question7: "¿Qué aplicaciones tiene AIera?",
  question8: "¿Cuál es la tecnología de AIera?",
  question9: "¿Qué idiomas puede procesar AIera?",
  question10: "¿Cómo garantiza la Plataforma la calidad de los contenidos generados?",
  answer1:
    "AIera es una red neuronal versátil, capaz de crear textos, revisarlos, ofrecer ideas inventivas y generar imágenes ultrarrealistas y archivos de audio con voces realistas.",
  answer2:
    "AIera utiliza redes neuronales de última generación capaces de analizar y generar contenidos, al tiempo que son lo suficientemente creativas como para aportar ideas y soluciones únicas.",
  answer3:
    "AIera consta de tres secciones distintas: El servicio de generación de textos permite a los usuarios reformular textos, crear ideas innovadoras, poemas, etc. El servicio de conversión de texto en voz ofrece 6 voces de gran realismo para crear contenidos de audio. La sección de generación de imágenes permite a los usuarios crear cualquier imagen en 17 estilos diferentes.",
  answer4:
    "Para utilizar los Servicios, debe crear una cuenta y verificar su correo electrónico. Una vez registrado, podrá acceder al Servicio deseado, asegurándose de que dispone de «Cuantías» suficientes para utilizarlos.",
  answer5:
    "En nuestra plataforma se ha implantado un sistema de saldo en el que los «Quanties» sirven como moneda especial para acceder a los Servicios de AIera. Para obtener «Quanties», vaya a su perfil y haga clic en el botón «Comprar Quanties».",
  answer6:
    "Existe un coste básico para cada Servicio. Por ejemplo, 1 generación de imagen cuesta 50 «Cuantias», no hay opciones adicionales, por lo que el precio es estático. Para otros servicios, como el de texto a voz, hay opciones adicionales como el idioma, el tamaño del texto y el tipo de voz. Todas estas opciones adicionales escalan el precio y nos aseguramos de que el coste final se muestre correctamente antes de que un usuario gaste sus «Cuantidades».",
  answer6_1:
    "Normalmente, las transacciones se procesan en un plazo de 72 horas tras el pago. Una vez completado el pago, el saldo de tu cuenta puede tardar hasta 24 horas en actualizarse, aunque el proceso suele ser instantáneo.",
  answer7:
    "AIera puede utilizarse para generar textos de calidad, aumentar y reescribir contenidos existentes, aportar ideas creativas, crear imágenes realistas y archivos de audio.",
  answer8:
    "AIera se basa en tecnologías avanzadas de inteligencia artificial, incluidas redes neuronales y aprendizaje profundo, y utiliza técnicas de generación y transformación de contenidos.",
  answer9:
    "AIera puede procesar texto en muchos idiomas, incluidos, entre otros, inglés, neerlandés, español, francés, alemán, italiano y otros idiomas populares.",
  answer10:
    "AIera utiliza modelos y algoritmos avanzados que se entrenan con grandes cantidades de datos y están equipados con mecanismos de control de calidad para garantizar que se generen contenidos de alta calidad, aunque no podemos garantizar resultados precisos al 100%.",
};
