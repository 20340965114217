import { Lang } from "@/types/lang";

export default <Lang>{
  question1: "Wat is AIera?",
  question2: "Hoe werkt AIera?",
  question3: "Welke diensten biedt het platform?",
  question4: "Hoe krijg ik toegang tot de diensten van AIera?",
  question5: "Wat zijn “Quanties” en hoe kan ik ze verkrijgen?",
  question6: "Hoe berekent het platform de uiteindelijke kosten van een contentgeneratie?",
  question6_1: "Hoe lang duurt het voordat transacties worden verwerkt?",
  question7: "Welke toepassingen heeft AIera?",
  question8: "Wat is de technologie achter AIera?",
  question9: "Welke talen kan AIera verwerken?",
  question10: "Hoe waarborgt het platform de kwaliteit van de gegenereerde content?",
  answer1:
    "AIera is een veelzijdig neuraal netwerk dat teksten kan maken, ze kan reviseren, inventieve ideeën kan aandragen en ultrarealistische afbeeldingen en audiobestanden met levensechte stemmen kan genereren.",
  answer2:
    "AIera maakt gebruik van geavanceerde neurale netwerken die bedreven zijn in het analyseren en genereren van inhoud, terwijl ze creatief genoeg zijn om unieke ideeën en oplossingen te bieden.",
  answer3:
    "AIera bestaat uit drie verschillende secties: De tekstgeneratieservice stelt gebruikers in staat om tekst te herformuleren, innovatieve ideeën, gedichten en meer te creëren. De tekst-naar-spraakservice biedt 6 zeer realistische stemmen voor het creëren van audio-inhoud. Met de sectie Afbeelding genereren kunnen gebruikers elke afbeelding in 17 verschillende stijlen maken.",
  answer4:
    "Om de diensten te gebruiken, moet u een account aanmaken en uw e-mailadres verifiëren. Zodra u geregistreerd bent, kunt u naar de gewenste dienst gaan en ervoor zorgen dat u voldoende “Quanties” hebt om ze te gebruiken.",
  answer5:
    "Er is een balanssysteem geïmplementeerd op ons platform waarbij “Quanties” dienen als speciale valuta om toegang te krijgen tot AIera's diensten. Om “Quanties” te krijgen, ga naar je profiel en klik op de “Koop Quanties” knop.",
  answer6:
    "Er is een basiskost voor elke dienst. Bijvoorbeeld, 1 beeldgeneratie kost 50 “Quanties”, er zijn geen extra opties, dus de prijs is statisch. Voor andere diensten, zoals tekst-naar-spraak, zijn er extra opties zoals taal, tekstgrootte en stemtype. Al deze extra opties schalen de prijs en we hebben ervoor gezorgd dat de uiteindelijke kosten goed worden weergegeven voordat een gebruiker zijn “Quanties” uitgeeft.",
  answer6_1:
    "Transacties worden meestal binnen 72 uur na betaling verwerkt. Nadat de betaling is voltooid, kan het tot 24 uur duren voordat uw rekeningsaldo is bijgewerkt, maar het proces verloopt meestal onmiddellijk.",
  answer7:
    "AIera kan worden gebruikt om kwaliteitstekst te genereren, bestaande inhoud te vergroten en te herschrijven, creatieve ideeën aan te dragen en realistische afbeeldingen en audiobestanden te creëren.",
  answer8:
    "AIera is gebaseerd op geavanceerde technologieën voor kunstmatige intelligentie, waaronder neurale netwerken en deep learning, en maakt gebruik van technieken voor het genereren en transformeren van content.",
  answer9:
    "AIera kan tekst verwerken in vele talen, inclusief maar niet beperkt tot Engels, Nederlands, Spaans, Frans, Duits, Italiaans en andere populaire talen.",
  answer10:
    "AIera maakt gebruik van geavanceerde modellen en algoritmen die zijn getraind op grote hoeveelheden gegevens en zijn uitgerust met kwaliteitscontrolemechanismen om ervoor te zorgen dat inhoud van hoge kwaliteit wordt gegenereerd, hoewel we geen 100% nauwkeurige resultaten kunnen garanderen.",
};
