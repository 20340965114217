import { Lang } from "@/types/lang";

export default <Lang>{
  termsHeading1: "Condiciones de uso",
  termsHeading2: "Condiciones de uso",
  termsHeading3: "Garantía y responsabilidad",
  termsHeading4: "El Contenido",
  termsHeading5: "Contenido restringido",
  termsHeading6: "Procedimiento de pago",
  termsHeading7: "Política de reembolso",
  termsHeading8: "Servicios de terceros",
  termsHeading9: "Derechos de autor",
  termsHeading10: "Rescisión",
  termsHeadingKyc: "Verificación KYC",

  terms1:
    'Antes de utilizar los Servicios prestados por AI Era (la "Empresa", "Nosotros", "Nos", "la Plataforma", el/los "Servicio(s)" o "el Sitio Web"), se recomienda que lea los siguientes Términos y Condiciones ("Acuerdo"). Al acceder al sitio web o a los servicios de cualquier forma, usted reconoce que ha leído, entendido y aceptado los términos y condiciones del presente Acuerdo y se compromete a cumplir todas sus disposiciones. Si no está de acuerdo con todos los términos y condiciones del presente Acuerdo, deberá interrumpir inmediatamente cualquier uso de la Plataforma o de los Servicios. El uso de los Servicios de la Empresa está condicionado a su aceptación de estas Condiciones. Si no está de acuerdo con estas Condiciones, le rogamos que NO utilice el Sitio Web.\n' +
    "AI Era World es propiedad y está gestionado por (Empresa).\n" +
    "(Empresa) está regulada por las leyes de (Jurisdicción) como sociedad de responsabilidad limitada, con domicilio social en (Dirección)\n" +
    "Número de teléfono  Correo electrónico: contact{'@'}aieraworld.com",
  terms2:
    "Usted nos garantiza que \n" +
    "- Es mayor de edad en su país de residencia; \n" +
    "- Tiene capacidad para celebrar un contrato vinculante y no tiene prohibido por ley utilizar o acceder a los Servicios; \n" +
    "- Que la información que nos facilita es exacta y veraz; \n" +
    "- Usted está legalmente autorizado a utilizar cualquier método de pago, incluyendo tarjeta de crédito o débito, para la realización de cualquier pedido (al proporcionar dicha información, usted reconoce y nos autoriza a compartir la información con terceros que procesan pagos para facilitar la realización de su pedido. Si sospechamos que se ha producido un fraude o una transacción no autorizada o ilegal, nos reservamos el derecho a rechazar o cancelar su pedido en cualquier momento por determinados motivos, incluida la falta de disponibilidad del Servicio, errores técnicos o precios incorrectos).\n" +
    "En las jurisdicciones en las que la ley lo prohíba, el presente Acuerdo perderá su validez y se revocará el derecho de acceso a los Servicios.",
  terms3:
    "La Plataforma se actualiza continuamente en relación con la disponibilidad, errores e inexactitudes de Nuestros Servicios. Nuestra Plataforma puede tener Servicios con precios inexactos, obsoletos o no disponibles en todo momento, y Nosotros podemos experimentar retrasos en la actualización de la información sobre nuestro Servicio en la Plataforma y en la publicidad en otros sitios web.\n" +
    "Nuestra garantía no cubre toda la información posible, incluidos los precios y las imágenes de los productos. Las especificaciones, disponibilidad o servicios pueden diferir, y Nosotros tenemos la autoridad para modificar o revisar la información sin previo aviso, incluyendo cualquier error, inexactitud u omisión. La Empresa se reserva el derecho de modificar sus precios en cualquier momento antes de recibir un Pedido.\n" +
    "Tanto si utiliza una tarjeta de crédito como de débito, el pago puede efectuarse con la tarjeta que se le haya emitido, y el emisor de la tarjeta se encargará de validarla. Si no pudiéramos obtener la autorización necesaria, no nos responsabilizaremos de ningún retraso o falta de entrega de su pedido.\n" +
    "Las promociones disponibles a través del Servicio pueden estar sujetas a normas distintas de las presentes Condiciones. Para garantizar el cumplimiento de la normativa aplicable y de nuestra Política de Privacidad, le recomendamos que lea estas normas antes de considerar cualquier promoción. Las condiciones de una promoción estarán sujetas a las mismas normas que estas Condiciones si no se cumple alguna de ellas.\n" +
    "Si crea una cuenta con Nosotros, debe proporcionar información precisa, completa y actualizada para crear su cuenta. El incumplimiento de esta obligación puede dar lugar a la cancelación inmediata de su cuenta en la Plataforma, ya que se considera un incumplimiento de las Condiciones.\n" +
    "Es su responsabilidad mantener la contraseña que utiliza para acceder al Servicio y a cualquier actividad relacionada con su contraseña, ya sea con Nuestro Servicio o con un Servicio de Medios Sociales de Terceros. Está obligado a mantener la confidencialidad cuando comparta su contraseña con otras personas. Si descubre que su cuenta ha sido violada o utilizada con fines ilegales, deberá notificárnoslo inmediatamente.\n" +
    "Nuestro servicio está limitado a una cuenta de usuario por usuario, y si infringe nuestras Condiciones o crea varias cuentas, podremos eliminar sus datos y restringir el acceso. Le concedemos la facultad de producir o transmitir contenidos de audio y vídeo a través de Nuestro Servicio. Es su responsabilidad asegurarse de que el Contenido creado utilizando Nuestro(s) Servicio(s) cumple(n) los requisitos legales, incluyendo su corrección y adecuación. El Servicio le otorga el derecho y la licencia para utilizar, modificar, ejecutar, exhibir, copiar (en su totalidad), reproducir y distribuir Contenido creado a través de su creación y publicación en el mismo. El Servicio le otorga derechos completos sobre todo el Contenido, lo que significa que usted es responsable de proteger y conservar dichos derechos.\n" +
    "Usted utiliza el Servicio bajo su propia responsabilidad. Usted reconoce que el Servicio puede contener contenido que usted pueda considerar ofensivo, indecente, incorrecto o censurable, y acepta que no existe responsabilidad alguna por los errores o daños resultantes de su uso de nuestra Plataforma. Usted es el único responsable de todas sus acciones relacionadas con los Servicios. Si incurre en conductas fraudulentas, abusivas o ilegales mientras utiliza los Servicios, sus derechos de acceso o uso podrían ser cancelados. Se le prohíbe publicar o transmitir cualquier mensaje o solicitud destinada a obtener contraseñas, cuentas o información privada de otros usuarios de la Plataforma. Queda expresamente prohibido utilizar los Servicios para vulnerar la seguridad de cualquier red informática, descifrar contraseñas o códigos de cifrado de seguridad, o transferir o almacenar material ilegal (incluidos contenidos potencialmente dañinos o ilegales) o participar en cualquier actividad ilegal.\n" +
    "El código fuente de la Plataforma no podrá descompilarse, someterse a ingeniería inversa ni obtenerse por ningún otro medio. Usted es responsable de retener, declarar y notificar todos los impuestos, tasas y otros gravámenes gubernamentales relacionados con su actividad en relación con los Servicios. La Empresa realiza copias de seguridad periódicas de los Contenidos, pero no garantiza la ausencia de pérdida o corrupción de datos. Los puntos de copia de seguridad pueden no ser válidos o estar dañados si el Contenido estaba dañado antes de realizar la copia de seguridad o si se modificó durante el proceso de copia de seguridad, sin limitación. La Empresa hará todo lo posible por resolver cualquier problema que pueda surgir, ya sea conocido o descubierto, que afecte a las copias de seguridad de los Contenidos. No obstante, la Empresa no se hace responsable de la integridad de los Contenidos ni de su imposibilidad de restaurarlos con éxito a un estado utilizable.",
  terms4:
    "Nuestro Servicio le permite generar Contenido de Audio, Texto e Imagen. Usted es responsable del Contenido que genere utilizando Nuestros Servicios, incluida su legalidad e idoneidad. Al generar Contenido en la Plataforma, nos concede el derecho y la licencia para utilizar, modificar, ejecutar públicamente, mostrar públicamente, reproducir y distribuir dicho Contenido en y a través del Servicio. Usted conserva todos y cada uno de sus derechos sobre cualquier Contenido que cree, publique o muestre en o a través del Servicio, y es responsable de proteger dichos derechos.\n" +
    "El uso de nuestros Servicios implica que usted entiende que la Empresa no puede garantizar que el contenido generado sea 100% correcto o de la mejor calidad o totalmente veraz y, por lo tanto, no estará sujeto a ningún reembolso o consulta sobre la calidad final del contenido.",
  terms5:
    "Usted es consciente y acepta que es el único responsable del Contenido, así como de todas las actividades asociadas a su cuenta, ya sean realizadas por usted o por otro usuario con acceso a su cuenta. \n" +
    "Se le prohíbe crear o transmitir cualquier Contenido que sea ilegal, ofensivo, vergonzoso, que pretenda ser repugnante, amenazador, difamatorio, indecente, ilegal u ofensivo de cualquier otro modo. La siguiente lista de Contenidos puede considerarse restringida, pero no se limita a: \n" +
    "- Participar en una actividad ilegal o fomentar dicho comportamiento ilegal; \n" +
    "- Utilizar la Plataforma para crear materiales difamatorios, discriminatorios y ofensivos, incluyendo cualquier mención u observación relativa a religión, raza, orientación sexual/identidad de género, origen nacional/étnico u otros grupos; \n" +
    "- Utilizar el Servicio para promocionar actividades prohibidas, como el consumo de sustancias/drogas ilegales y servicios para adultos; \n" +
    "- Generar spam, tareas generadas por máquinas o asignadas al azar, publicidad no autorizada o no solicitada, cartas en cadena;\n" +
    "- Cualquier otra forma de solicitación no segura, o cualquier tipo de lotería o juego; \n" +
    "- Utilizar el Servicio para crear o desarrollar virus, malwares, troyanos o material similar diseñado para causar interrupciones, daños o interferencias en el funcionamiento de cualquier software; \n" +
    "- Infringir los derechos de propiedad intelectual de cualquier parte, incluidos, entre otros: patentes, marcas comerciales o secretos comerciales, así como derechos de autor y derechos de publicidad; \n" +
    "- Hacerse pasar por otras personas, incluidos empleados y representantes de la Empresa;\n" +
    "- Poner en peligro la privacidad de otras personas. \n" +
    "A menos que se acuerde lo contrario, la Empresa tiene autoridad para rechazar o eliminar contenidos sexualmente explícitos y tomar decisiones sobre si son apropiados para su uso o si cumplen estas Condiciones. La Empresa tiene libertad para modificar la forma en que se presenta el Contenido y realizar cambios. La Empresa está facultada para restringir o revocar el Servicio si usted lo utiliza para crear Contenido que se considere ofensivo. La Empresa no se hace responsable de los contenidos publicados por usuarios y/o terceros en otras plataformas.  \n",
  termsKyc:
    "Para utilizar los Servicios, el usuario debe confirmar su dirección de correo electrónico. Sin embargo, el uso de los Servicios está restringido para los residentes de los siguientes países: Corea del Norte (República Popular Democrática de Corea), Irán y Myanmar.\n" +
    "Usuarios con actividad sospechosa o residentes en países de alto riesgo, como Afganistán, Albania, Barbados, Burkina Faso, Camboya, Islas Caimán, República Democrática del Congo, Gibraltar, Haití, Jamaica, Jordania, Mali, Marruecos, Mozambique, Nicaragua, Pakistán, Panamá, Filipinas, Rusia, Senegal, Sudán del Sur, Siria, Tanzania, Trinidad y Tobago, Turquía, Uganda, Emiratos Árabes Unidos, Vanuatu, Yemen y Zimbabue, pueden estar sujetos a una verificación adicional (a.k.a. Know Your Customer o KYC) mediante la aportación de datos y/o documentos adicionales. Dicha información también puede ser requerida si existe una solicitud oficial por parte de las autoridades u otros organismos encargados de hacer cumplir la ley.",
  terms6:
    "Para acceder a los Servicios, debe disponer de una cantidad suficiente de la moneda virtual de la Plataforma -Neurons- en la Cartera de su cuenta. Puede comprobar su saldo y recargarlo en la sección correspondiente del Sitio Web. Las Neuronas no tienen valor fuera de la Plataforma, sólo pueden utilizarse en la Plataforma y no pueden transferirse a otra plataforma o persona. 10 Neuronas equivalen a 1 Euro.\n" +
    'Las transacciones se procesan en las 72 horas siguientes al pago. Una vez completado el pago, su saldo puede tardar hasta 24 horas en actualizarse, aunque este proceso suele ser instantáneo. Tenemos derecho a utilizar un procesador de servicios de pago externo (el "Procesador de Pagos") para sus depósitos. El procesador de pagos correspondiente es el principal responsable de facilitar el pago de sus transacciones. Para procesar su transacción, el procesador de pagos necesita sus datos personales e información de pago, como datos de la tarjeta de débito/crédito, dirección y otra información. Esta información sólo se utiliza para procesar las transacciones y no se revela a terceros, excepto a las instituciones financieras y reguladoras implicadas en los procedimientos de procesamiento o cumplimiento. Puede obtener más información sobre el uso de sus datos personales en Nuestra política de privacidad.\n' +
    "Nos reservamos el derecho a verificar y aprobar todas y cada una de las transacciones realizadas por usted al utilizar el Servicio para asegurarnos de que cumplen las presentes Condiciones Generales y las condiciones impuestas a la Plataforma por terceros proveedores de servicios. Usted reconoce y acepta expresamente que dichas verificaciones pueden requerir que nos proporcione información personal adicional para verificar y confirmar su identidad y realizar verificaciones destinadas a disuadir el fraude y el uso indebido del Servicio, de conformidad con Nuestra Política de Privacidad y todas las leyes y reglamentos aplicables.\n" +
    "Al proceder a una transacción, usted certifica que todas las tarjetas bancarias y demás cuentas de pago que utiliza para las transacciones son legalmente suyas.",
  terms7:
    "El Contenido adquirido en nuestro sitio web no es susceptible de reembolso. Usted no tiene derecho a ningún reembolso por las tasas o comisiones de transacción que ya haya pagado. Los reembolsos sólo son posibles para las transacciones realizadas para comprar Neuronas y sólo en caso de problemas técnicos con el Servicio prestado en el Sitio Web y/u otros problemas derivados de errores Nuestros que le hayan impedido recibir el Servicio por el que ha pagado.Cualquier solicitud de reembolso debe enviarse personalmente a través del correo electrónico contact{'@'}aiaieraworld.com en un plazo de 14 días desde el momento de la transacción.",
  terms8:
    "Los enlaces de nuestra Plataforma pueden conducir a sitios web o servicios de terceros que no son propiedad de la Empresa. Los sitios web o servicios de terceros pueden estar vinculados a la Empresa, pero ésta no es responsable de su contenido, políticas de privacidad o prácticas. Usted reconoce y acepta que la Empresa no será responsable de ningún daño o pérdida causados por o en relación con el uso o la confianza depositada en dichos contenidos, bienes o servicios ofrecidos a través de sitios web u otros servicios. Le recomendamos encarecidamente que lea las condiciones de uso y las políticas de privacidad de los sitios web o servicios de terceros a los que acceda.",
  terms9:
    "El principio de los derechos de autor nos obliga a reconocer y respetar los derechos de propiedad intelectual de terceros. Nuestra política es atender cualquier reclamación de que el Contenido publicado en el Servicio infringe los derechos de propiedad intelectual o los derechos de autor de terceros. Si cree que el Servicio está facilitando la infracción de derechos de autor, y usted es el propietario de los derechos o está autorizado en su nombre, envíenos un correo electrónico a contact{'@'}aiaieraworld.com para enviar su notificación. Por favor, incluya en su notificación una explicación detallada de la supuesta infracción. La admisión de la falsedad de que algún Contenido infringe sus derechos de autor puede dar lugar a que se le exija responsabilidad por daños y perjuicios, incluidos costes y honorarios de abogados.",
  terms10:
    "Estamos facultados para suspender o cancelar su Cuenta sin previo aviso ni responsabilidad alguna, aunque no infrinja las Condiciones. Esto se aplica independientemente del motivo. Su uso del Servicio finalizará cuando elimine su cuenta. Si desea eliminar su Cuenta de la Plataforma, puede simplemente dejar de utilizar el Servicio. El presente Acuerdo continuará en pleno vigor y efecto mientras esté utilizando los Servicios. La Empresa podrá dar por terminado o suspender su acceso a los Servicios en cualquier momento. En caso de incumplimiento de cualquiera de los términos y condiciones del presente Acuerdo, la Empresa podrá suspender o cancelar inmediatamente todos los Servicios y el acceso al Sitio Web sin ninguna responsabilidad o advertencia.\n" +
    "Todas las disposiciones del presente Acuerdo que sigan vigentes tras la rescisión son esenciales, incluidas las disposiciones sobre propiedad y las cláusulas de exención de responsabilidad. Estas limitaciones también se aplican a las limitaciones de responsabilidad. La Empresa, sus empresas matrices, subsidiarias, filiales y empleados están totalmente obligados a defenderse de cualquier reclamación, responsabilidad, liquidación/coste de indemnización, incluidos los honorarios de abogados o daños y perjuicios derivados del uso que usted haga de los Servicios, de la violación del presente Contrato o de la infracción por su parte o por parte de terceros de sus derechos de propiedad intelectual o de otro tipo.\n" +
    "Si alguna de las disposiciones de las presentes Condiciones se considerara inaplicable o inválida, las demás disposiciones se modificarán e interpretarán para alcanzar el fin perseguido de conformidad con la legislación aplicable, sin que queden anuladas. A menos que se acuerde lo contrario, la inaplicación de cualquier derecho u obligación en virtud de estas Condiciones no afectará a la capacidad de cualquiera de las partes para exigir el cumplimiento del presente Acuerdo. La versión original en inglés de las Condiciones será la que prevalezca en caso de litigio.\n" +
    "Estas Condiciones están sujetas a cambios y pueden ser modificadas o sustituidas por nosotros a nuestra entera discreción. Usted reconoce y acepta quedar vinculado por las Condiciones revisadas si continúa accediendo o utilizando nuestro Servicio después de que dichas revisiones entren en vigor. Si no está de acuerdo con las nuevas condiciones, le rogamos que deje de utilizar el sitio web y el Servicio.\n" +
    "Si tiene alguna duda sobre estas Condiciones, póngase en contacto con nosotros en contact{'@'}aiaieraworld.com.",
};
