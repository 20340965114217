import { Lang } from "@/types/lang";

export default <Lang>{
  privacyHeading1: "Privacybeleid",
  privacyHeading2: "Het doel van het verzamelen van uw informatie",
  privacyHeading3: "Cookies",
  privacyHeading4: "Openbaarmaking van gegevens",
  privacyHeading5: "Bewaarperiode",
  privacyHeading6: "Gegevensbescherming",
  privacyHeading7: "GDPR",
  privacyHeading8: "Koppelingen naar websites van derden",
  privacy1:
    "AI Era hecht veel waarde aan de vertrouwelijkheid van onze klanten. Dit privacybeleid beschrijft hoe Ons Platform uw persoonlijke informatie of gebruiksinformatie verzamelt, gebruikt, deelt, opslaat, beschermt en openbaar maakt.\n" +
    "Door onze services te gebruiken, accepteert u en stemt u in met dit privacybeleid. Als u dit privacybeleid niet naleeft, dient u het gebruik van onze diensten onmiddellijk te staken.\n" +
    "(Bedrijf) is gereguleerd onder de wetten (Land) als een besloten vennootschap met beperkte aansprakelijkheid, met haar statutaire zetel te (adres). Registratienummer:  Telefoonnummer: + E-mail: contact{'@'}aieraworld.com",
  privacy2:
    "De doeleinden waarvoor we uw informatie verzamelen staan hieronder vermeld en omvatten, maar zijn niet beperkt tot:\n" +
    "\t•\tJe account registreren en beheren;\n" +
    "\t•\tRechtstreeks met u communiceren;\n" +
    "\t•\tInformatie over ons verstrekken, recensies, forumberichten of inhoud publiceren; \n" +
    "\t•\tReageren op je vragen;\n" +
    "\t•\tVoldoen aan toepasselijke wettelijke bepalingen.\n" +
    "Wij verzamelen informatie over uw computer of mobiele apparaat terwijl u Onze Services gebruikt en gebruiken deze om uw gegevens te analyseren. De gegevens die we verzamelen omvatten IP-adressen van computers, ID's van mobiele apparaten en technische details over uw computer of mobiele apparaat.",
  privacy3:
    '"Cookies" zijn kleine bestanden die zijn opgeslagen op uw computer of mobiele apparaat waarmee wij het gebruik van onze diensten kunnen volgen en kunnen vaststellen wanneer u de website bezoekt.\n' +
    "Het aanpassen van de voorkeuren van uw browser of mobiele apparaat kan voorkomen dat cookies worden geaccepteerd op uw computer of apparaat, wat het gebruik van bepaalde functies op Ons Platform kan belemmeren. U erkent en gaat ermee akkoord dat dit Privacybeleid geen betrekking heeft op het gebruik van cookies door derden.",
  privacy4:
    "Ons beleid verbiedt de overdracht van uw Persoonlijke gegevens aan derden om geld te verdienen door middel van advertenties of verkoop. Wij kunnen uw Persoonlijke Gegevens echter wel delen met derden die diensten leveren zoals bedrijfsanalyses, klantenservice, marketing, distributie en betalingsverwerking. Leveranciers kunnen gegevens namens ons verzamelen om functies van applicaties te beheren of aangepaste services te leveren. De informatie die door externe leveranciers wordt verstrekt, is uitsluitend voor hun eigen doeleinden en kan niet voor andere doeleinden worden gedeeld of geëxploiteerd.\n" +
    "Wij kunnen uw Persoonsgegevens en Gebruiksgegevens bekendmaken aan overheidsinstanties wanneer dit vereist is, zoals in reactie op een gerechtelijk bevel of een eis van de overheid. Als Wij aan een wettelijk verzoek moeten voldoen, wordt er een e-mailbericht naar uw Postvak IN gestuurd.",
  privacy5:
    "De bewaartermijn van uw persoonsgegevens wordt bepaald door het Bedrijf op basis van de tijd die nodig is om te voldoen aan dit Privacybeleid en kan variëren. Wij bewaren en gebruiken uw gegevens voor zover dat nodig is om aan onze wettelijke verplichtingen te voldoen, geschillen op te lossen en onze overeenkomsten en beleidsregels af te dwingen. Doorgaans worden gegevens één jaar bewaard sinds de laatste verstrekking (of bijwerking) van gegevens door de gebruiker.",
  privacy6:
    "We willen dat je ons vertrouwt en dat je erop kunt vertrouwen dat je informatie beschermd is. Wij houden ons aan algemeen aanvaarde standaard veiligheidsmaatregelen voor Persoonlijke Gegevens en Gebruiksgegevens. Hoewel geen enkele Dienst volledige veiligheid kan garanderen, hebben Wij de nodige administratieve, technische en veiligheidsmaatregelen getroffen om de bescherming van uw gegevens te waarborgen. Geautoriseerd personeel, agenten, externe verkopers en andere entiteiten gebruiken Onze gegevens alleen voor de vereiste doeleinden. We gebruiken geschikte firewalls om deze te beveiligen tegen ongeautoriseerd gebruik, evenals encryptietechnologieën en inbraakdetectiesystemen.\n" +
    "The Company zal alle nodige stappen ondernemen om ervoor te zorgen dat uw gegevens veilig en in overeenstemming met dit Privacybeleid worden behandeld. Er worden internationaal geen Persoonsgegevens overgedragen, tenzij er adequate maatregelen zijn genomen om uw gegevens en andere informatie te beschermen.",
  privacy7:
    "Wij voldoen aan de General Data Protection Regulation (GDPR) met betrekking tot de verwerking van uw Persoonsgegevens. Wij kunnen Persoonsgegevens verwerken onder de volgende voorwaarden:\n" +
    "\t•\tU hebt toestemming gegeven voor het verwerken van Persoonsgegevens voor een of meer specifieke doeleinden.\n" +
    "\t•\tNaleving van een wettelijke verplichting waaraan het bedrijf is onderworpen.\n" +
    "\t•\tVitale belangen: Het verwerken van Persoonsgegevens is noodzakelijk om uw vitale belangen of die van een andere natuurlijke persoon te beschermen.\n" +
    "\t•\tOpenbare belangen: De verwerking van Persoonsgegevens houdt verband met een taak die wordt uitgevoerd in het algemeen belang of in het kader van de uitoefening van het openbaar gezag dat aan het Bedrijf is opgedragen.\n" +
    "\t•\tGerechtvaardigde belangen: Het verwerken van Persoonsgegevens is noodzakelijk met het oog op de legitieme belangen die het Bedrijf nastreeft.\n" +
    "In elke situatie zal het Bedrijf graag helpen bij het verduidelijken van de exacte rechtsgrond die van toepassing is op de verwerking, inclusief of het verstrekken van Persoonsgegevens een wettelijke of contractuele noodzaak is, of een voorwaarde om een contract aan te gaan.\n" +
    "Het Bedrijf verbindt zich ertoe de vertrouwelijkheid van Uw Persoonsgegevens te respecteren en te garanderen dat U uw rechten kunt uitoefenen. U hebt het recht onder dit Privacybeleid en volgens de wet om:\n" +
    "\t•\tToegang vragen tot uw Persoonlijke gegevens;\n" +
    "\t•\tVerzoek om correctie van de Persoonsgegevens die wij over u bewaren;\n" +
    "\t•\tBezwaar maken tegen de verwerking van uw Persoonsgegevens;\n" +
    "\t•\tVerzoek om verwijdering van uw Persoonsgegevens. U hebt het recht om ons te vragen Persoonsgegevens te wissen of te verwijderen wanneer er voor ons geen goede reden is om deze te blijven verwerken;\n" +
    "\t•\tDe overdracht van uw Persoonlijke gegevens aanvragen;\n" +
    "\t•\tUw toestemming voor het verwerken van persoonlijke gegevens intrekken.",
  privacy8:
    "Ons Platform kan links bevatten naar andere websites die niet door het Bedrijf worden beheerd. Als u op een link van een derde klikt, wordt u naar de website van die derde geleid. Wij raden u ten zeerste aan om het privacybeleid van elke website die u bezoekt door te nemen.\n" +
    "We hebben geen controle over en zijn niet verantwoordelijk voor de inhoud, het privacybeleid of de praktijken van websites of diensten van derden.\n" +
    "Dit Privacybeleid kan op elk moment worden bijgewerkt of gewijzigd zonder voorafgaande kennisgeving, dus we raden u aan deze pagina af en toe te controleren. Als je vragen hebt over dit Privacybeleid, kun je contact met ons opnemen via contact{'@'}aieraworld.com.",
};
