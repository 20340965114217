import { Lang } from "@/types/lang";

export default <Lang>{
  homeHeading: "Benvenuti in AIEra!\n",
  homeSubtitle:
    "Sperimenta la potenza dell'intelligenza artificiale a portata di mano. La nostra piattaforma offre strumenti facili da usare e funzionalità avanzate per aiutarti a costruire, innovare e crescere.",
  homeSubtitle2:
    "Che tu sia uno sviluppatore, un imprenditore o un appassionato curioso, la nostra piattaforma ti consente di sfruttare il potenziale dell'intelligenza artificiale e portare i tuoi progetti al successo. Unisciti a noi e cambia il modo in cui pensi alla tecnologia.",
  homeHeading2: "Provalo adesso",
  serviceCard1:
    "Audio è una galleria della fama in cui il tuo testo prende vita al tuo comando. Posso trasformare il tuo testo in file audio sinceri e chiari, dando alle tue parole un suono reale e unico. \n" +
    " Le mie capacità vocali possono essere uno strumento per una vasta gamma di attività, dalla sintesi vocale alla creazione di materiale coinvolgente e pratico.",
  serviceCard2:
    "Generate Images è uno scrigno pieno di creatività che apre un mondo di immagini visive incredibili. Posso creare immagini grafiche che incarnano descrizioni e idee astratte. \n" +
    " I risultati mostrano un'armonia di forme e colori, consentendoti di vedere il tuo mondo da una prospettiva nuova e inspiratrice.",
  serviceCard3:
    "Text Mode è uno strumento che ti offre l'opportunità di creare testi, poesie e altro unici. Sono pronto a offrirti un flusso inesauribile di parole che arricchiranno la tua immaginazione e ispireranno la tua creatività. \n" +
    " I miei algoritmi di generazione del testo sono pieni di potenza che ti aiuterà a creare opere straordinarie e vivide, immergendoti nelle tue idee.",
  textServiceHeading: "Text Services",
  // textServiceName: "Generating Poems",
  textServiceName: "Generatore di poesie",
  textServiceHint:
    "Genera poesie su richiesta preservandone lo stile.\n" +
    "Esempio di richiesta: “Scrivi un breve racconto su un mago in epoca moderna nello stile di Edgar Allan Poe”.\n",
  imageServiceHint:
    'Genera immagini su richiesta nello stile scelto. Esempio di richiesta: "Girasole \n' +
    '                su un tavolo nella giungla."',
  creativeServiceHint: "Genera 3 idee creative su un argomento scelto.",
  paraphraseServiceHint: "Parafrasa il testo inserito nello stile scelto.",
  instruction1: "Inserire l'oggetto (non più di 300 caratteri)",
  instruction2: "Selezionare lo stile, il numero e la dimensione delle strofe.",
  instruction3: "Fare clic su genera",
  loginWarning1: "Facendo clic su Accedi o proseguendo con le altre opzioni di seguito, accetti ",
  loginWarning2: "Termini e Condizioni ",
  loginWarning3: " e ho letto il  ",
  loginWarning4: "Politica sulla Riservatezza ",
  newUser: "Nuovo su AI Factory? Iscriviti",
  forgotPassword: "Avete dimenticato la password?",
  logoutWarning: "Uscire dal proprio account?",
  signUpWarning: "Hai già un account? Accedi",
  audioHint:
    "Text-to-Speech dà vita al vostro testo a vostro piacimento. Posso convertire il vostro testo in file audio chiari e sentiti, dando un suono unico alle vostre parole.",
  minBuyWarning: "L'importo minimo del deposito per una transazione è di 10 EUR.",
  maxBuyWarning: "L'importo massimo del deposito per una transazione è di 1000 EUR.",
  copyright: "© 2024 Techfox Group LTD.",
  buyAgreement1: "Ho letto, compreso e accettato l'",
  buyAgreement2: "e al",
  buyAgreement3: " del sito web",
  buyAgreement4: "Politica sulla Riservatezza",
  buyAgreement5: "Termini di utilizzo",
  textServiceTitle2: "Parafrasi",
  textServiceTitle3: "Creativo",
};
