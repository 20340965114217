import { Lang } from "@/types/lang";

export default <Lang>{
  privacyHeading1: "Informativa sulla privacy",
  privacyHeading2: "Lo scopo della raccolta dei vostri dati",
  privacyHeading3: "Biscotti",
  privacyHeading4: "Divulgazione dei dati",
  privacyHeading5: "Periodo di conservazion",
  privacyHeading6: "Protezione dei dati",
  privacyHeading7: "GDPR",
  privacyHeading8: "Link a siti web di terzi\n",
  privacy1:
    "AI Era si impegna a mantenere la riservatezza dei propri clienti. La presente Informativa sulla privacy illustra le modalità con cui la Nostra Piattaforma raccoglie, utilizza, condivide, archivia, protegge e divulga le Informazioni personali o le Informazioni sull'utilizzo.\n" +
    "Utilizzando i Nostri Servizi, l'utente accetta e acconsente alla presente Informativa sulla privacy. In caso di mancata osservanza della presente Informativa sulla privacy, l'utente è tenuto a interrompere immediatamente l'utilizzo dei Nostri Servizi.\n" +
    "(Società) è disciplinata dalle leggi del (Paese) come società a responsabilità limitata, con sede legale a (indirizzo). Numero di registrazione:  Numero di telefono: + E-mail: contact{'@'}aieraworld.com",
  privacy2:
    "Gli scopi per i quali raccogliamo i vostri dati sono elencati di seguito e comprendono, a titolo esemplificativo e non esaustivo:\n" +
    "\t•\tRegistrazione e gestione dell'account;\n" +
    "\t•\tComunicare direttamente con voi;\n" +
    "\t•\tFornire informazioni su di Noi, pubblicare recensioni, post su forum o contenuti; \n" +
    "\t•\tRispondere alle vostre richieste;\n" +
    "\t•\tRispetto delle disposizioni di legge applicabili.\n" +
    "Raccogliamo informazioni sul vostro computer o dispositivo mobile durante l'utilizzo dei Nostri Servizi e le utilizziamo per analizzare i vostri dati. I dati che raccogliamo includono gli indirizzi IP del computer, gli ID dei dispositivi mobili e i dettagli tecnici del computer o del dispositivo mobile.",
  privacy3:
    '"I cookie" sono piccoli file memorizzati sul vostro computer o dispositivo mobile che ci permettono di tracciare l\'utilizzo dei nostri servizi e di identificare quando visitate il sito web.\n' +
    "La modifica delle preferenze del browser o del dispositivo mobile può impedire l'accettazione dei cookie sul computer o sul dispositivo, il che potrebbe impedire l'utilizzo di alcune funzioni disponibili sulla nostra piattaforma. L'utente riconosce e accetta che la presente Informativa sulla privacy non copre l'uso dei cookie da parte di terzi.",
  privacy4:
    "La nostra politica vieta di trasferire le informazioni personali dell'utente a terzi per monetizzare attraverso pubblicità o vendite. Tuttavia, possiamo condividere le informazioni personali dell'utente con terze parti che forniscono servizi quali l'analisi aziendale, il servizio clienti, il marketing, la distribuzione e l'elaborazione dei pagamenti. I fornitori possono raccogliere dati per nostro conto per gestire le funzionalità dell'applicazione o fornire servizi personalizzati. Le informazioni fornite dai fornitori terzi sono destinate esclusivamente ai loro scopi e non possono essere condivise o sfruttate per altri scopi.\n" +
    "Possiamo divulgare i vostri dati personali e di utilizzo a enti governativi quando richiesto, ad esempio in risposta a un'ordinanza del tribunale o a una richiesta governativa. Se dobbiamo ottemperare a una richiesta legale, vi verrà inviata una notifica via e-mail nella vostra casella di posta.",
  privacy5:
    "La durata della conservazione dei dati personali dell'utente è determinata dalla Società in base al tempo necessario per conformarsi alla presente Informativa sulla privacy e può variare. Conserveremo e utilizzeremo i dati dell'utente nella misura necessaria per adempiere ai nostri obblighi legali, risolvere le controversie e applicare i nostri accordi e le nostre politiche. In genere, i dati vengono conservati per un anno dall'ultima fornitura (o aggiornamento) dei dati da parte dell'utente.",
  privacy6:
    "Vogliamo che vi fidiate di noi e che abbiate la certezza che le vostre informazioni siano protette. Ci atteniamo alle misure di salvaguardia standard comunemente accettate per le Informazioni personali e le Informazioni di utilizzo. Sebbene nessun servizio possa garantire la completa sicurezza, abbiamo implementato le necessarie misure amministrative, tecniche e di sicurezza per assicurare la protezione delle vostre informazioni. Il personale autorizzato, gli agenti, i fornitori terzi e altre entità utilizzano i nostri dati solo per gli scopi richiesti. Utilizziamo firewall appropriati per proteggerli dall'uso non autorizzato, nonché tecnologie di crittografia e sistemi di rilevamento delle intrusioni.\n" +
    "La Società adotterà tutte le misure necessarie per garantire che i vostri dati siano trattati in modo sicuro e in conformità con la presente Informativa sulla privacy. Nessun dato personale sarà trasferito a livello internazionale a meno che non siano in atto controlli adeguati per salvaguardare i vostri dati e altre informazioni.",
  privacy7:
    "Rispettiamo il Regolamento generale sulla protezione dei dati (GDPR) per quanto riguarda il trattamento dei vostri dati personali. Possiamo trattare i Dati personali alle seguenti condizioni:\n" +
    "\t•\tAvete dato il vostro consenso al trattamento dei Dati personali per una o più finalità specifiche.\n" +
    "\t•\tAdempimento di un obbligo legale a cui la Società è soggetta.\n" +
    "\t•\tInteressi vitali: Il trattamento dei dati personali è necessario per proteggere gli interessi vitali vostri o di un'altra persona fisica.\n" +
    "\t•\tInteressi pubblici: Il trattamento dei dati personali è legato a un compito svolto nell'interesse pubblico o nell'esercizio dei poteri ufficiali conferiti alla Società.\n" +
    "\t•\tInteressi legittimi: Il trattamento dei dati personali è necessario ai fini dei legittimi interessi perseguiti dalla Società.\n" +
    "In ogni caso, la Società sarà lieta di aiutarvi a chiarire l'esatta base giuridica che si applica al trattamento, compreso il fatto che la fornitura di Dati personali sia una necessità legale o contrattuale, o un prerequisito per stipulare un contratto.\n" +
    "La Società si impegna a rispettare la riservatezza dei Suoi dati personali e a garantirLe l'esercizio dei Suoi diritti. L'utente ha il diritto, ai sensi della presente Informativa sulla privacy e della legge, di:\n" +
    "\t•\tRichiedere l'accesso ai propri Dati personali;\n" +
    "\t•\tRichiedere la correzione dei dati personali in nostro possesso;\n" +
    "\t•\tOpporsi al trattamento dei propri Dati personali;\n" +
    "\t•\tRichiedere la cancellazione dei vostri dati personali. L'utente ha il diritto di chiederci di cancellare o rimuovere i Dati personali quando non vi sono validi motivi per continuare a trattarli;\n" +
    "\t•\tRichiedere il trasferimento dei propri Dati Personali;\n" +
    "\t•\trevocare il consenso al trattamento dei dati personali.",
  privacy8:
    "La nostra Piattaforma può contenere link ad altri siti web non gestiti dalla Società. Se si fa clic su un link di terzi, si viene indirizzati al sito web di tali terzi. Si consiglia vivamente di prendere visione dell'informativa sulla privacy di ogni sito web visitato.\n" +
    "Non abbiamo alcun controllo e non ci assumiamo alcuna responsabilità per il contenuto, le politiche sulla privacy o le pratiche di siti web o servizi di terzi.\n" +
    "La presente Informativa sulla privacy può essere aggiornata o modificata in qualsiasi momento senza preavviso, pertanto si consiglia di controllare questa pagina di tanto in tanto. In caso di domande sulla presente Informativa sulla privacy, è possibile contattarci all'indirizzo contact{'@'}aieraworld.com.",
};
